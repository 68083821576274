/** @format */

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Surveys } from '../../lib/surveys';
import { Loading } from '../basic/Loading';
import {NotReady} from "../basic/NotReady";
import { AspectsSummary } from '../reports/AspectsSummary';
import { SurveysSummary } from '../reports/SurveysSummary';

import { Hr } from '../basic/Hr';
import { GenericReportPage } from './GenericReportPage';

interface FinalReportProps extends RouteComponentProps<any> {
  surveys: Surveys;
  showDetails: boolean;
}

export function FinalReportPage(props: FinalReportProps) {
  const { surveys, showDetails } = props;
  if (surveys.state !== 'ready') return <NotReady state={surveys.state} />;

  return (
    <GenericReportPage id="FinalReport">
      <SurveysSummary {...props} />

      {showDetails && <Hr />}

      {showDetails &&
        surveys.allSurveyDefinitions._data?.map(
          ({ surveyId, name, disabled }) => {
            const surveyData = surveys.getSurvey(surveyId, undefined);
            return !disabled && surveyId !== 'org' && surveyData ? (
              <React.Fragment key={surveyId}>
                <AspectsSummary surveyId={surveyId} surveys={surveys} />
                <Hr />
              </React.Fragment>
            ) : null;
          },
        )}
    </GenericReportPage>
  );
}
