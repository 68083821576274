/** @format */

import '@shared/config';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'iframe-resizer/js/iframeResizer.contentWindow.min.js';
// import 'react-toastify/dist/ReactToastify.min.css'; // minified version is also included

// @ts-ignore
import reportWebVitals from './reportWebVitals';

import './styles/index.scss';
import App from './App';

const customHistory = createBrowserHistory();

const routing = (
  <React.StrictMode>
    {/* @ts-ignore */}
    <Router history={customHistory}>
      <App />
    </Router>
    <ToastContainer
      autoClose={3000}
      hideProgressBar={false}
      pauseOnFocusLoss={false}
      pauseOnHover={true}
    />
  </React.StrictMode>
);

ReactDOM.render(routing, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
