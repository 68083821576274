import React from "react";
import {WidgetProps} from "@rjsf/semantic-ui";

export const TestWidget = (props: WidgetProps) => {
  return (
    <code>
      <pre>{'TestWidget: ' + JSON.stringify(props, null, 2)}</pre>
    </code>
  );
};

