/** @format */

import React from 'react';
import { Search, Grid, Header, Segment } from 'semantic-ui-react';
import _ from 'lodash';
import faker from 'faker';

import { WidgetProps } from '@rjsf/semantic-ui';
import { BackendHttp } from '../../../services/BackendHttp';

import { WidgetLabel } from '../components/WidgetLabel';

////////////////////////////////////////////////////////////////////////////////
//
// https://react.semantic-ui.com/modules/search/#types-standard
//

const source = _.times(5, () => ({
  title: faker.company.companyName(),
  description: faker.company.catchPhrase(),
  image: faker.internet.avatar(),
  price: faker.finance.amount(0, 100, 2, '$'),
}));

const initialState = {
  loading: false,
  results: [],
  value: '',
};

function exampleReducer(state, action) {
  switch (action.type) {
    case 'CLEAN_QUERY':
      return initialState;
    case 'START_SEARCH':
      return { ...state, loading: true, value: action.query };
    case 'FINISH_SEARCH':
      return { ...state, loading: false, results: action.results };
    case 'UPDATE_SELECTION':
      return { ...state, value: action.selection };

    default:
      throw new Error();
  }
}

export const SearchWidget = (props: WidgetProps) => {
  const [state, dispatch] = React.useReducer(exampleReducer, initialState);
  const { loading, results, value } = state;

  const handleResultSelect = React.useCallback((e, data) => {
    console.log('handleResultSelect: ', data.result);
    props.onChange(data.result?.title);
    dispatch({ type: 'UPDATE_SELECTION', selection: data.result.title });
  }, []);

  const handleSearchChange = React.useCallback((e, data) => {
    console.log('handleSearchChange: ', e, data);
    props.onChange(data.value);
    dispatch({ type: 'START_SEARCH', query: data.value });

    const backendHttp = new BackendHttp();
    backendHttp
      .getOkveds({
        code: data.value + '*',
        name: '*' + data.value + '*',
      })
      .then((requestResults) => {
        console.log('requestResults:', requestResults);
        const searchResults = requestResults.map((r) => ({
          title: r.code,
          description: r.name,
          // name: r.name,
        }));
        console.log('searchResults:', searchResults);
        dispatch({
          type: 'FINISH_SEARCH',
          results: searchResults,
        });
      });
    // }, 300)
  }, []);

  React.useEffect(() => {
    // set initial value from props
    if (props.value) {
      dispatch({ type: 'UPDATE_SELECTION', selection: props.value });
    }

    // cleanup if needed
    return () => {};
  }, []);

  return (
    <div className="custom-inline-widget custom-text-widget">
      {/* InlineTextWidget */}
      <WidgetLabel {...props} />
      {/* <BaseInput {...props} label={props.schema.title} /> */}

      <Search
        loading={loading}
        onResultSelect={handleResultSelect}
        onSearchChange={handleSearchChange}
        onFocus={handleSearchChange}
        results={results}
        value={value}
      />
    </div>
  );
};
