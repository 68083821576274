import React from 'react'
import {Error} from "./Error";
import {Loading} from "./Loading";

import {LoadingState} from "../../lib/surveys";

export const NotReady = ({ state } : { state?: LoadingState }) => {
  console.log(`NotReady: state: ${state}`);
  switch (state) {
    case undefined:
    case 'init':
    case 'loading':
      return <Loading/>
    case 'error':
      return <Error/>
    default:
      return null
  }
}
