/** @format */

import React from 'react';

import { MAX_LEVEL } from '../../lib/const';
import { convertToChart2 } from '../../lib/calc';
import { Surveys } from '../../lib/surveys';

import { Error } from '../basic/Error';
import { Loading } from '../basic/Loading';
import {NotReady} from "../basic/NotReady";
import { AspectsBarChart } from '../charts/AspectsBarChart';
import { AspectsRadarChart } from '../charts/AspectsRadarChart';
import {
  radarChartWidth,
  reportMargin,
  totalReportWidth,
  sv_body_padding,
} from '../charts/defaults';

// import { AllSurveyWeightDefinitions, SurveyWeightDefinitions } from '@shared/types/SurveyWeightDefinitions';
// import { SurveyState } from '@shared/types/SurveyState';

// const allWeightsDefinitions: AllSurveyWeightDefinitions = require('@shared/weights.js');

// const totalWidth = 1200;
// const sv_body_padding = 14;

const rightTextWidth =
  totalReportWidth -
  radarChartWidth -
  sv_body_padding * 2 -
  reportMargin * 2 -
  50; // otherwise it will be shown in PDF at next line
//const totalWidth = radarChartWidth+rightTextWidth + sv_body_padding*2;

interface AspectsSummaryProps {
  surveyId: string;
  surveys: Surveys;
  // name: string,
  // survey: SurveyState,
}

export function AspectsSummary(props: AspectsSummaryProps) {
  console.log('AspectsSummary props:', props);
  const { surveyId, surveys } = props;
  if (surveys.state !== 'ready') return <NotReady state={surveys.state} />;

  const surveyDef = surveys.getDefinition(surveyId);

  // const surveyIndex = parseInt(surveyId) - 1;
  // const surveyDef = allSurveys[surveyIndex];
  if (!surveyDef)
    return <Error>Survey with surveyId="{surveyId}" not found</Error>;

  const surveyName = surveys.getSurveyName(surveyId);

  console.log(
    `AspectsSummary surveyName: "${surveyName}", surveyId: ${surveyId}`,
  );
                                         
  const surveyResult = surveys.getResult(surveyId);
  // if (!surveyResult) return

  const chartData = convertToChart2(surveyResult);
  console.log('AspectsSummary Result (calculated):', surveyResult);

  const maturityLevels = surveyDef?.maturityLevels;
  if (!maturityLevels)
    console.warn(
      `Survey with surveyId="${surveyId}" is missing mandatory property "maturityLevels"`,
    ); 

  const NO_DESCRIPTION = 'Описание размерности отсутствует';

  const maturityDef = maturityLevels?.[surveyResult.level - 1] ?? {
    html1: NO_DESCRIPTION,
    html2: NO_DESCRIPTION,
  };
  if (!maturityDef)
    console.warn(
      `Survey with surveyId="${surveyId}" is missing maturity level definition for level ${surveyResult.level}.`,
    );

  const { html1, html2 } = maturityDef;

  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>
        Промежуточный отчет по размерности "{surveyName}"
      </h1>

      <div className="">
        <h2 style={{ textAlign: 'center' }}>
          Оценка вашей организации по данному направлению –{' '}
          <strong>
            {surveyResult.level} из {MAX_LEVEL}
          </strong>
        </h2>

        <div dangerouslySetInnerHTML={{ __html: html1 }} />

        <div>
          <div
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              width: `${radarChartWidth}px`,
            }}
          >
            <AspectsRadarChart data={chartData} />
          </div>

          <div
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              width: `${rightTextWidth}px`,
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: html2 }} />
          </div>
        </div>

        <AspectsBarChart data={chartData} />
      </div>
    </div>
  );
}
