import React from "react";
import {WidgetProps} from "@rjsf/semantic-ui";
import UpDownWidget from "@rjsf/core/lib/components/widgets/UpDownWidget";

import {WidgetLabel} from "../components/WidgetLabel";

export const InlineNumberWidget = (props: WidgetProps) => {        
  return (
    <div className="custom-inline-widget custom-number-widget">
      <WidgetLabel {...props} />
      <UpDownWidget {...props} />
    </div>
  );
};

