/** @format */

const dotenv = require('dotenv').config();
// import /*dotenv from */'dotenv'

// @format
// @-ts-nocheck

// import './dotenv';
// declare var process: { env: { [key: string]: string } };
// console.log('process.env:', process.env)

// React Routes

export const ROOT_PATH = '/';

export const SURVEY_PATH = '/survey/:surveyId';

export const SURVEY_EDIT_PATH = '/survey/edit/:surveyId';

export const SURVEY_EDIT_QUESTIONS_LIST_PATH =
  '/survey/edit/:surveyId/questions';
export const SURVEY_EDIT_QUESTIONS_PATH =
  '/survey/edit/:surveyId/questions/:questionId';

export const SURVEY_LEVEL_LIST_PATH = '/survey/edit/:surveyId/level';
export const SURVEY_LEVEL_EDIT_PATH = '/survey/edit/:surveyId/level/:levelId';

export const SURVEY_ASPECT_LIST_PATH = '/survey/edit/:surveyId/aspect';
export const SURVEY_ASPECT_EDIT_PATH =
  '/survey/edit/:surveyId/aspect/:aspectId';

export const SURVEY_DEF_EDIT_PATH = '/survey-def/edit/:surveyId';

export const SURVEY_REPORT_PATH = '/report/survey/:surveyId';

export const REPORT_CHOOSE_SURVEYS_PATH = '/report/choose';

export const INTERMEDIATE_SUMMARY_REPORT_PATH = '/report/intermediate/summary';
export const INTERMEDIATE_DETAILED_REPORT_PATH =
  '/report/intermediate/detailed';
export const FINAL_REPORT_PATH = '/report/final';

// Backend Routes

// export const {
//   BACKEND_APP_SERVER_PROTOCOL,
//   BACKEND_APP_SERVER_HOST,
//   BACKEND_APP_SERVER_PORT,
// } = process.env;
// export const BACKEND_APP_SERVER_URL = `${BACKEND_APP_SERVER_PROTOCOL}://${BACKEND_APP_SERVER_HOST}:${BACKEND_APP_SERVER_PORT}`;
export const {
  REACT_APP_BACKEND_API_SERVER_URL: BACKEND_API_SERVER_URL,
  REACT_APP_WORDPRESS_SERVER_URL: WORDPRESS_SERVER_URL,
  REACT_APP_WORDPRESS_SERVER_SURVEY_PATH: WORDPRESS_SERVER_SURVEY_PATH,

  REACT_APP_BACKEND_APP_SERVER_URL: BACKEND_APP_SERVER_URL,
} = process.env;
console.log('process.env', process.env);

// export const {
//   BACKEND_API_SERVER_PROTOCOL,
//   BACKEND_API_SERVER_HOST,
//   BACKEND_API_SERVER_PORT,
// } = process.env;
// export const BACKEND_API_SERVER_URL = `${BACKEND_API_SERVER_PROTOCOL}://${BACKEND_API_SERVER_HOST}:${BACKEND_API_SERVER_PORT}`;
console.log(`BACKEND_API_SERVER_URL: ${BACKEND_API_SERVER_URL}`);

let BACKEND_API_SERVER_PORT;
try {
  BACKEND_API_SERVER_PORT = new URL(BACKEND_API_SERVER_URL).port; // needed
} catch (e) {
  console.warn('Invalid BACKEND_API_SERVER_URL', BACKEND_API_SERVER_URL);
  BACKEND_API_SERVER_PORT = 3000;
}
export { BACKEND_API_SERVER_PORT };
// export const BACKEND_API_SERVER_PORT = new URL(BACKEND_API_SERVER_URL).port; // needed for express module

// const BASE_API_URL = 'http://localhost:3000/api'
export const BASE_API_URL = `/api`;

export const SURVEY_LIST_API_URL = `/survey`;

export const SURVEY_API_URL = `/survey/data`;
export const BASE_SURVEY_API_URL = BASE_API_URL + SURVEY_API_URL;
// export const SURVEY_API_URL = 'http://localhost:3000/api/survey'

export const SURVEY_QUESTIONS_API_URL = `/survey/questions`;
export const BASE_SURVEY_QUESTIONS_API_URL =
  BASE_API_URL + SURVEY_QUESTIONS_API_URL;

export const SURVEY_DEFINITIONS_API_URL = `/survey/definitions`;
export const BASE_SURVEY_DEFINITIONS_API_URL =
  BASE_API_URL + SURVEY_DEFINITIONS_API_URL;

export const PDF_TO_EMAIL_API_URL = `/email`;
export const BASE_PDF_TO_EMAIL_API_URL = BASE_API_URL + PDF_TO_EMAIL_API_URL;

export const RESULTS_API_URL = `/results`;
export const BASE_RESULTS_API_URL = BASE_API_URL + RESULTS_API_URL;

export const OKVED_API_URL = `/okved`;
export const BASE_OKVED_API_URL = BASE_API_URL + OKVED_API_URL;

export const JSON_API_URL = `/csv`;
export const BASE_JSON_API_URL =
  BACKEND_API_SERVER_URL + BASE_API_URL + JSON_API_URL;

//

export const BASE_OAUTH_URL = `/oauth`;
export const OAUTH_WORDPRESS_CB_URL = `/cb`;

export const WORDPRESS_TO_BACKEND_REDIRECT_URL =
  BACKEND_API_SERVER_URL + BASE_OAUTH_URL + OAUTH_WORDPRESS_CB_URL;
// 'http://localhost:3001/oauth/cb';
export const FINAL_WORDPRESS_OAUTH_REDIRECT_URL = BACKEND_APP_SERVER_URL; // + '?test=test';

// Word Press Server

// export const {
//   WORDPRESS_SERVER_PROTOCOL,
//   WORDPRESS_SERVER_HOST,
//   WORDPRESS_SERVER_PORT,                         `
// } = process.env;
// export const WORDPRESS_SERVER_URL = `${WORDPRESS_SERVER_PROTOCOL}://${WORDPRESS_SERVER_HOST}:${WORDPRESS_SERVER_PORT}`;
console.log(`WORDPRESS_SERVER_URL: ${WORDPRESS_SERVER_URL}`);

// const WORDPRESS_TOKEN_URL = WORDPRESS_SERVER_URL + '/oauth/token';
export const WORDPRESS_TOKEN_URL = WORDPRESS_SERVER_URL + '?oauth=token';
// const WP_USER_URL = WORDPRESS_SERVER_URL + '/oauth/me';
export const WORDPRESS_USER_URL = WORDPRESS_SERVER_URL + '?oauth=me';

export const {
  REACT_APP_WORDPRESS_CLIENT_ID: WORDPRESS_CLIENT_ID,
  REACT_APP_WORDPRESS_CLIENT_SECRET: WORDPRESS_CLIENT_SECRET,
  REACT_APP_MONGO_DB_URL: MONGO_DB_URL,
} = process.env;

// export const WORDPRESS_CLIENT_ID = 'LP9umjKOt7REU9fB7c49vViJO6poe9FQrePiTSor';
// export const WORDPRESS_CLIENT_SECRET =
//   's9hwnc55rGikB3lTNWbcrOFUgZ3xPRijkBHUGuGF';

//

// export const MONGO_DB_URL = 'mongodb://127.0.0.1:27017';
