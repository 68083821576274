/** @format */

import { JSON_API_URL, SURVEY_EDIT_PATH } from '@shared/config';
import React, { PropsWithChildren, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-regular-svg-icons';
import { Input } from 'semantic-ui-react';
import { Button, Popup } from 'semantic-ui-react';

import { Surveys } from '../../../lib/surveys';
import { getIsAdmin, routeSubstitute } from '../../../lib/utils';

import { Hr } from '../../basic/Hr';
import { MiniLink } from '../../basic/Link';
import { NotReady } from '../../basic/NotReady';
import { ReportLinks } from './ReportLinks';
import { SurveysSections } from './SurveySections';

import {WORDPRESS_SERVER_URL, WORDPRESS_SERVER_SURVEY_PATH} from '@shared/config/'

export const MainPage = ({ surveys }: { surveys: Surveys }) => {
  console.log('MainPage surveyQuestions:', surveys);
  const [isOpened, setIsOpened] = useState(false);

  if (surveys.state !== 'ready') return <NotReady state={surveys.state} />;

  const { completed } = /*await*/ surveys.getCounts();
  const urlId = surveys._data?.id;
  // http://localhost?big_data_survey_id=02f07bcd-41b7-4ada-9f34-9d34b41bc6d6
  const url = WORDPRESS_SERVER_URL +
    WORDPRESS_SERVER_SURVEY_PATH+
    '&big_data_survey_id='+
    surveys._data?.id;

  const isIntermediateEnabled = completed > 2;
  const isFinalEnabled = completed >= 7 && surveys.getSurvey('org')?.completed;
  // console.log(`isIntermediateEnabled: ${isIntermediateEnabled}`);
  // console.log(`isFinalEnabled: ${isFinalEnabled}`);

  function copyUrl() {
    const copyText = document.querySelector(
      '#permalink-url-input',
    ) as HTMLInputElement;
    copyText.select();
    document.execCommand('copy');
    setIsOpened(false);
  }

  return (
    <div className=" ui main container">
      <nav className="main-menu">
        <header>
          <h3>Пожалуйста, ответьте на вопросы следующих разделов:</h3>
        </header>

        <main>
          <SurveysSections surveys={surveys} />
        </main>

        {/*
      <FontAwesomeIcon
        icon={faClipboard}
        // style={{ color: 'darkgreen', margin: '0 5px' }}
      />
*/}

        <footer>
          <Hr style={{ marginLeft: '40px', width: '300px' }} />

          <ReportLinks
            isFinalEnabled={isFinalEnabled}
            isIntermediateEnabled={isIntermediateEnabled}
          />

          {/*
        <Input icon='users' readonly value='Search users...' />
        <Input
          action={{
            color: 'teal',
            labelPosition: 'right',
            icon: 'copy',
            content: 'Copy',
          }}
          disabled
          defaultValue='http://ww.short.url/c0opq'
        />
*/}

          {urlId ? (
            <React.Fragment>
              <Hr style={{ marginLeft: '40px', width: '300px' }} />

              <div style={{
                /* у кнопки есть свой margin */
                marginLeft: '37px'
              }}>
                <Button
                  className="ui primary button"
                  onClick={() => setIsOpened(!isOpened)}
                  style={{
                    /* компенсировать разницу в высоте button и input */
                    marginTop: '1px',
                    marginBottom: '1px',
                  }}
                >
                  {/*<i className="copy icon"/>*/}
                  Получить ссылку на опрос
                </Button>

                {isOpened ? (
                  <div className="ui action input">
                    <Input
                      id="permalink-url-input"
                      type="text"
                      value={url}
                      style={{ minWidth: '400px' }}
                    />

                    <Popup
                      content="Скопировать URL в буфер обмена"
                      trigger={
                        <Button
                          // className="ui primary right labeled icon button"
                          className="ui primary right icon button"
                          onClick={copyUrl}
                        >
                          <i className="copy icon" />
                          {/*Копировать*/}
                        </Button>
                      }
                    />
                  </div>
                ) : null}
              </div>
            </React.Fragment>
          ) : null}
        </footer>
      </nav>
    </div>
  );
};
