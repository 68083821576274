/** @format */

import React, { PropsWithChildren } from 'react';

import { Link, MiniLink, LinkProps } from '../../../basic/Link';
import { SurveyCompleteIcon } from '../../../basic/SurveyCompleteIcon';
import { Surveys } from '../../../../lib/surveys';
import { routeSubstitute, getIsAdmin } from '../../../../lib/utils';
import {
  SURVEY_PATH,
  SURVEY_EDIT_PATH,
  SURVEY_DEF_EDIT_PATH,
  SURVEY_EDIT_QUESTIONS_LIST_PATH,
  SURVEY_LEVEL_LIST_PATH, SURVEY_ASPECT_LIST_PATH,
} from '@shared/config';

export const LinkToSurvey = ({
  surveys,
  hasData,
  surveyId,
  name,
  completed,
  disabled,
}: {
  surveys: Surveys;
  hasData: boolean;
  surveyId: string;
  name: string;
  completed: boolean;
  disabled: boolean;
}) => {
  return (
    <li key={surveyId}>
      <Link to={routeSubstitute(SURVEY_PATH, { surveyId })} disabled={disabled}>
        {name}
      </Link>
      <SurveyCompleteIcon completed={completed} hasData={hasData} />
      <MiniLink
        to={`#`}
        visible={hasData}
        disabled={disabled}
        onClick={() => surveys.clear(surveyId)}
      >
        [сбросить]
      </MiniLink>
      {/*{surveyId !== 'org' ? (*/}
      {/*  <React.Fragment>*/}
          &nbsp;
          <MiniLink
            to={routeSubstitute(SURVEY_EDIT_PATH, { surveyId })}
            visible={getIsAdmin()}
            disabled={disabled}
          >
            [изменить]
          </MiniLink>
{/*
          &nbsp;
          <MiniLink
            to={routeSubstitute(SURVEY_EDIT_QUESTIONS_LIST_PATH, { surveyId })}
            visible={getIsAdmin()}
            disabled={disabled}
          >
            [ред.вопросы-новое]
          </MiniLink>
          &nbsp;
          <MiniLink
            to={routeSubstitute(SURVEY_LEVEL_LIST_PATH, { surveyId })}
            visible={getIsAdmin()}
            disabled={disabled}
          >
            [ред.описание ур.разм.]
          </MiniLink>
          &nbsp;
          <MiniLink
            to={routeSubstitute(SURVEY_ASPECT_LIST_PATH, { surveyId })}
            visible={getIsAdmin()}
            disabled={disabled}
          >
            [ред.описание ур.разм.]
          </MiniLink>
          &nbsp;
          <MiniLink
            to={routeSubstitute(SURVEY_DEF_EDIT_PATH, { surveyId })}
            visible={getIsAdmin()}
            disabled={disabled}
          >
            [ред.отчет]
          </MiniLink>
*/}
        {/*</React.Fragment>*/}
      {/*) : null}*/}
    </li>
  );
};
