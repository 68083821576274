/** @format */

import {JSONSchema7} from "json-schema";

const AllSurveysSchema/*: JSONSchema7*/ = {
  type: 'array',
  title: 'Настройка обработки результатов опросов',
  items: {
    type: 'object',
    title: 'Размерность',
    required: ['name', 'weight', 'disabled', 'maturityLevels', 'aspectWeights'],
    properties: {
      name: {
        type: 'string',
        title: 'Название размерности',
        default: 'Название размерности по умолчанию',
      },
      weight: {
        type: 'number',
        title: 'Вес размерности',
        default: 0.142857143,
      },
      disabled: {
        type: 'boolean',
        title: 'Скрыть размерность для пользователя',
        default: false,
      },
      maturityLevels: {
        type: 'array',
        title: 'Описание уровней зрелости по размерностям',
        items: {
          type: 'object',
          title: 'Уровни зрелости',
          required: ['level', 'name'],
          properties: {
            level: {
              type: 'number',
              title: 'Числовое значение уровня зрелости размерности (1..5)',
              minimum: 1,
              maximum: 5,
              multipleOf: 1,
              // enum: [1,2,3,4,5]
            },
            name: {
              type: 'string',
              title: 'Название уровня зрелости размерности',
              default: 'Название уровня зрелости по умолчанию',
            },
            html1: {
              type: 'string',
              title:
                'Первая часть описания уровня зрелости (над графиком), формат HTML',
              description: '',
              default: '<p>Описание уровня зрелости</p>',
            },
            html2: {
              type: 'string',
              title:
                'Вторая часть описания уровня (справа от графика), формат HTML)',
              description: '',
              default: '<p>Описание уровня зрелости</p>',
            },
          },
        },
      },
      aspectWeights: {
        type: 'array',
        title: 'Веса отдельных аспектов размерности',
        items: {
          type: 'object',
          title: 'Аспект',
          required: ['id', 'name', 'w', 'q'],
          properties: {
            id: {
              type: 'string',
              title: 'Идентификатор аспекта',
            },
            name: {
              type: 'string',
              title: 'Название аспекта',
              default: 'Название аспекта по умолчанию',
            },
            w: {
              type: 'number',
              title: 'Вес аспекта',
              default: 0.25,
            },
            q: {
              type: 'array',
              title: 'Правила расчета',
              description: 'Каждое правило состоит из логического выражения и результата, который будет присвоен аспекту, если данное выражение истинно. Правила проверяются по очереди; если логическое выражение вычисляется в true, аспекту присваивается значение данного правила и выполнение выражений для данного. В противном случае происходит переход к следующему правилу. Если правил больше не осталось, значение аспекта будет равным нулю.',
              items: {
                type: 'object',
                title: '',
                required: ['w', 'q'],
                properties: {
                  q: {
                    type: 'string',
                    // title: 'Выражение',
                    title: 'Логическое выражение',
                    // description: 'Логическое выражение',
                  },
                  w: {
                    type: 'number',
                    title: 'Результат правила',
                    minimum: 1,
                    maximum: 5,
                    default: 1,
                    // description: 'Результирующее значение (если выражение истинно)',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

export default AllSurveysSchema;// as JSONSchema7;
