/** @format */

import { AbstractDataCache } from '../DataCache';
import { BackendHttp } from '../../services/BackendHttp';

import _allSurveys from '@shared/data/fixtures/surveyDefinitions/surveyDefinitions';
import {
  AllSurveysDefinitions,
  AllSurveysElement,
} from '@shared/types/SurveyDefinitions';

export const allSurveyDefinitions = _allSurveys as AllSurveysDefinitions;

export class SurveyDefinitions extends AbstractDataCache<AllSurveysDefinitions> {
  protected backendHttp = new BackendHttp();

  constructor() {
    super();
  }

  async _loadLocal(): Promise<AllSurveysDefinitions | undefined> {
    this._set(allSurveyDefinitions);
    // console.log('_loadLocal');
    // await new Promise((resolve, reject) =>
    //   setTimeout(() => {
    //     console.log('_loadLocal');
    //     this._set(allSurveyDefinitions);
    //     resolve(true);
    //   }, 3000),
    // );
    return this._get();
  }

  async _loadRemote(): Promise<AllSurveysDefinitions | undefined> {
    const data = await this.backendHttp.getDefinitions();
    this._set(data);
    return this._get();
  }

  async _saveLocal(): Promise<AllSurveysDefinitions | undefined> {
    return this._get();
  }

  async load(): Promise<AllSurveysDefinitions | undefined> {
    await this._loadRemote();
    // return this._loadLocal();
    return this._get();
  }

  async save(): Promise<AllSurveysDefinitions | undefined> {
    await this.backendHttp.postDefinitions(this._data);
    return this._saveLocal();
  }

  public findSurvey(surveyId: string): AllSurveysElement | undefined {
    return this._data?.find((s) => s.surveyId === surveyId);
  }
}
