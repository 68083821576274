import React from "react";
import {WidgetProps} from "@rjsf/semantic-ui";
import TextareaWidget from "@rjsf/core/lib/components/widgets/TextareaWidget";

import {WidgetLabel} from "../components/WidgetLabel";

export const InlineTextareaWidget = (props: WidgetProps) => {
  return (
    <div className="custom-inline-widget custom-textarea-widget">
      <WidgetLabel {...props} />
      <TextareaWidget {...props} />
    </div>
  );
};

