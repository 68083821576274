import React from "react";

import {Link} from "../basic/Link";

//
// Set custom label text and manage text wrap:
// -------------------------------------------
// Word-wrapping <Text> component -  https://github.com/recharts/recharts/issues/198
// Custom label on Recharts radar chart - https://stackoverflow.com/questions/59361614/custom-label-on-recharts-radar-chart
//

 interface CustomTickProps {
  className?: string;
  cx?: number;
  cy?: number;
  fill?: undefined;
  getName?: (index: number) => string;
  getUrl?: (index: number) => string;
  index?: number;
  orientation?: string;
  payload?: {
    coordinate?: number;
    index?: number;
    offset?: number;
    value?: string;
  };
  radius?: number;
  stroke?: string;
  textAnchor?: string;
  type?: string;
  x?: number;
  y?: number;
}

const SVG_A_MARGIN = 5;

export function CustomPolarAngleAxisTick(props: CustomTickProps) {
  // console.log('CustomPolarAngleAxisTick: props:', props);
  const {
    payload,
    cx,
    cy,
    x,
    y,
    textAnchor,
    stroke,
    radius,
    getUrl,
    getName,
  } = props;
  const { index, value } = payload;
  const id = value;
  return (
    <g className="recharts-layer recharts-polar-angle-axis-tick">
      {/*<a href={getUrl(index) ?? '#'}>*/}
      <Link to={getUrl(index) ?? '#'}>
        <text
          radius={radius}
          stroke={stroke}
          x={x}
          y={y}
          className="recharts-text recharts-polar-angle-axis-tick-value"
          textAnchor={textAnchor}
        >
          {/*
            мы должны явно задать размер шрифта для текста,
            чтобы при преобразовании в png он сохранился
            <<<
          */}
          <tspan
            x={x}
            dy={y > cy ? SVG_A_MARGIN : y < cy ? -SVG_A_MARGIN : '0'}
            dx={x > cx ? SVG_A_MARGIN : x < cx ? -SVG_A_MARGIN : '0'}
            style={{ fontSize: '14px' }}
          >
            {/*
            >>>
            */}
            {getName(index) ?? ''}
          </tspan>
        </text>
      </Link>
    </g>
  );
}

export const CustomPolarRadiusAxisTick = function (props: any) {
  // console.log('CustomTick:', props);
  const { payload, cx, cy, x, y, textAnchor, stroke, radius } = props;
  const { index, value } = payload;
  return (
    <g>
      <text
        radius={radius}
        stroke={stroke}
        x={x}
        y={y}
        fill="grey"
        className="recharts-text recharts-polar-angle-axis-tick-value"
        textAnchor={textAnchor}
      >
        <tspan x={x} dy="-3" dx="-5">
          {value || '.'}
        </tspan>
      </text>
    </g>
  );
};
