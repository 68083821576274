import React, {useEffect, useRef} from "react";
import {WidgetProps} from "@rjsf/semantic-ui";
import TextareaWidget from "@rjsf/core/lib/components/widgets/TextareaWidget";
import CodeMirror from 'codemirror';

// import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/htmlmixed/htmlmixed';

// CodeMirror theme styles
import 'codemirror/lib/codemirror.css';
// import 'codemirror/theme/eclipse.css';
import 'codemirror/theme/idea.css';
// import 'codemirror/theme/neat.css';
// import 'codemirror/theme/neo.css';

import {WidgetLabel} from "../components/WidgetLabel";

export const InlineCodeWidget = (props: WidgetProps) => {
  const codeMirrorRef = useRef(null);
  console.log('InlineCodeWidget: codeMirrorRef:', codeMirrorRef);

  useEffect(() => {
    function cleanup() {
      console.log('InlineCodeWidget: useEffect: cleanup');
      if (codeMirrorRef.current) {
        codeMirrorRef.current.toTextArea();
        codeMirrorRef.current = null;
      }
    }

    console.log('InlineCodeWidget: useEffect: codeMirrorRef:', codeMirrorRef);

    const el = document.getElementById(props.id) as HTMLTextAreaElement;
    console.log('InlineCodeWidget: useEffect: el:', el);

    if (codeMirrorRef.current) {
      console.log('CodeMirror is already created');
      return cleanup;
    }

    const config = {
      lineWrapping: true,
      lineNumbers: true,
      // mode: 'javascript',
      // mode: 'xml',
      // htmlMode: true,
      mode: 'htmlmixed',
      // theme: 'eclipse'
      theme: 'idea',
      // theme: 'neo'
    };
    const myCodeMirror = CodeMirror.fromTextArea(el, config);

    // function updateTextArea() {
    //   myCodeMirror.save();
    // }
    // myCodeMirror.on('change', updateTextArea);

    myCodeMirror.on("change", function(cm, change) {
      // console.log('myCodeMirror.on("change")', cm.getValue())
      // console.log('myCodeMirror.on("change"): el', el)
      // console.log('myCodeMirror.on("change"): el.value', el.value)
      // el.value = cm.getValue();
      const newValue = cm.getValue();
      // el.innerHTML = cm.getValue();
      props.onChange(newValue);
      // console.log('myCodeMirror.on("change"): el:', el)
      // console.log('myCodeMirror.on("change"): el.value:', el.value)
    })

    // console.log('InlineCodeWidget: useEffect: myCodeMirror', myCodeMirror);
    // setCodeMirror(myCodeMirror);
    codeMirrorRef.current = myCodeMirror;
    // console.log('InlineCodeWidget: useEffect: codeMirrorRef:', codeMirrorRef);

    return cleanup;
  }, []);

  return (
    <div className="custom-inline-widget custom-code-widget">
      {/* InlineTextWidget */}
      <WidgetLabel {...props} />
      {/* <BaseInput {...props} label={props.schema.title} /> */}
      <TextareaWidget {...props} />
    </div>
  );
};
