// import {dom} from "@fortawesome/fontawesome-svg-core";
import React from 'react';
// import { RouteComponentProps } from 'react-router-dom'

import {GenericPage} from "./GenericPage";
import {ReportPanel} from "../reports/ReportPanel";
// import {AspectsRadarChart } from '../charts/AspectsRadarChart';
import { reportMargin } from '../charts/defaults'

//
// !!! This is required in order to properly display fonts in PDF !!!
import '../../fonts/Montserrat-Regular-normal.js';
//
//

import './GenericReportPage.scss'


interface GenericReportPageProps {
  id: string
  children: React.ReactNode
  footer?: React.ReactNode

}


export function GenericReportPage(props: GenericReportPageProps) {


  // var doc = new jsPDF();
  // var specialElementHandlers = {
  //   '#editor': function (element: any, renderer: any) {
  //     return true;
  //   }
  // };


  return <GenericPage id={props.id}>

    <ReportPanel selector='.report-area' />
    {/*<ReportPanel selector='.sv_main' />*/}


    <div
      className='report-area'
      style={{
        fontFamily: 'Montserrat !important',
        margin: `${reportMargin}px`,
      }}
    >

      { props.children }

    </div>

    { props.footer }

  </GenericPage>

}
