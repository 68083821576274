/** @format */

import React from 'react';
// import {Link} from './Link';

import './Menu.scss';

export const HorizontalMenu = (props: {
  className: string;
  logo?: React.ReactNode;
  children: React.ReactNode;
}) => {
  const { children, className } = props;
  return (
    <nav
      className={`horizontal-menu ${className || ''}`}
      style={{
      }}
    >
      {props.logo}

      <ul style={{
        // display: 'inline'
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
      >{children}</ul>
    </nav>
  );
};
