/** @format */

import { UiSchema } from '@rjsf/core';

const uiSchema: UiSchema = {
  // 'ui:ObjectFieldTemplate': ObjectFieldTemplate,
  'ui:options': {
    semantic: {
      class_: 'custom-root', // <h5>
      wrapItem: true,
      horizontalButtons: true,
      fluid: true,
      // inverted: false,
      // errorOptions: {
      //   size: 'small',
      //   pointing: 'above',
      // },
    },
  },
  items: {
    'ui:options': {
      semantic: {
        class_: 'custom-root-items', // <h5>
        fluid: true,
        wrapItem: true,
        // horizontalButtons: true,
      },
    },
    name: {
      // 'ui:widget': 'inline-text-widget',
      'ui:options': {
        semantic: {
          class_: 'custom-root-items-name', // <h5>
          fluid: true,
          wrapItem: true,
          // horizontalButtons: true,
        },
      },
    },
    weight: {
      // 'ui:widget': 'inline-number-widget',
      'ui:options': {
        semantic: {
          class_: 'custom-root-items-weight', // <h5>
        },
      },
    },
    disabled: {},
    maturityLevels: {
      'ui:options': {
        semantic: {
          class_: 'custom-root-items-maturityLevels', //s <h5>
          wrapItem: true,
          // horizontalButtons: true,
        },
      },
      items: {
        level: {
          // 'ui:widget': 'inline-number-widget',
          'ui:disabled': true,
          // 'ui:widget': 'range',
          'ui:options': {
            semantic: {
              // style: { width: '200px' },
              wrapItem: true,
            },
          },
        },
        name: {},
        html1: { 'ui:widget': 'CodeWidget' },
        html2: { 'ui:widget': 'CodeWidget' },
      },
    },
    aspectWeights: {
      'ui:options': {
        semantic: {
          class_: 'custom-root-items-aspectWeights', //s <h5>
          wrapItem: true,
          //   horizontalButtons: true,
        },
      },
      items: {
        'ui:options': {
          semantic: {
            class_: 'custom-root-items-aspectWeights-items', //s <h5>
            wrapItem: true,
            // horizontalButtons: true,
          },
        },
        id: {
          'ui:disabled': true,
        },
        name: {},
        w: {
          // 'ui:widget': 'inline-number-widget',
        },
        q: {
          'ui:options': {
            semantic: {
              //     class_: 'custom-root-items-aspectWeights-items-q', //s <h5>
              wrapItem: true,
              //     // horizontalButtons: true,
            },
          },
          items: {
            'ui:options': {
              semantic: {
                //     class_: 'custom-root-items-aspectWeights-items-q-items', //s <h5>
                wrapItem: true,
                //     // horizontalButtons: true,
              },
            },
            q: {
              // 'ui:widget': 'inline-number-widget',
            },
            w: {
              'ui:widget': 'UpDownWidget',
              // 'ui:widget': 'inline-number-widget',
              'ui:options': {
                semantic: {
                  //     class_: 'custom-root-items-weight', // <h5>
                  // wrapItem: true,
                },
              },
            },
          },
        },
      },
    },
  },
};

export default uiSchema;
