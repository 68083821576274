/** @format */

import React from 'react';
import * as Survey from 'survey-react';

import eventBus from './lib/EventBus';
import { Link } from './components/basic/Link';
import { Login } from './components/basic/Login';
import { HorizontalMenu } from './components/basic/Menu';
import { Surveys } from './lib/surveys';
import { Routes } from './Routes';

import './styles/bar-chart.scss';
import './styles/App.scss';
import './styles/recharts-custom-tooltip.scss';
import './styles/semantic-ui.scss';
import './styles/codemirror-semantic-ui.scss';

const TopMenu = () => {
  return (
    <div>
      <HorizontalMenu
        className="top-menu"
        // logo={Logo}
      >
        <li>
          <Link to="/">Меню опроса</Link>
        </li>
        <li>
          <Login />
        </li>
      </HorizontalMenu>
    </div>
  );
};

type AppProps = {};

type AppState = {
  // name: string;
  // result: Survey.SurveyModel | null;
};

class App extends React.Component<AppProps, AppState> {
  surveys: Surveys;

  constructor(props: AppProps) {
    super(props);
    this.state = {
      // name: '',
      // result: null,
    };
    this.handleUnload = this.handleUnload.bind(this);
    // Survey.StylesManager.applyTheme('darkblue'); // "bootstrap"
    const defaultThemeColors = Survey.StylesManager.ThemeColors['darkblue'];
    defaultThemeColors['$main-color'] = '#001031';
    defaultThemeColors['$main-hover-color'] = '#001031e6';
    // defaultThemeColors['$text-color'] = '#4a4a4a';
    // defaultThemeColors['$header-color'] = '#7ff07f';
    // defaultThemeColors['$header-background-color'] = '#4a4a4a';
    // defaultThemeColors['$body-container-background-color'] = '#f8f8f8';
    Survey.StylesManager.applyTheme('darkblue');
    //
    this.surveys = new Surveys();
    // toast.configure({
    //   autoClose: 3000,
    //   pauseOnFocusLoss: false,
    //   pauseOnHover: true,
    // });
    this.handleLogout = this.handleLogout.bind(this);
  }

  forceUpdate() {
    this.setState({});
  }

  handleLogout() {
    this.forceUpdate(); // just rerender
  }

  getBigDataSurveyId(): string|undefined {
    const queryParams = new URLSearchParams(window.location.search);

    const PARAM_NAME_URL = 'parent_url';
    const PARAM_NAME_LVL2_ID = 'big_data_survey_id';
    if (queryParams.has(PARAM_NAME_URL)) {
      const parent_url_string = decodeURIComponent(
        queryParams.get(PARAM_NAME_URL),
      );
      console.log('parent_url_string:', parent_url_string);

      const url = new URL(parent_url_string);
      const big_data_survey_id = url.searchParams.get(PARAM_NAME_LVL2_ID);
      console.log('big_data_survey_id:', big_data_survey_id);

      return big_data_survey_id;
    }
    return undefined;
  }

  tryLoadData(idFromUrl: string) {
    this.surveys._prepareLoad();
    this.forceUpdate(); // just rerender
    this.surveys
      .load(idFromUrl)
      .then(() => {
        this.forceUpdate(); // just rerender
      })
      .catch((e) => {
        console.log('Error loading data from server', e);
        this.forceUpdate(); // just rerender

        const RETRY_TIMEOUT = 10 * 1000;
        console.log(`Will retry in ${RETRY_TIMEOUT} ms`);

        setTimeout(() => {
          console.log('Retrying...');
          this.tryLoadData(idFromUrl);
        }, RETRY_TIMEOUT);
      });
  }

  componentDidMount() {
    const idFromUrl = this.getBigDataSurveyId()
    this.tryLoadData(idFromUrl);
    window.addEventListener('beforeunload', this.handleUnload);
    eventBus.on('logout', this.handleLogout);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleUnload);
    eventBus.remove('logout', this.handleLogout);
  }

  handleUnload(e: BeforeUnloadEvent) {
    if (this.surveys.isChanged) {
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
      // Cancel the event
      e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      (e || window.event).returnValue = '';
    } else {
      delete e['returnValue'];
    }
  }

  render() {
    return (
      <div className="App">
        <TopMenu />
        <Routes surveys={this.surveys} />
      </div>
    );
  }
}

export default App;
