import React from "react";
import {WidgetProps} from "@rjsf/semantic-ui";
import EmailWidget from "@rjsf/core/lib/components/widgets/EmailWidget";

import {WidgetLabel} from "../components/WidgetLabel";

export const InlineEmailWidget = (props: WidgetProps) => {
  return (
    <div className="custom-inline-widget custom-email-widget">
      <WidgetLabel {...props} />
      <EmailWidget {...props} />
    </div>
  );
};

