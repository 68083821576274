/** @format */

// export function wrapDoc({ type, doc }: { type: string; doc: any }) {
// interface WrapDocArgs { type: string; doc: any }

type WrappedType = string;

export interface WrappedDoc<Data> {
  _id?: any; // string | MongoId;
  type: WrappedType;
  datetime: Date;
  data: Data;
}

export function wrapDoc({ type, doc }: { type: string; doc: any }): WrappedDoc<any> {
  return {
    type: type,
    datetime: new Date(),
    data: doc,
  };
}

// : { type?: string; doc: any }
export function unwrapDoc({ type, doc }: { type: string; doc: WrappedDoc<any> }): any {
  // return doc.map((d) => {
  const { type: docType, datetime, data } = doc || {};
  if (type && type !== docType)
    throw new Error(`Expecting doc type ${type}, but found: ${docType}`);
  return data;
  // });
}

// : { type?: string; docs: any[] }
export function unwrapDocs({ type, docs }: { type: string; docs: WrappedDoc<any>[] }): any[] {
  if (!Array.isArray(docs)) docs = [docs];
  return docs.map((doc) => unwrapDoc({ type, doc }));
}
