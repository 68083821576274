/** @format */

import React, { useState, useEffect } from 'react';
import _ from 'lodash';

// import JSONSchemaForm, { UiSchema } from 'react-jsonschema-form';

// @ts-ignore
// import { BaseInput } from '@rjsf/core';

import JSONSchemaForm, {
  UiSchema,
  WidgetProps,
  FieldProps,
  FieldTemplateProps,
} from '@rjsf/semantic-ui';
// import JSONSchemaForm, { UiSchema, WidgetProps, BaseInput } from '@rjsf/semantic-ui';
// import {BaseInput} from '../../../node_modules/@rjsf/core/lib/components/widgets';

// import { withTheme } from '@rjsf/core';
// import { Theme as SemanticUITheme } from '@rjsf/semantic-ui';

import { JSONSchema6, JSONSchema7 } from 'json-schema';

import 'semantic-ui-css/semantic.min.css';

import { Hr } from '../../basic/Hr';

import { useForceUpdate } from '../../../lib/utils';
import { Surveys } from '../../../lib/surveys';
import {Loading} from "../../basic/Loading";
import {NotReady} from "../../basic/NotReady";

import { customWidgets, customFields } from '../../rjsf';

import AllSurveys_uiSchema from '@shared/schemas-ui/AllSurveys.ui-schema';

import AllSurveysSchema_ from '@shared/schemas/AllSurveys.schema';
const AllSurveysSchema = AllSurveysSchema_ as JSONSchema7;

// import BaseInput from '@rjsf/core/lib/components/widgets/BaseInput';
// import TextWidget from '@rjsf/core/lib/components/widgets/TextWidget';
// import UpDownWidget from '@rjsf/core/lib/components/widgets/UpDownWidget';
// import StringField from '@rjsf/core/lib/components/fields/StringField';
// import { getDefaultRegistry } from '@rjsf/core/lib/utils';
// const BaseInput = require('@rjsf/core/lib/components/widgets/BaseInput')
//   .default;
// const TextWidget = require('@rjsf/core/lib/components/widgets/TextWidget')
//   .default;
// const UpDownWidget = require('@rjsf/core/lib/components/widgets/UpDownWidget')
//   .default;
// const StringField = require('@rjsf/core/lib/components/fields/StringField')
//   .default;
// const { getDefaultRegistry } = require('@rjsf/core/lib/utils');
// console.log('getDefaultRegistry', getDefaultRegistry)

interface AdminSurveyDefPageProps {
  surveyId: string;                   
  surveys: Surveys;
  // name: string
  // surveyState: SurveyState
  // onBack: ClickEvent
  // onNext: ClickEvent
}

export function AdminEditSurveyDefFull(props: AdminSurveyDefPageProps) {
  const { surveyId, surveys } = props;
  if (surveys.state !== 'ready') return <NotReady state={surveys.state} />;

  const formData = surveys.allSurveyDefinitions.findSurvey(surveyId);

  const schema = AllSurveysSchema.items as JSONSchema7;

  const uiSchema = AllSurveys_uiSchema.items;
  // uiSchema['ui:options'] = {
    // ...AllSurveys_uiSchema['ui:options'],
    // ...uiSchema['ui:options'],
  // };

  return (
    <div className="ui container">
      {formData ? (
        <JSONSchemaForm
          formData={formData}
          schema={schema}
          uiSchema={uiSchema}
          formContext={{
            'ui:options': {
              semantic: {
                wrapLabel: true,
                wrapContent: true,
              },
            },
          }}
          widgets={customWidgets}
          fields={customFields}
          // FieldTemplate={CustomFieldTemplate}
        />
      ) : null}
    </div>
  );
}
