/** @format */

import {JSONSchema7} from "json-schema";

const SurveysSchema/*: JSONSchema7*/ = {
  type: 'object',
  title: 'Настройка вопросов размерности',
  // definitions: {
  //   visibleIf: {
  //     type: 'string',
  //     title: 'Условие видимости элемента',
  //   },
  //   isRequired: {
  //     type: 'boolean',
  //     title: 'Значение обязательное',
  //   },
  // },
  properties: {
    pages: {
      type: 'array',
      title: '',
      items: {
        type: 'object',
        title: '',
        required: [
          'name',
          'title',
          // 'weight',
          // 'disabled',
          // 'maturityLevels',
          // 'aspectWeights',
        ],
        properties: {
          name: {
            type: 'string',
            title: 'Идентификатор страницы',
            default: 'p01',
          },
          title: {
            type: 'object',
            title: '',
            properties: {
              ru: {
                type: 'string',
                title: 'Текст заголовка страницы',
                // default: '',
              },
              // default: '',
            },
          },
          elements: {
            type: 'array',
            title: 'Список элементов опроса',
            items: {
              type: 'object',
              // title: 'Элемент опроса',
              // properties: {
              //     visibleIf: {
              //     type: 'string',
              //     title: 'Условие видимости элемента',
              //   },
              //   // type: {
              //   //   // enum: ['html'],
              //   //   // const: 'html',
              //   //   // default: 'html',
              //   //   type: 'string',
              //   //   title: 'Тип элемента',
              //   //   // 'ui-readonly': true,
              //   //   'ui-disabled': true,
              //   // },
              // },
              oneOf: [
                {
                  title: 'Тип элемента: текст HTML (html)',
                  required: ['type', 'name', 'html'],
                  properties: {
                    type: {
                      // enum: ['html'],
                      const: 'html',
                      default: 'html',
                      title: 'Тип элемента',
                    },
                    name: {
                      type: 'string',
                      title: 'Идентификатор элемента',
                    },
                    html: {
                      type: 'object',
                      title: '',
                      properties: {
                        ru: {
                          type: 'string',
                          title: 'Текст описания размерности',
                        },
                      },
                    },
                  },
                },
                {
                  title:
                    'Тип элемента: выбор одного значения из списка (radiogroup)',
                  required: ['type', 'name', 'title', 'choices'],
                  properties: {
                    type: {
                      // enum: ['radiogroup'],
                      const: 'radiogroup',
                      default: 'radiogroup',
                      title: 'Тип элемента',
                    },
                    name: {
                      type: 'string',
                      title: 'Идентификатор элемента',
                    },
                    title: {
                      type: 'object',
                      title: '',
                      properties: {
                        ru: {
                          type: 'string',
                          title: 'Текст вопроса',
                          // default: '',
                        },
                        // default: '',
                      },
                    },
                    description: {
                      type: 'object',
                      title: '',
                      properties: {
                        ru: {
                          type: 'string',
                          title: 'Описание вопроса',
                          // default: '',
                        },
                        // default: '',
                      },
                    },
                    visibleIf: {
                      type: 'string',
                      title: 'Условие видимости элемента',
                    },
                    // visibleIf: { "$ref": "#/definitions/visibleIf" },
                    defaultValue: {
                      type: 'string',
                      title: 'Значение по умолчанию (идентификатор ответа)',
                    },
                    isRequired: {
                      type: 'boolean',
                      title: 'Значение обязательное',
                    },
                    // choices: { "$ref": "#/definitions/choices" },
                    choices: {
                      type: 'array',
                      title: 'Варианты ответов',
                      items: {
                        type: 'object',
                        properties: {
                          value: {
                            type: 'string',
                            default: 'item1',
                            title: 'Идентификатор варианта ответа',
                          },
                          text: {
                            type: 'object',
                            title: '',
                            properties: {
                              ru: {
                                type: 'string',
                                default: 'Описание ответа',
                                title: 'Текст описания варианта ответа',
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
                {
                  title: 'Тип элемента: матрица (matrixdropdown)',
                  required: ['type', 'name', 'title', 'choices', 'columns'],
                  properties: {
                    type: {
                      // enum: ['matrixdropdown'],
                      const: 'matrixdropdown',
                      default: 'matrixdropdown',
                      title: 'Тип элемента',
                    },
                    name: {
                      type: 'string',
                      title: 'Идентификатор элемента',
                    },
                    title: {
                      type: 'object',
                      title: '',
                      required: ['ru'],
                      properties: {
                        ru: {
                          type: 'string',
                          title: 'Текст вопроса',
                          // default: '',
                        },
                        // default: '',
                      },
                    },
                    description: {
                      type: 'object',
                      title: '',
                      properties: {
                        ru: {
                          type: 'string',
                          title: 'Описание вопроса',
                          // default: '',
                        },
                        // default: '',
                      },
                    },
                    visibleIf: {
                      type: 'string',
                      title: 'Условие видимости элемента',
                    },
                    // visibleIf: { "$ref": "#/definitions/visibleIf" },
                    // isRequired: { $ref: '#/definitions/isRequired' },
                    isRequired: {
                      type: 'boolean',
                      title: 'Значение обязательное',
                    },
                    columns: {
                      type:'array',
                      title: 'Список столбцов матрицы',
                      items: {
                        type: 'object',
                        required: ['name','title'],
                        properties: {
                          name: {
                            type:'string',
                            title: 'Идентификатор столбца'
                          },
                          title: {
                            type: 'object',
                            title: '',
                            properties: {
                              ru: {
                                type: 'string',
                                title: 'Заголовок столбца',
                                // default: '',
                              },
                              // default: '',
                            },
                          },
                        }
                      }
                    },
                    choices: {
                      type: 'array',
                      title: 'Варианты ответов',
                      items: {
                        type: 'object',
                        required: ['name','text'],
                        properties: {
                          value: {
                            type: 'string',
                            default: 'item1',
                            title: 'Идентификатор варианта ответа',
                          },
                          text: {
                            type: 'object',
                            title: '',
                            required: ['ru'],
                            properties: {
                              ru: {
                                type: 'string',
                                default: 'Описание ответа',
                                title: 'Текст описания варианта ответа',
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              ],
            },
          },
        },
      },
    },
  },
};

export default SurveysSchema;
