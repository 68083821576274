/** @format */

import React, { PropsWithChildren } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle, faCircle } from '@fortawesome/free-regular-svg-icons';

import './SurveyCompleteIcon.scss';

export const SurveyCompleteIcon = ({
  completed,
  hasData,
}: {
  completed: boolean;
  hasData: boolean;
}) => {
  return (
    <span className="survey-complete-icon">
      {completed ? (
        <FontAwesomeIcon
          icon={faCheckCircle}
          // style={{ color: 'darkgreen', margin: '0 5px' }}
        />
      ) : hasData ? (
        <FontAwesomeIcon
          icon={faCircle}
          // style={{ color: 'darkgreen', margin: '0 5px' }}
        />
      ) : null}
    </span>
  );
};
