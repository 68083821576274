/** @format */

import JSONSchemaForm from '@rjsf/semantic-ui';
import { JSONSchema7 } from 'json-schema';
import React, { PropsWithChildren, useState, useEffect } from 'react';
import { NotReady } from '../../basic/NotReady';

import { CustomArrayField } from '../../rjsf';
import {
  SURVEY_EDIT_QUESTIONS_LIST_PATH,
  SURVEY_EDIT_QUESTIONS_PATH,
} from '@shared/config';
import Surveys_uiSchema from '@shared/schemas-ui/Surveys.ui-schema';
import { routeSubstitute, limitStr } from '../../../lib/utils';
import { Surveys } from '../../../lib/surveys';
import { Link } from '../../basic/Link';
import { Notfound } from '../../basic/Notfound';
import { LocalizableString } from '@shared/types/SurveyQuestions';
import { customFields, customWidgets } from '../../rjsf';
import { Title } from './components/Title';
import SurveysSchema_ from '@shared/schemas/Surveys.schema';
import AllSurveysSchema_ from '@shared/schemas/AllSurveys.schema';
const SurveysSchema = SurveysSchema_ as JSONSchema7;

const elementTypeDescriptions = {
  html: 'Блок HTML текста',
  radiogroup: 'Radio-кнопка',
  matrixdropdown: 'Матрица с выпадающими списками',
};

function getElementTypeDescription(type: string) {
  return elementTypeDescriptions[type] || type;
}

export const AdminListSurveyQuestion = (props: {
  surveys: Surveys;
  surveyId: string;
}) => {
  console.log('SurveyEditAspectsList props:', props);
  const { surveys, surveyId } = props;
  if (surveys.state !== 'ready') return <NotReady state={surveys.state} />;

  const surveyQuestions = surveys.getQuestions(surveyId);
  const surveyDefinitions = surveys.getDefinition(surveyId);
  if (
    !surveyQuestions ||
    !surveyDefinitions ||
    surveyQuestions._type !== 'survey-js'
  )
    return <Notfound message="Раздел опроса не найден" />;

  return (
    <nav className="ui main container">
      <header className="ui container">
        <h2 className="ui center aligned header">
          Редактирование вопросов для размерности "{surveyDefinitions?.name}"
        </h2>
        <h3>
          Выберите вопрос размерности "{surveyDefinitions?.name}" для его
          редактирования:
        </h3>
      </header>
      <main>
        {surveyQuestions.pages.map((p) => (
          <ul key={p.name}>
            {p.elements.map((pe) => (
              <li
                // style={{ display: 'flex', flexBasis: 'flex-start' }}
                key={`${p.name}_${pe.name}`}
              >
                <Link
                  to={routeSubstitute(SURVEY_EDIT_QUESTIONS_PATH, {
                    surveyId,
                    questionId: pe.name,
                  })}
                >
                  [{pe.name}] &nbsp;
                  {pe.type === 'html' ? (
                    // HTML has no `title` property, only `html`
                    <Title title={pe.html} />
                  ) : (
                    <Title title={pe.title} />
                  )}
                  &nbsp; [{getElementTypeDescription(pe.type)}] &nbsp;
                </Link>
              </li>
            ))}
          </ul>
        ))}
      </main>
    </nav>
  );

  const schema = SurveysSchema as JSONSchema7;

  const uiSchema = Surveys_uiSchema;
  // uiSchema['ui:options'] = {};

  customFields['ArrayField'] = CustomArrayField;

  return (
    <div className="ui container">
      {surveyQuestions ? (
        <JSONSchemaForm
          formData={surveyQuestions}
          schema={schema}
          uiSchema={uiSchema}
          formContext={{
            'ui:options': {
              semantic: {
                wrapLabel: true,
                wrapContent: true,
              },
            },
          }}
          widgets={customWidgets}
          fields={customFields}
          // FieldTemplate={CustomFieldTemplate}
        />
      ) : null}
    </div>
  );
};
