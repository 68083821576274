/** @format */

import { AllSurveysDefinitions } from '@shared/types/SurveyDefinitions';

const surveyDefinitions: AllSurveysDefinitions = [
  {
    surveyId: '1',
    name: 'Стратегия и регулирование',
    weight: 0.142857143,
    disabled: false,
    maturityLevels: [
      {
        level: 1,
        name: 'Начальный',
        html1:
          '<div>\n  <p>По направлению "Стратегия и регулирование" Ваша организация находится на <strong>начальном</strong> уровне зрелости в работе с большими данными.</p>\n</div>',
        html2:
          '<div>\n  <p>Для данного уровня характерны следующие характеристики:</p>\n  <ul>\n    <li>У организации нет стратегии работы с большими данными;</li>\n    <li>В организации отсутствует осведомленность о требованиях нормативного правового регулирования, стандартах и принципах саморегулирования работы с большими данными.</li>\n  </ul>\n</div>',
      },
      {
        level: 2,
        name: 'Формирующийся',
        html1:
          '<div> \
                  <p>По направлению "Стратегия и регулирование" Ваша организация достигла <strong>формирующегося</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div>\
  <p>Для данного уровня характерны следующие характеристики:</p>\
  <ul>\
    <li>Организация имеет стратегию работы с большими данными.</li>                     <li>В стратегии работы с большими данными сформулированы цели и/или измеримые показатели их достижения.</li>                   <li>В организации нет плана действий по реализации стратегии работы с большими данными.</li>               <li>Организация в полной мере не обеспечена ресурсами для реализации стратегии работы с большими данными.</li>     \
    <li>Стратегия организации не предполагает проведение экспериментов с перспективными технологиями работы с большими данными.</li>\
  </ul>   \
</div>',
      },
      {
        level: 3,
        name: 'Средний',
        html1:
          '<div> \
                  <p>По направлению "Стратегия и регулирование" Ваша организация достигла <strong>среднего</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div> \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>В организации есть стратегия работы с большими данными, согласованная со стратегий развития организации, определены цели и измеримые показатели их достижения;</li> \
                    <li>Разработан план реализации стратегии работы с большими данными и показатели результативности мероприятий;</li> \
                    <li>Ощущается нехватка ресурсов для полноценной реализации стратегии работы с большими данными;</li> \
                    <li>Ведется деятельность по приведению в соответствие работы с большими данными требованиям нормативного правового регулирования, стандартов и принципам саморегулирования.</li> \
                  </ul> \
                 </div>',
      },
      {
        level: 4,
        name: 'Продвинутый',
        html1:
          '<div> \
                  <p>По направлению "Стратегия и регулирование" Ваша организация достигла <strong>продвинутого</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div> \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>В организации есть стратегия работы с большими данными, согласованная со стратегий развития организации, определены цели и измеримые показатели их достижения;</li> \
                    <li>Разработан план реализации стратегии работы с большими данными и показатели результативности мероприятий;</li> \
                    <li>Достаточно ресурсов для полноценной реализации стратегии работы с большими данными;</li> \
                    <li>Отсутствуют мероприятия, нацеленные на эксперименты с перспективными технологиями работы с большими данными</li> \
                    <li>Деятельность организации соответствует требованиям нормативного правового регулирования, требованиям стандартов и принципам саморегулирования работы с большими данными.</li> \
                  </ul> \
                </div>',
      },
      {
        level: 5,
        name: 'Высокий',
        html1:
          '<div> \
                  <p>По направлению "Стратегия и регулирование" Ваша организация достигла <strong>высокого</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div> \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>В организации есть стратегия работы с большими данными, согласованная со стратегий развития организации, определены цели и измеримые показатели их достижения;</li> \
                    <li>Разработан план реализации стратегии работы с большими данными и показатели результативности мероприятий;</li> \
                    <li>Достаточно ресурсов для полноценной реализации стратегии работы с большими данными;</li> \
                    <li>В стратегии есть мероприятия, нацеленные на эксперименты с перспективными технологиями работы с большими данными</li> \
                    <li>Деятельность организации соответствует требованиям нормативного правового регулирования, требованиям стандартов и принципам саморегулирования работы с большими данными</li> \
                    <li>Организация участвует в развитии нормативного правового, технического регулирования и  zпринципов саморегулирования работы с большими данными.</li> \
                  </ul> \
                </div>',
      },
    ],
    aspectWeights: [
      {
        id: 'a1',
        name: 'Стратегия',
        w: 0.25,
        q: [
          { q: '{r1q01}="item1"', w: 1 },
          { q: '{r1q02}="item1" and {r1q03}="item2"', w: 2 },
          { q: '{r1q02}="item2" and {r1q03}="item1"', w: 2 },
          { q: '{r1q02}="item2" and {r1q03}="item2"', w: 2 },
          { q: '{r1q04}="item1"', w: 2 },
          { q: '{r1q04}="item2" and {r1q05}="item1"', w: 3, },
          // { q: '{r1q04}="item2" and {r1q05}="item1" and {r1q06}="item2" and {r1q07}="item2"', w: 3, },
          { q: '{r1q04}="item2" and {r1q05}="item2" and {r1q06}="item2"', w: 4, },
          { q: '{r1q04}="item2" and {r1q05}="item2" and {r1q06}="item1" and {r1q07}="item2"', w: 4, },
          { q: '{r1q04}="item2" and {r1q05}="item2" and {r1q06}="item1" and {r1q07}="item1"', w: 5, },
        ],
      },
      {
        id: 'a2',
        name: 'Нормативное регулирование',
        w: 0.25,
        q: [
          { q: '{r1q08}="item1"', w: 1 }, //{ q: '{r1q08}="item2"', w: 0, },
          { q: '{r1q09}="item2" and {r1q10}="item2"', w: 5 },
          { q: '{r1q09}="item1" and {r1q10}="item2"', w: 4 },
          { q: '{r1q11}="item1"', w: 2 },
          { q: '{r1q11}="item2"', w: 3 },
        ],
      },
      {
        id: 'a3',
        name: 'Техническое регулирование',
        w: 0.25,
        q: [
          { q: '{r1q12}="item1"', w: 1 }, //{ q: '{r1q12}="item2"', w: 0, },
          { q: '{r1q13}="item2" and {r1q14}="item2"', w: 5 },
          { q: '{r1q13}="item1" and {r1q14}="item2"', w: 4 },
          { q: '{r1q15}="item1"', w: 2 },
          { q: '{r1q15}="item2"', w: 3 },
        ],
      },
      {
        id: 'a4',
        name: 'Саморегулирование',
        w: 0.25,
        q: [
          { q: '{r1q16}="item1"', w: 1 }, //{ q: '{r1q16}="item2"', w: 0, },
          { q: '{r1q17}="item2" and {r1q18}="item2"', w: 5 },
          { q: '{r1q17}="item1" and {r1q18}="item2"', w: 4 },
          { q: '{r1q19}="item1"', w: 2 },
          { q: '{r1q19}="item2"', w: 3 },
        ],
      },
    ],
  },
  {
    surveyId: '2',
    name: 'Кадры и лидерство',
    weight: 0.142857143,
    disabled: false,
    maturityLevels: [
      {
        level: 1,
        name: 'Начальный',
        html1:
          '<div> \
                  <p>По направлению "Кадры и лидерство" Ваша организация находится на <strong>начальном</strong> уровне зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div> \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>Сотрудники организации и ее руководители не осведомлены о возможностях работы с большими данными;</li> \
                    <li>Как следствие в организации отсутствуют специалисты по работе с большими данными и нет системы повышения уровня компетенций кадров для работы с большими данными.</li> \
                  </ul> \
                </div>',
      },
      {
        level: 2,
        name: 'Формирующийся',
        html1:
          '<div> \
                  <p>По направлению "Кадры и лидерство" Ваша организация достигла <strong>формирующегося</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div>, \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>Сотрудники организации и ее руководители осведомлены о возможностях работы с большими данными;</li> \
                    <li>В организации есть специалисты по работе с большими данными, но потребность организации в них не удовлетворена;</li> \
                    <li>У сотрудников не хватает навыков для работы с большими данными в своей профессиональной деятельности, а руководители не используют аналитику на основе больших данных в процессах принятия решений;</li> \
                    <li>Отсутствует система повышения уровня компетенций кадров для работы с большими данными;</li> \
                    <li>В организации нет руководителей высокого уровня, являющихся "лидерами" по работе с большими данными.</li> \
                  </ul> \
                </div>',
      },
      {
        level: 3,
        name: 'Средний',
        html1:
          '<div> \
                  <p>По направлению "Кадры и лидерство" Ваша организация достигла <strong>среднего</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div> \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>Сотрудники организации и ее руководители осведомлены о возможностях работы с большими данными;</li> \
                    <li>В организации есть специалисты по работе с большими данными, но потребность организации в них удовлетворена лишь частично;</li> \
                    <li>Не все сотрудники обладают необходимыми навыками работы с большими данными для использования в своей профессиональной деятельности, и не все руководители высокого уровня используют аналитику больших данных в своей профессиональной;</li> \
                    <li>В организации сформирована система повышения уровня компетенций кадров в области работы с большими данными, но потребность организации она удовлетворяет частично;</li> \
                    <li>Некоторые руководители высокого уровня начали использовать аналитику больших данных в своей профессиональной деятельности</li> \
                    <li>Среди руководителей высокого уровня есть небольшое количество "лидеров" по работе с большими данными.</li> \
                  </ul> \
                 </div>',
      },
      {
        level: 4,
        name: 'Продвинутый',
        html1:
          '<div> \
                  <p>По направлению "Кадры и лидерство" Ваша организация достигла <strong>продвинутого</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div> \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>Большинство сотрудников и руководителей организации обладают необходимыми навыками работы с большими данными и используют ее в своей профессиональной деятельности;</li> \
                    <li>В организации есть специалисты по работе с большими данными, есть система повышения уровня компетенций кадров в области работы с большими данными, потребность организации в таких специалистах в основном удовлетворена;</li> \
                    <li>Большинство руководителей высокого уровня являются "лидерами" по работе с большими данными.</li> \
                  </ul> \
                </div>',
      },
      {
        level: 5,
        name: 'Высокий',
        html1:
          '<div> \
                  <p>По направлению "Кадры и лидерство" Ваша организация достигла <strong>высокого</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div> \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>Все сотрудники и руководители организации обладают необходимыми навыками работы с большими данными и используют ее в своей профессиональной деятельности;</li> \
                    <li>В организации есть специалисты по работе с большими данными, есть система повышения уровня компетенций кадров в области работы с большими данными, потребность организации в таких специалистах полностью удовлетворена;</li> \
                    <li>Все руководители высокого уровня являются "лидерами" по работе с большими данными.</li> \
                  </ul> \
                </div>',
      },
    ],
    aspectWeights: [
      {
        id: 'a1',
        name: 'Осведомленность сотрудников',
        w: 0.16666666666666666,
        q: [
          { q: '{r1q01}="item1"', w: 1 },
          //
          { q: '{r1q02}="item1"', w: 5 },
          { q: '{r1q02}="item2"', w: 4 },
          { q: '{r1q02}="item3"', w: 3 },
          { q: '{r1q02}="item4"', w: 2 },
        ],
      },
      {
        id: 'a2',
        name: 'Наличие профессионалов',
        w: 0.16666666666666666,
        q: [
          { q: '{r1q01}="item1"', w: 1 },

          { q: '{r1q03}="item1"', w: 1 },

          { q: '{r1q04}="item1"', w: 5 },
          { q: '{r1q04}="item2"', w: 4 },
          { q: '{r1q04}="item3"', w: 3 },
          { q: '{r1q04}="item4"', w: 2 },
        ],
      },
      {
        id: 'a3',
        name: 'Система повышения уровня компетенций',
        w: 0.16666666666666666,
        q: [
          { q: '{r1q01}="item1"', w: 1 },

          { q: '{r1q05}="item1"', w: 1 },

          { q: '{r1q06}="item1"', w: 5 },
          { q: '{r1q06}="item2"', w: 4 },
          { q: '{r1q06}="item3"', w: 3 },
          { q: '{r1q06}="item4"', w: 2 },
        ],
      },
      {
        id: 'a4',
        name: 'Осведомленность руководства',
        w: 0.16666666666666666,
        q: [
          { q: '{r1q01}="item1"', w: 1 },
          //
          { q: '{r1q07}="item1"', w: 1 },
          { q: '{r1q08}="item1"', w: 5 },
          { q: '{r1q08}="item2"', w: 4 },
          { q: '{r1q08}="item3"', w: 3 },
          { q: '{r1q08}="item4"', w: 2 },
        ],
      },
      {
        id: 'a5',
        name: 'Наличие лидеров',
        w: 0.16666666666666666,
        q: [
          { q: '{r1q01}="item1"', w: 1 },
          //
          { q: '{r1q09}="item1"', w: 1 },
          { q: '{r1q10}="item1"', w: 5 },
          { q: '{r1q10}="item2"', w: 4 },
          { q: '{r1q10}="item3"', w: 3 },
          { q: '{r1q10}="item4"', w: 2 },
        ],
      },
      {
        id: 'a6',
        name: 'Использование аналитики руководством',
        w: 0.16666666666666666,
        q: [
          { q: '{r1q01}="item1"', w: 1 },
          //
          { q: '{r1q11}="item1"', w: 2 },
          { q: '{r1q12}="item1"', w: 5 },
          { q: '{r1q12}="item2"', w: 4 },
          { q: '{r1q12}="item3"', w: 3 },
          //{ q: '{r1q12}="item4"', w: 2, },
        ],
      },
    ],
  },
  {
    surveyId: '3',
    name: 'Данные',
    weight: 0.142857143,
    disabled: false,
    maturityLevels: [
      {
        level: 1,
        name: 'Начальный',
        html1:
          '<div> \
                  <p>По направлению "Данные" Ваша организация находится на <strong>начальном</strong> уровне зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div> \
                  <p>Для данного уровня характерно отсутствие больших данных в организации.</p> \
                </div>',
      },
      {
        level: 2,
        name: 'Формирующийся',
        html1:
          '<div> \
                  <p>По направлению "Данные" Ваша организация достигла <strong>формирующегося</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div>, \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>Организация начала использовать большие данные в некоторых деловых процессах, для которых решаются задачи обеспечения качества данных, использования мастер данных, интеграции данных и совместного использования.</li> \
                  </ul> \
                </div>',
      },
      {
        level: 3,
        name: 'Средний',
        html1:
          '<div> \
                  <p>По направлению "Данные" Ваша организация достигла <strong>среднего</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div> \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>Организация начала использовать большие данные в основных деловых процессах, для которых решаются задачи обеспечения качества данных, использования мастер данных, интеграции данных и совместного использования.</li> \
                  </ul> \
                 </div>',
      },
      {
        level: 4,
        name: 'Продвинутый',
        html1:
          '<div> \
                  <p>По направлению "Данные" Ваша организация достигла <strong>продвинутого</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div> \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>Организация обеспечила использование больших данных в основных деловых процессах организации, а также обеспечивающих процессах или процессах управления организацией, для которых решаются задачи обеспечения качества данных, использования мастер данных, интеграции данных и совместного использования.</li> \
                    </ul> \
                </div>',
      },
      {
        level: 5,
        name: 'Высокий',
        html1:
          '<div> \
                  <p>По направлению "Данные" Ваша организация достигла <strong>высокого</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div> \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>Организация обеспечила использование больших данных для всех деятельности компании, решены задачи обеспечения качества данных, использования мастер данных, интеграции данных и совместного использования, в том числе и для заинтересованных контрагентов, работа с большими данными ведется на всех этапах их жизненного цикла.</li> \
                  </ul> \
                </div>',
      },
    ],
    aspectWeights: [
      {
        id: 'a1',
        name: 'Обеспеченность',
        description: 'Обеспеченность большими данными',
        w: 0.16666666666666666,
        q: [
          { q: '{r1q01}="item1"', w: 1 },

          { q: '{r1q02}="item1"', w: 5 },
          { q: '{r1q02}="item2"', w: 4 },
          { q: '{r1q02}="item3"', w: 3 },
          { q: '{r1q02}="item4"', w: 2 },
        ],
      },
      {
        id: 'a2',
        name: 'Качество',
        description: 'Качество больших данных',
        w: 0.16666666666666666,
        q: [
          { q: '{r1q01}="item1"', w: 1 },

          { q: '{r1q03}="item1"', w: 5 },
          { q: '{r1q03}="item2"', w: 4 },
          { q: '{r1q03}="item3"', w: 3 },
          { q: '{r1q03}="item4"', w: 2 },
        ],
      },
      {
        id: 'a3',
        name: 'Интеграция',
        description: 'Интеграция больших данных',
        w: 0.16666666666666666,
        q: [
          { q: '{r1q01}="item1"', w: 1 },

          { q: '{r1q04}="item1"', w: 5 },
          { q: '{r1q04}="item2"', w: 4 },
          { q: '{r1q04}="item3"', w: 3 },
          { q: '{r1q04}="item4"', w: 2 },
        ],
      },
      {
        id: 'a4',
        name: 'Совместное использование',
        description: 'Совместное использование больших данных',
        w: 0.16666666666666666,
        q: [
          { q: '{r1q01}="item1"', w: 1 },

          { q: '{r1q05}="item1"', w: 5 },
          { q: '{r1q05}="item2"', w: 4 },
          { q: '{r1q05}="item3"', w: 3 },
          { q: '{r1q05}="item4"', w: 2 },
        ],
      },
      {
        id: 'a5',
        name: 'Работа на стадиях жизненного цикла',
        description:
          'Работа с большими данными в организации на стадиях жизненного цикла',
        w: 0.16666666666666666,
        q: [
          { q: '{r1q01}="item1"', w: 1 },
          //
          // - не менее 3 баллов для основных деловых процессов & не менее 3
          //   баллов для обеспечивающих процессов & не менее 3 баллов для
          //   процессов управления - 5
          // - не менее 3 баллов для основных деловых процессов & также не менее
          //   3 баллов для обеспечивающих процессов или процессов управления
          //   организацией - 4
          // - не менее 3 баллов для основных деловых процессов - 3
          // - набирает не менее 1 балла для всех видов деловых процессов - 2
          // - 0 баллов - 1
          //
          {
            q:
              '( Number({r1q06.Row1.Col1})+Number({r1q06.Row1.Col2})+Number({r1q06.Row1.Col3})+Number({r1q06.Row1.Col4}) >= 3 ) and ( Number({r1q06.Row2.Col1})+Number({r1q06.Row2.Col2})+Number({r1q06.Row2.Col3})+Number({r1q06.Row2.Col4}) >= 3) and (Number({r1q06.Row3.Col1})+Number({r1q06.Row3.Col2})+Number({r1q06.Row3.Col3})+Number({r1q06.Row3.Col4}) >= 3)',
            w: 5,
          },
          {
            q:
              '( Number({r1q06.Row1.Col1})+Number({r1q06.Row1.Col2})+Number({r1q06.Row1.Col3})+Number({r1q06.Row1.Col4})  >= 3 )  and ( (Number({r1q06.Row2.Col1})+Number({r1q06.Row2.Col2})+Number({r1q06.Row2.Col3})+Number({r1q06.Row2.Col4})  >= 3 ) || (Number({r1q06.Row3.Col1})+Number({r1q06.Row3.Col2})+Number({r1q06.Row3.Col3})+Number({r1q06.Row3.Col4})  >= 3 ) )',
            w: 4,
          },
          {
            q:
              '( Number({r1q06.Row1.Col1})+Number({r1q06.Row1.Col2})+Number({r1q06.Row1.Col3})+Number({r1q06.Row1.Col4}) >= 3 )',
            w: 3,
          },
          {
            q:
              '( Number({r1q06.Row1.Col1})+Number({r1q06.Row1.Col2})+Number({r1q06.Row1.Col3})+Number({r1q06.Row1.Col4}) >= 1 )',
            w: 2,
          },
          {
            q:
              '( Number({r1q06.Row1.Col1})+Number({r1q06.Row1.Col2})+Number({r1q06.Row1.Col3})+Number({r1q06.Row1.Col4}) = 0 )',
            w: 2,
          },
          //{ q: '1', w: 1, }, // default: the last option, always returns true
        ],
      },
      {
        id: 'a6',
        name: 'Мастер данные',
        w: 0.16666666666666666,
        q: [
          { q: '{r1q07}="item1"', w: 5 },
          { q: '{r1q07}="item2"', w: 4 },
          { q: '{r1q07}="item3"', w: 3 },
          { q: '{r1q07}="item4"', w: 2 },
        ],
      },
    ],
  },
  {
    surveyId: '4',
    name: 'Инструменты и аналитика',
    weight: 0.142857143,
    disabled: false,
    maturityLevels: [
      {
        level: 1,
        name: 'Начальный',
        html1:
          '<div> \
                  <p>По направлению "Инструменты и аналитика" Ваша организация находится на <strong>начальном</strong> уровне зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div> \
                  <p>Для данного уровня характерно отсутствие использование специализированных инструментов для работы с большими данными и использование аналитики.</p> \
                </div>',
      },
      {
        level: 2,
        name: 'Формирующийся',
        html1:
          '<div> \
                  <p>По направлению "Инструменты и аналитика" Ваша организация достигла <strong>формирующегося</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div>, \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>Организация начала использовать специализированные инструменты для работы с большими данными, но количество деловых процессов организации, для которых они используются, еще незначительное.</li> \
                    <li>Аналитика регулярно используется только в некоторых деловых процессах, а виды аналитики, используемые в организации для поддержки бизнес- процессов ограничены.</li> \
                  </ul> \
                </div>',
      },
      {
        level: 3,
        name: 'Средний',
        html1:
          '<div> \
                  <p>По направлению "Инструменты и аналитика" Ваша организация достигла <strong>среднего</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div> \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>Организация начала использовать специализированные инструменты для работы с большими данными, но поддержаны только основные деловые процессы, для них же регулярно используется и аналитика больших данных.</li> \
                  </ul> \
                 </div>',
      },
      {
        level: 4,
        name: 'Продвинутый',
        html1:
          '<div> \
                  <p>По направлению "Инструменты и аналитика" Ваша организация достигла <strong>продвинутого</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div> \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>Организация начала широко применять специализированные инструменты для работы с большими данными как для основных деловых процессов, так и для обеспечивающих или процессов управления организацией, для них же регулярно используется и аналитика больших данных.</li> \
                    </ul> \
                </div>',
      },
      {
        level: 5,
        name: 'Высокий',
        html1:
          '<div> \
                  <p>По направлению "Инструменты и аналитика" Ваша организация достигла <strong>высокого</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div> \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>Организация начала широко применять специализированные инструменты для работы с большими данными как для основных деловых процессов, так и для обеспечивающих и процессов управления организацией, при этом вся деятельность организации основана на использовании аналитики больших данных.</li> \
                  </ul> \
                </div>',
      },
    ],
    aspectWeights: [
      {
        id: 'a1',
        name: 'Использование специализированных инструментов',
        description:
          'Использование специализированных инструментов работы с большими данными',
        w: 0.3333333333333333,
        q: [
          { q: '{r1q01}="item1"', w: 1 },
          // Не менее 6 баллов по основным деловым процессам & 5 баллов по обеспечивающим процессам & 5 баллов по процессам управления организацией
          //Не менее 5 баллов по основным деловым процессам & не менее 5 баллов по обеспечивающим или процессам управления организацией
          //Не менее 3 баллов по основным деловым процессам
          //Не менее 1 балла
          {
            q:
              '( Number({r1q02.Row1.Col1})+Number({r1q02.Row1.Col2})+Number({r1q02.Row1.Col3})+Number({r1q02.Row1.Col4})+Number({r1q02.Row1.Col5})+Number({r1q02.Row1.Col6}) >= 6 ) and ( Number({r1q02.Row2.Col1})+Number({r1q02.Row2.Col2})+Number({r1q02.Row2.Col3})+Number({r1q02.Row2.Col4})+Number({r1q02.Row2.Col5})+Number({r1q02.Row2.Col6}) >= 5) and (Number({r1q02.Row3.Col1})+Number({r1q02.Row3.Col2})+Number({r1q02.Row3.Col3})+Number({r1q02.Row3.Col4})+Number({r1q02.Row3.Col5})+Number({r1q02.Row3.Col6}) >= 5)',
            w: 5,
          },
          {
            q:
              '( Number({r1q02.Row1.Col1})+Number({r1q02.Row1.Col2})+Number({r1q02.Row1.Col3})+Number({r1q02.Row1.Col4})+Number({r1q02.Row1.Col5})+Number({r1q02.Row1.Col6}) >= 6 ) and ( ( Number({r1q02.Row2.Col1})+Number({r1q02.Row2.Col2})+Number({r1q02.Row2.Col3})+Number({r1q02.Row2.Col4})+Number({r1q02.Row2.Col5})+Number({r1q02.Row2.Col6}) >= 5) || (Number({r1q02.Row3.Col1})+Number({r1q02.Row3.Col2})+Number({r1q02.Row3.Col3})+Number({r1q02.Row3.Col4})+Number({r1q02.Row3.Col5})+Number({r1q02.Row3.Col6}) >= 5) )',
            w: 4,
          },
          {
            q:
              '( Number({r1q02.Row1.Col1})+Number({r1q02.Row1.Col2})+Number({r1q02.Row1.Col3})+Number({r1q02.Row1.Col4})+Number({r1q02.Row1.Col5})+Number({r1q02.Row1.Col6}) >= 3 )',
            w: 3,
          },
          {
            q:
              '( Number({r1q02.Row1.Col1})+Number({r1q02.Row1.Col2})+Number({r1q02.Row1.Col3})+Number({r1q02.Row1.Col4})+Number({r1q02.Row1.Col5})+Number({r1q02.Row1.Col6}) >= 1 )',
            w: 2,
          },
          { q: '1', w: 1 }, // default: the last option, always returns true
        ],
      },
      {
        id: 'a2',
        name: 'Регулярность использования аналитики',
        description: 'Регулярность использования аналитики больших данных',
        w: 0.3333333333333333,
        q: [
          { q: '{r1q01}="item1"', w: 1 },
          { q: '{r1q03}="item1"', w: 1 },
          { q: '{r1q04}="item1"', w: 5 },
          { q: '{r1q04}="item2"', w: 4 },
          { q: '{r1q04}="item3"', w: 3 },
          { q: '{r1q04}="item4"', w: 2 },
        ],
      },
      {
        id: 'a3',
        name: 'Направления использования аналитики',
        description: 'Направления использования аналитики больших данных',
        w: 0.3333333333333333,
        q: [
          { q: '{r1q01}="item1"', w: 1 },
          { q: '{r1q03}="item1"', w: 1 },
          // - Не менее 1 балла по основным деловым процессам & не менее 1 балла по обеспечивающим процессам & не менее 1 балла по процессам управления организацией - 5
          // - Не менее 1 балла по основным деловым процессам & не менее 1 балла по обеспечивающим процессам или процессам управления организацией - 4
          // - Не менее 1 балла по основным деловым процессам - 3
          // - Не менее 1 балла по любым процессам кроме основных - 2
          // - 0 баллов - 1
          {
            q:
              '( Number({r1q05.Row1.Col1})+Number({r1q05.Row1.Col2})+Number({r1q05.Row1.Col3})+Number({r1q05.Row1.Col4}) >= 1 ) and ( Number({r1q05.Row2.Col1})+Number({r1q05.Row2.Col2})+Number({r1q05.Row2.Col3})+Number({r1q05.Row2.Col4}) >= 1) and (Number({r1q05.Row3.Col1})+Number({r1q05.Row3.Col2})+Number({r1q05.Row3.Col3})+Number({r1q05.Row3.Col4}) >= 1)',
            w: 5,
          },
          {
            q:
              '( Number({r1q05.Row1.Col1})+Number({r1q05.Row1.Col2})+Number({r1q05.Row1.Col3})+Number({r1q05.Row1.Col4}) >= 1 ) and ( ( Number({r1q05.Row2.Col1})+Number({r1q05.Row2.Col2})+Number({r1q05.Row2.Col3})+Number({r1q05.Row2.Col4}) >= 1) || (Number({r1q05.Row3.Col1})+Number({r1q05.Row3.Col2})+Number({r1q05.Row3.Col3})+Number({r1q05.Row3.Col4}) >= 1) )',
            w: 4,
          },
          {
            q:
              '( Number({r1q05.Row1.Col1})+Number({r1q05.Row1.Col2})+Number({r1q05.Row1.Col3})+Number({r1q05.Row1.Col4}) >= 1 )',
            w: 3,
          },
          {
            q:
              '( Number({r1q05.Row2.Col1})+Number({r1q05.Row2.Col2})+Number({r1q05.Row2.Col3})+Number({r1q05.Row2.Col4}) >= 1 ) || ( Number({r1q05.Row3.Col1})+Number({r1q05.Row3.Col2})+Number({r1q05.Row3.Col3})+Number({r1q05.Row3.Col4}) >= 1 )',
            w: 2,
          },
        ],
      },
    ],
  },
  {
    surveyId: '5',
    name: 'Инфраструктура и безопасность',
    weight: 0.142857143,
    disabled: false,
    maturityLevels: [
      {
        level: 1,
        name: 'Начальный',
        html1:
          '<div> \
                  <p>По направлению "Инфраструктура и безопасность" Ваша организация находится на <strong>начальном</strong> уровне зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div> \
                  <p>Для данного уровня характерно отсутствие инфраструктуры, необходимой для работы с большими данными.</p> \
                </div>',
      },
      {
        level: 2,
        name: 'Формирующийся',
        html1:
          '<div> \
                  <p>По направлению "Инфраструктура и безопасность" Ваша организация достигла <strong>формирующегося</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div>, \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>Имеющаяся в организации инфраструктура для работы с большими данными обеспечивает отдельные регламентированные деловые процессы.</li> \
                    <li>У сотрудников нет необходимых навыков по обеспечению доверия и безопасности работы с большими данными.</li> \
                    <li>Потребность организации в специалистах по обеспечению доверия и безопасности работы с большими данными не удовлетворена.</li> \
                    <li>Специализированные средства обеспечения доверия и безопасности работы с большими данными в организации используются для отдельных бизнес-процессов.</li> \
                  </ul> \
                </div>',
      },
      {
        level: 3,
        name: 'Средний',
        html1:
          '<div> \
                  <p>По направлению "Инфраструктура и безопасность" Ваша организация достигла <strong>среднего</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div> \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>Имеющаяся в организации инфраструктура для работы с большими данными обеспечивает основные деловые процессы, они же, как правило регламентированы.</li> \
                    <li>Некоторые сотрудники обладают необходимыми навыками по обеспечению доверия и безопасности работы с большими данными.</li> \
                    <li>Потребность организации в специалистах по обеспечению доверия и безопасности работы с большими данными частично удовлетворена.</li> \
                    <li>Специализированные средства обеспечения доверия и безопасности работы с большими данными в организации используются для обеспечения основных бизнес-процессов.</li> \
                  </ul> \
                 </div>',
      },
      {
        level: 4,
        name: 'Продвинутый',
        html1:
          '<div> \
                  <p>По направлению "Инфраструктура и безопасность" Ваша организация достигла <strong>продвинутого</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div> \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>Имеющаяся в организации инфраструктура для работы с большими данными обеспечивает основные деловые процессы, а также обеспечивающие процессы или процессы управления организацией, они же, как правило регламентированы.</li> \
                    <li>Большинство сотрудников обладают необходимыми навыками по обеспечению доверия и безопасности работы с большими данными.</li> \
                    <li>Потребность организации в специалистах по обеспечению доверия и безопасности работы с большими данными в основном удовлетворена.</li> \
                    <li>Специализированные средства обеспечения доверия и безопасности работы с большими данными в организации используются для обеспечения не только основных бизнес-процессов, но и других.</li> \
                    </ul> \
                </div>',
      },
      {
        level: 5,
        name: 'Высокий',
        html1:
          '<div> \
                  <p>По направлению "Инфраструктура и безопасность" Ваша организация достигла <strong>высокого</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div> \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>Существующая инфраструктура работы с большими данными полностью обеспечивает всю деятельность организации, в том числе, может быть легко масштабируема под новые потребности.</li> \
                    <li>Вся деятельность организации регламентирована с точки зрения обеспечения доверия и безопасности работы с большими данными.</li> \
                    <li>Все сотрудников обладают необходимыми навыками по обеспечению доверия и безопасности работы с большими данными, а потребность организации в специалистах по обеспечению доверия и безопасности работы с большими данными, полностью удовлетворена.</li> \
                    <li>Специализированные средства обеспечения доверия и безопасности работы с большими данными в организации используются для обеспечения всех бизнес-процессов.</li> \
                  </ul> \
                </div>',
      },
    ],
    aspectWeights: [
      {
        id: 'a1',
        name: 'Наличие инфраструктуры',
        description: 'Наличие инфраструктуры для работы с большими данными',
        w: 0.16666666666666666,
        q: [
          { q: '{r1q01}="item1"', w: 1 },
          //
          { q: '{r1q02}="item1"', w: 5 },
          { q: '{r1q02}="item2"', w: 4 },
          { q: '{r1q02}="item3"', w: 3 },
          { q: '{r1q02}="item4"', w: 2 },
        ],
      },
      {
        id: 'a2',
        name: 'Масштабируемость инфраструктуры',
        description:
          'Масштабируемость инфраструктуры для работы с большими данными',
        w: 0.16666666666666666,
        q: [
          { q: '{r1q01}="item1"', w: 1 },
          //
          { q: '{r1q03}="item1"', w: 5 },
          { q: '{r1q03}="item2"', w: 4 },
          { q: '{r1q03}="item3"', w: 3 },
          { q: '{r1q03}="item4"', w: 2 },
        ],
      },
      {
        id: 'a3',
        name: 'Регламентирование процессов обеспечения доверия и безопасности',
        description:
          'Регламентирование процессов обеспечения доверия и безопасности работы с большими данными',
        w: 0.16666666666666666,
        q: [
          { q: '{r1q01}="item1"', w: 1 },
          //
          { q: '{r1q04}="item1"', w: 1 },
          { q: '{r1q05}="item1"', w: 5 },
          { q: '{r1q05}="item2"', w: 4 },
          { q: '{r1q05}="item3"', w: 3 },
          { q: '{r1q05}="item4"', w: 2 },
        ],
      },
      {
        id: 'a4',
        name: 'Навыки сотрудников по обеспечению доверия и безопасности',
        description:
          'Навыки сотрудников по обеспечению доверия и безопасности работы с большими данными',
        w: 0.16666666666666666,
        q: [
          { q: '{r1q01}="item1"', w: 1 },
          //
          { q: '{r1q06}="item1"', w: 1 },
          { q: '{r1q07}="item1"', w: 2 },
          { q: '{r1q08}="item1"', w: 5 },
          { q: '{r1q08}="item2"', w: 4 },
          { q: '{r1q08}="item3"', w: 3 },
        ],
      },
      {
        id: 'a5',
        name:
          'Потребности в специалистах по обеспечению доверия и безопасности',
        description:
          'Потребности в специалистах по обеспечению доверия и безопасности работы с большими данными',
        w: 0.16666666666666666,
        q: [
          { q: '{r1q01}="item1"', w: 1 },
          //
          { q: '{r1q09}="item1"', w: 1 },
          { q: '{r1q10}="item1"', w: 5 },
          { q: '{r1q10}="item2"', w: 4 },
          { q: '{r1q10}="item3"', w: 3 },
          { q: '{r1q10}="item4"', w: 2 },
        ],
      },
      {
        id: 'a6',
        name:
          'Использование специализированных инструментов обеспечения доверия и безопасности',
        description:
          'Использование специализированных инструментов обеспечения доверия и безопасности при работе с большими данными',
        w: 0.16666666666666666,
        q: [
          { q: '{r1q01}="item1"', w: 1 },
          //
          { q: '{r1q11}="item1"', w: 1 },
          // - 7 баллов по основным деловым процессам & 7 баллов по обеспечивающим
          //   процессам & 7 баллов по процессам управления организацией
          // - не менее 6 баллов по основным деловым процессам & не менее 6 баллов
          //   по обеспечивающим или процессам управления
          // - не менее 6 баллов по основным деловым процессам
          // - не менее 1 балла
          {
            q:
              '( Number({r1q12.Row1.Col1})+Number({r1q12.Row1.Col2})+Number({r1q12.Row1.Col3})+Number({r1q12.Row1.Col4})+Number({r1q12.Row1.Col5})+Number({r1q12.Row1.Col6})+Number({r1q12.Row1.Col7}) = 7 ) and ( Number({r1q12.Row2.Col1})+Number({r1q12.Row2.Col2})+Number({r1q12.Row2.Col3})+Number({r1q12.Row2.Col4})+Number({r1q12.Row2.Col5})+Number({r1q12.Row2.Col6})+Number({r1q12.Row2.Col7}) = 7 ) and ( Number({r1q12.Row3.Col1})+Number({r1q12.Row3.Col2})+Number({r1q12.Row3.Col3})+Number({r1q12.Row3.Col4})+Number({r1q12.Row3.Col5})+Number({r1q12.Row3.Col6})+Number({r1q12.Row3.Col7}) = 7 )',
            w: 5,
          },
          {
            q:
              '( Number({r1q12.Row1.Col1})+Number({r1q12.Row1.Col2})+Number({r1q12.Row1.Col3})+Number({r1q12.Row1.Col4})+Number({r1q12.Row1.Col5})+Number({r1q12.Row1.Col6})+Number({r1q12.Row1.Col7}) >= 6 ) and ( Number({r1q12.Row2.Col1})+Number({r1q12.Row2.Col2})+Number({r1q12.Row2.Col3})+Number({r1q12.Row2.Col4})+Number({r1q12.Row2.Col5})+Number({r1q12.Row2.Col6})+Number({r1q12.Row2.Col7}) >= 6 ) || ( Number({r1q12.Row3.Col1})+Number({r1q12.Row3.Col2})+Number({r1q12.Row3.Col3})+Number({r1q12.Row3.Col4})+Number({r1q12.Row3.Col5})+Number({r1q12.Row3.Col6})+Number({r1q12.Row3.Col7}) >= 6 )',
            w: 4,
          },
          {
            q:
              '( Number({r1q12.Row1.Col1})+Number({r1q12.Row1.Col2})+Number({r1q12.Row1.Col3})+Number({r1q12.Row1.Col4})+Number({r1q12.Row1.Col5})+Number({r1q12.Row1.Col6})+Number({r1q12.Row1.Col7}) >= 6 )',
            w: 3,
          },
          {
            q:
              '( Number({r1q12.Row1.Col1})+Number({r1q12.Row1.Col2})+Number({r1q12.Row1.Col3})+Number({r1q12.Row1.Col4})+Number({r1q12.Row1.Col5})+Number({r1q12.Row1.Col6})+Number({r1q12.Row1.Col7})+Number({r1q12.Row2.Col1})+Number({r1q12.Row2.Col2})+Number({r1q12.Row2.Col3})+Number({r1q12.Row2.Col4})+Number({r1q12.Row2.Col5})+Number({r1q12.Row2.Col6})+Number({r1q12.Row2.Col7})+Number({r1q12.Row3.Col1})+Number({r1q12.Row3.Col2})+Number({r1q12.Row3.Col3})+Number({r1q12.Row3.Col4})+Number({r1q12.Row3.Col5})+Number({r1q12.Row3.Col6})+Number({r1q12.Row3.Col7}) >= 1 )',
            w: 2,
          },
        ],
      },
    ],
  },
  {
    surveyId: '6',
    name: 'Организация работы',
    weight: 0.142857143,
    disabled: false,
    maturityLevels: [
      {
        level: 1,
        name: 'Начальный',
        html1:
          '<div> \
                  <p>По направлению "Организация работы" Ваша организация находится на <strong>начальном</strong> уровне зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div> \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>Отсутствие регламентирования процессов работы с большими данными;</li> \
                    <li>Отсутствие системы мониторинга работы с большими данными;</li> \
                    <li>Отсутствие руководителя и подразделения, отвечающего за работу с большими данными.</li> \
                  </ul> \
                </div>',
      },
      {
        level: 2,
        name: 'Формирующийся',
        html1:
          '<div> \
                  <p>По направлению "Организация работы" Ваша организация достигла <strong>формирующегося</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div>, \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>Регламентированы отдельные процессы работы с большими данными;</li> \
                    <li>Отсутствие системы мониторинга работы с большими данными;</li> \
                    <li>Отсутствие руководителя и подразделения, отвечающего за работу с большими данными.</li> \
                  </ul> \
                </div>',
      },
      {
        level: 3,
        name: 'Средний',
        html1:
          '<div> \
                  <p>По направлению "Организация работы" Ваша организация достигла <strong>среднего</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div> \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>Регламентированы основные процессы работы с большими данными;</li> \
                    <li>В организации нет действующей системы мониторинга, но есть ключевые показатели деятельности по работе с большими данными для основных деловых процессов.</li> \
                    <li>В организации есть руководитель, отвечающий за работу с большими данными, но нет соответствующего подразделения.</li> \
                    <li>В организации ведется проектное управление при работе с большими данными, но состав проектной команды не сбалансирован (не междисциплинарен).</li> \
                  </ul> \
                 </div>',
      },
      {
        level: 4,
        name: 'Продвинутый',
        html1:
          '<div> \
                  <p>По направлению "Организация работы" Ваша организация достигла <strong>продвинутого</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div> \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>Регламентировано большинство процессов работы с большими данными;</li> \
                    <li>В организации есть ключевые показатели деятельности по работе с большими данными для большинства деловых процессов. В организации есть действующая система мониторинга работы с большими данными.</li> \
                    <li>В организации есть руководитель, отвечающий за работу с большими данными и соответствующее подразделение.</li> \
                    <li>В организации ведется проектное управление при работе с большими данными, а состав проектной команды сбалансирован (междисциплинарен).</li> \
                    </ul> \
                </div>',
      },
      {
        level: 5,
        name: 'Высокий',
        html1:
          '<div> \
                  <p>По направлению "Организация работы" Ваша организация достигла <strong>высокого</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div> \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>Регламентировано все процессы работы с большими данными;</li> \
                    <li>В организации есть ключевые показатели деятельности по работе с большими данными для большинства деловых процессов. В организации есть действующая система мониторинга работы с большими данными.</li> \
                    <li>В организации есть руководитель, отвечающий за работу с большими данными и соответствующее подразделение.</li> \
                    <li>В организации есть действующий центр компетенций по работе с большими данными.</li> \
                    <li>В организации ведется проектное управление при работе с большими данными, состав проектной команды сбалансирован (междисциплинарен), наряду с традиционными методами проектного управления применяются и гибкие методы.</li> \
                    </ul> \
                </div>',
      },
    ],
    aspectWeights: [
      {
        id: 'a1',
        name: 'Регламентирование процессов',
        description: 'Регламентирование процессов работы с большими данными',
        w: 0.16666666666666666,
        q: [
          { q: '{r1q01}="item1"', w: 1 },
          //
          { q: '{r1q02}="item1"', w: 5 },
          { q: '{r1q02}="item2"', w: 4 },
          { q: '{r1q02}="item3"', w: 3 },
          { q: '{r1q02}="item4"', w: 2 },
        ],
      },
      {
        id: 'a2',
        name: 'Система мониторинга',
        description: 'Система мониторинга работы с большими данными',
        w: 0.16666666666666666,
        q: [
          // Вопрос 3 (Да)& Вопрос 4 (Для всех) =5
          // Вопрос 3 (Да)& Вопрос 4 (Для большинства) =4
          // Вопрос 3 (не важно)& Вопрос 4 (Для основных) =3
          // Любые другие варианты = 2
          { q: '{r1q03}="item2" and {r1q04}="item1"', w: 5 },
          //{ q: '{r1q03}="item2" and {r1q04}=item2', w: 5, },
          { q: '{r1q03}="item2" and {r1q04}="item2"', w: 4 },
          { q: '{r1q04}="item3"', w: 3 },
          { q: '1', w: 2 },
        ],
      },
      {
        id: 'a3',
        name: 'Наличие руководителя',
        description:
          'Наличие руководителя, отвечающего за работу с большими данными',
        w: 0.16666666666666666,
        q: [
          { q: '{r1q05}="item1"', w: 1 },
          { q: '{r1q05}="item2"', w: 5 },
        ],
      },
      {
        id: 'a4',
        name: 'Наличие структурного подразделения',
        description:
          'Наличие структурного подразделения, отвечающего за работу с большими данными',
        w: 0.16666666666666666,
        q: [
          { q: '{r1q06}="item1"', w: 1 },
          { q: '{r1q06}="item2"', w: 5 },
        ],
      },
      {
        id: 'a5',
        name: 'Наличие центра компетенций',
        description: 'Наличие центра компетенций по работе с большими данными',
        w: 0.16666666666666666,
        q: [
          { q: '{r1q07}="item1"', w: 1 },
          { q: '{r1q07}="item2"', w: 5 },
        ],
      },
      {
        id: 'a6',
        name: 'Управление проектами',
        description: 'Управление проектами по работе с большими данными',
        w: 0.16666666666666666,
        q: [
          { q: '{r1q08}="item1"', w: 1 },

          { q: '{r1q09}="item1"', w: 3 },
          { q: '{r1q10}="item1"', w: 4 },
          { q: '{r1q10}="item2"', w: 5 },
        ],
      },
    ],
  },
  {
    surveyId: '7',
    name: 'Воздействие',
    weight: 0.142857143,
    disabled: false,
    maturityLevels: [
      {
        level: 1,
        name: 'Начальный',
        html1:
          '<div> \
                  <p>По направлению "Воздействие" Ваша организация находится на <strong>начальном</strong> уровне зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div> \
                  <p>Для данного уровня характерно отсутствие экономических или неэкономических эффектов.</p> \
                </div>',
      },
      {
        level: 2,
        name: 'Формирующийся',
        html1:
          '<div> \
                  <p>По направлению "Воздействие" Ваша организация достигла <strong>формирующегося</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div>, \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>Отсутствие воздействие работы с большими данными на деятельность организации в целом;</li> \
                    <li>Незначительные экономические и экономические эффекты.</li> \
                  </ul> \
                </div>',
      },
      {
        level: 3,
        name: 'Средний',
        html1:
          '<div> \
                  <p>По направлению "Воздействие" Ваша организация достигла <strong>среднего</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div> \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>Незначительное воздействие работы с большими данными на деятельность организации в целом;</li> \
                    <li>Заметные экономические и экономические эффекты по основным деловым процессам.</li> \
                  </ul> \
                 </div>',
      },
      {
        level: 4,
        name: 'Продвинутый',
        html1:
          '<div> \
                  <p>По направлению "Воздействие" Ваша организация достигла <strong>продвинутого</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div> \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>Заметное воздействие работы с большими данными на деятельность организации в целом;</li> \
                    <li>Заметные экономические и экономические эффекты для основных деловых процессов, а так же обеспечивающих или процессах управления организацией.</li> \
                    </ul> \
                </div>',
      },
      {
        level: 5,
        name: 'Высокий',
        html1:
          '<div> \
                  <p>По направлению "Воздействие" Ваша организация достигла <strong>высокого</strong> уровня зрелости в работе с большими данными.</p> \
                </div>',
        html2:
          '<div> \
                  <p>Для данного уровня характерны следующие характеристики:</p> \
                  <ul> \
                    <li>Существенное воздействие работы с большими данными на деятельность организации в целом;</li> \
                    <li>Заметные экономические и экономические эффекты для основных деловых процессов, обеспечивающих и процессах управления организацией.</li> \
                  </ul> \
                </div>',
      },
    ],
    aspectWeights: [
      {
        id: 'a1',
        name: 'Воздействие на деятельность организации в целом',
        description:
          'Воздействие работы с большими данными на деятельность организации в целом',
        w: 0.16666666666666666,
        q: [
          //{ q: '{r1q01}="item1"', w: 1, },
          //
          // { {r1q01}: ["item1", "item2", "item3", "item4"] }
          //
          // - не менее 3 баллов = 5
          // - 2 балла = 4
          // - 1 балл = 3
          // - 0 баллов = 2
          //{ q: 'Length({r1q01}) >= 3', w: 5, },
          //{ q: 'Length({r1q01}) = 2', w: 4, },
          //{ q: 'Length({r1q01}) = 1', w: 3, },
          //{ q: 'Length({r1q01}) = 0', w: 2, },

          {
            q:
              '( Number({r1q01.Row1.Col1})+Number({r1q01.Row1.Col2})+Number({r1q01.Row1.Col3})+Number({r1q01.Row1.Col4}) >= 3)',
            w: 5,
          },
          {
            q:
              '( Number({r1q01.Row1.Col1})+Number({r1q01.Row1.Col2})+Number({r1q01.Row1.Col3})+Number({r1q01.Row1.Col4}) = 2)',
            w: 4,
          },
          {
            q:
              '( Number({r1q01.Row1.Col1})+Number({r1q01.Row1.Col2})+Number({r1q01.Row1.Col3})+Number({r1q01.Row1.Col4}) = 1)',
            w: 3,
          },
          {
            q:
              '( Number({r1q01.Row1.Col1})+Number({r1q01.Row1.Col2})+Number({r1q01.Row1.Col3})+Number({r1q01.Row1.Col4}) = 0)',
            w: 2,
          },
        ],
      },
      {
        id: 'a2',
        name: 'Экономические эффекты',
        description: 'Экономические эффекты от использования больших данных',
        w: 0.16666666666666666,
        q: [
          // - Не менее 2 баллов по основным деловым процессам & 2 баллов по обеспечивающим процессам & 2 баллов по процессам управления организацией - 5
          // - Не менее 2 баллов по основным деловым процессам & не менее 2 баллов по обеспечивающим или процессам управления организацией - 4
          // - Не менее 2 баллов по основным деловым процессам - 3
          // - 1 балл по любым процессам - 2
          // - 0 баллов - 1
          //{ q: '( Number({r1q02.Row1.Col1})+Number({r1q02.Row1.Col2}) >= 2 ) and ( Number({r1q02.Row2.Col1})+Number({r1q02.Row2.Col2}) >= 2) and (Number({r1q02.Row3.Col1})+Number({r1q02.Row3.Col2}) >= 5 )', w: 5, },
          {
            q:
              '( Number({r1q02.Row1.Col1})+Number({r1q02.Row1.Col2}) >= 2 ) and ( Number({r1q02.Row2.Col1})+Number({r1q02.Row2.Col2}) >= 2) and (Number({r1q02.Row3.Col1})+Number({r1q02.Row3.Col2}) >= 2)',
            w: 5,
          },
          //{ q: '( Number({r1q02.Row1.Col1})+Number({r1q02.Row1.Col2}) >= 2 ) and ( ( Number({r1q02.Row2.Col1})+Number({r1q02.Row2.Col2}) >= 2) || (Number({r1q02.Row3.Col1})+Number({r1q02.Row3.Col2}) >= 5) )', w: 4, },
          {
            q:
              '( Number({r1q02.Row1.Col1})+Number({r1q02.Row1.Col2}) >= 2 ) and ( ( Number({r1q02.Row2.Col1})+Number({r1q02.Row2.Col2}) >= 2) || (Number({r1q02.Row3.Col1})+Number({r1q02.Row3.Col2}) >= 2) )',
            w: 4,
          },
          {
            q: 'Number({r1q02.Row1.Col1})+Number({r1q02.Row1.Col2}) >= 2',
            w: 3,
          },
          {
            q:
              '( Number({r1q02.Row1.Col1}) + Number({r1q02.Row1.Col2}) + Number({r1q02.Row2.Col1}) + Number({r1q02.Row2.Col2}) + Number({r1q02.Row3.Col1}) + Number({r1q02.Row3.Col2}) = 1 )',
            w: 2,
          },
          { q: '1', w: 1 }, // default, the last one
        ],
      },
      {
        id: 'a3',
        name: 'Неэкономические эффекты',
        description: 'Неэкономические эффекты от использования больших данных',
        w: 0.16666666666666666,
        q: [
          // - Не менее 3 баллов по основным деловым процессам & 3 баллов по обеспечивающим процессам & 3 баллов по процессам управления организацией - 5
          // - Не менее 3 баллов по основным деловым процессам & не менее 3 баллов по обеспечивающим или процессам управления организацией - 4
          // - Не менее 3 баллов по основным деловым процессам - 3
          // - 1 балл по любым процессам - 2
          // - 0 баллов - 1
          //{ q: '( Number({r1q03.Row1.Col1})+Number({r1q03.Row1.Col2})+Number({r1q03.Row1.Col3})+Number({r1q03.Row1.Col4}) >= 2 ) and ( Number({r1q03.Row2.Col1})+Number({r1q03.Row2.Col2})+Number({r1q03.Row2.Col3})+Number({r1q03.Row2.Col4}) >= 2) and (Number({r1q03.Row3.Col1})+Number({r1q03.Row3.Col2})+Number({r1q03.Row3.Col3})+Number({r1q03.Row3.Col4}) >= 5)', w: 5, },
          {
            q:
              '( Number({r1q03.Row1.Col1})+Number({r1q03.Row1.Col2})+Number({r1q03.Row1.Col3})+Number({r1q03.Row1.Col4}) >= 3 ) and ( Number({r1q03.Row2.Col1})+Number({r1q03.Row2.Col2})+Number({r1q03.Row2.Col3})+Number({r1q03.Row2.Col4}) >= 3) and (Number({r1q03.Row3.Col1})+Number({r1q03.Row3.Col2})+Number({r1q03.Row3.Col3})+Number({r1q03.Row3.Col4}) >= 3)',
            w: 5,
          },
          //{ q: '( Number({r1q03.Row1.Col1})+Number({r1q03.Row1.Col2})+Number({r1q03.Row1.Col3})+Number({r1q03.Row1.Col4}) >= 2 ) and ( (Number({r1q03.Row2.Col1})+Number({r1q03.Row2.Col2})+Number({r1q03.Row2.Col3})+Number({r1q03.Row2.Col4}) >= 2) || (Number({r1q03.Row3.Col1})+Number({r1q03.Row3.Col2})+Number({r1q03.Row3.Col3})+Number({r1q03.Row3.Col4}) >= 5) )', w: 4, },
          {
            q:
              '( Number({r1q03.Row1.Col1})+Number({r1q03.Row1.Col2})+Number({r1q03.Row1.Col3})+Number({r1q03.Row1.Col4}) >= 3 ) and ( (Number({r1q03.Row2.Col1})+Number({r1q03.Row2.Col2})+Number({r1q03.Row2.Col3})+Number({r1q03.Row2.Col4}) >= 3) || (Number({r1q03.Row3.Col1})+Number({r1q03.Row3.Col2})+Number({r1q03.Row3.Col3})+Number({r1q03.Row3.Col4}) >= 3) )',
            w: 4,
          },
          {
            q:
              'Number({r1q03.Row1.Col1})+Number({r1q03.Row1.Col2})+Number({r1q03.Row1.Col3})+Number({r1q03.Row1.Col4}) >= 3',
            w: 3,
          },
          {
            q:
              'Number({r1q03.Row1.Col1})+Number({r1q03.Row1.Col2})+Number({r1q03.Row1.Col3})+Number({r1q03.Row1.Col4})+Number({r1q03.Row2.Col1})+Number({r1q03.Row2.Col2})+Number({r1q03.Row2.Col3})+Number({r1q03.Row2.Col4})+Number({r1q03.Row3.Col1})+Number({r1q03.Row3.Col2})+Number({r1q03.Row3.Col3})+Number({r1q03.Row3.Col4}) >= 1',
            w: 2,
          },
          { q: '1', w: 1 },
        ],
      },
    ],
  },
  {
    surveyId: 'org',
    name: 'Информация о компании',
    weight: 0,
    disabled: false,
    maturityLevels: [],
    aspectWeights: [],
  },
];

export default surveyDefinitions;
