import {ChartData} from "../../lib/calc";

export const animationDuration = 100;
export const gridColor = '#d5d5d5';
export const strokeDasharray = '5 5';
//export const fillColor = '#413ea0';
//export const fillColor = '#3c4f6d';
export const fillColor = '#60B34A';//'#5B9BD5';
export const fillColorAvg = '#4a79b3';//'#5B9BD5';
export const fillColor2 = '#dfeedb'; // '#4a76b3';//'#d5695b';// '#ED7D31';
export const fillOpacity = 0.9;
export const radarFillOpacity = 0.5;
export const radarStrokeWidth = 3;

export const fontColor = '#212529'; // rgb(33, 37, 41)

export const axisTicks = {
  fontSize: 14, fill: fontColor, width: 350
}

export interface AspectsBarChartProps {
  data: ChartData
}


export const radarChartWidth = 650;
export const radarChartHeight = 250;
export const radarChartDiameter = 150;

export const totalReportWidth = 1200;
export const reportMargin = 50; // the only way to set margin for PDF

export const sv_body_padding = 50;
