/** @format */

import React from 'react';
import {
  Link as OriginalLink,
  LinkProps as OriginalLinkProps,
} from 'react-router-dom';

export type LinkProps = OriginalLinkProps & {
  // using `interface` is also ok
  visible?: boolean;
  disabled?: boolean;
};

export const Link = ({ children, visible=true, disabled, ...rest }: LinkProps) => {
    return visible ? (
      disabled ? (
        <span>{children}</span>
      ) : (
        <OriginalLink {...rest } children={children}/>
      )
    ) : null;
}

export const MiniLinkStyle = {
  fontSize: '0.5em',
  verticalAlign: 'middle',
}


export const MiniLink = (props: LinkProps) => {
  return (
    <Link
      {...props}
      style={{
        ...(props.style || {}),
        ...MiniLinkStyle,
      }}
    />
  );
};

