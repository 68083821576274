/** @format */

import React from 'react';

import { Surveys } from '../../../../lib/surveys';
import {Loading} from "../../../basic/Loading";
import {NotReady} from "../../../basic/NotReady";

import { LinkToSurvey } from './LinkToSurvey';

export const SurveysSections = ({ surveys }: { surveys: Surveys }) => {
  console.log('SurveysSections: surveys:', surveys);
  if (surveys.state !== 'ready') return <NotReady state={surveys.state} />;

  return (
    <React.Fragment>
      <ul>
        {surveys.allSurveyDefinitions._data?.map(
          ({ surveyId, name, disabled }) => {
            const { data, completed } =
              surveys.getSurvey(surveyId, undefined) || {};
            // if (completed) completedCount++;
            console.log('data, completed', data, completed);
            if (surveyId === 'org') return null;
            return (
              <LinkToSurvey
                surveys={surveys}
                hasData={!!data}
                surveyId={surveyId}
                name={name}
                completed={!!completed}
                disabled={disabled}
                key={surveyId}
              />
            );
          },
        )}
      </ul>

      <ul>
        {(() => {
          const { data, completed } = surveys.getSurvey('org', undefined) || {};
          // const data = null;
          // const completed = false;
          return (
            <LinkToSurvey
              surveys={surveys}
              hasData={!!data}
              surveyId={'org'}
              name={'Информация о компании'}
              completed={!!completed}
              disabled={false}
            />
          );
        })()}
      </ul>
    </React.Fragment>
  );
};
