/** @format */

import React from 'react';
import { ChartDataPoint } from '../../lib/calc';
// import { MAX_LEVEL } from '../../lib/const';

//
// Set custom label text and manage text wrap:
// -------------------------------------------
// Word-wrapping <Text> component -  https://github.com/recharts/recharts/issues/198
// Custom label on Recharts radar chart - https://stackoverflow.com/questions/59361614/custom-label-on-recharts-radar-chart
//

interface CustomTooltipPropsPayloadPayload {
  id: string;
  name: string;
  value: number;
  value2?: number;
}

interface CustomTooltipPropsPayload {
  dataKey?: string;
  fill?: string;
  fillOpacity?: number;
  formatter?: undefined;
  name?: string;
  payload?: CustomTooltipPropsPayloadPayload;
  stroke?: string;
  strokeWidth?: number;
  type?: undefined;
  unit?: undefined;
  value?: number;
  color?: string;
}

interface CustomTooltipCoordinate {
  allowDuplicatedCategory: boolean;
  angle: number;
  angleAxisId: number;
  axisLine: boolean;
  axisType: string;
  categoricalDomain: undefined;
  cx: number;
  cy: number;
  dataKey: string;
  domain: string[];
  duplicateDomain: undefined;
  endAngle: number;
  hide: boolean;
  innerRadius: number;
  isCategorical: boolean;
  layout: string;
  orientation: string;
  originalDomain: string[] | number[];
  outerRadius: 75;
  radius: number;
  range: number[];
  realScaleType: string;
  scale: any;
  startAngle: number;
  tick: any;
  tickLine: true;
  tickSize: number;
  type: string;
  x: number;
  y: number;
}

interface CustomTooltipViewBox {
  bottom: number;
  brushBottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
  x: number;
  y: number;
}

interface CustomTooltipProps {
  active?: boolean;
  allowEscapeViewBox?: { x: boolean; y: boolean };
  animationDuration?: boolean;
  animationEasing?: string;
  content?: any;
  contentStyle?: {};
  coordinate?: CustomTooltipCoordinate;
  cursor?: true;
  cursorStyle?: {};
  filterNull?: true;
  isAnimationActive?: true;
  itemStyle?: {};

  label?: string;

  labelStyle?: {};
  offset?: number;
  payload?: CustomTooltipPropsPayload[];
  separator?: string;
  trigger?: string;
  useTranslate3d?: boolean;
  viewBox?: CustomTooltipViewBox;
  wrapperStyle?: {};
  color?: string;
  tooltipKey?: string;
}

export const CustomTooltip = (props: CustomTooltipProps) => {
  const { active, payload, label, tooltipKey } = props;
  // console.log('CustomTooltip', props);

  if (!active || !payload) return null;

  let lastName = '';
  let lastDescription = '';

  return (
    <div className="custom-tooltip">
      {payload.map((p, i) => {
        // show tooltip only for one axis if tooltipKey is set
        if (tooltipKey && tooltipKey === p.dataKey) {
          return null;
        }
        // console.log('CustomTooltip', i, p);

        const dataPoint: ChartDataPoint = p?.payload ?? {
          id: '',
          name: '',
          value: 0,
        };
        const res = (
          <div key={ (dataPoint.id + p.dataKey) || i}>
            {/* <p className="">{JSON.stringify(payload)}</p> */}

            {/* Показывать название поля только если оно отличается от уже выведенного выше */}
            {lastName !== dataPoint.name ? (
              <p className="name">{dataPoint.name}</p>
            ) : null}

            {/* Показывать описание поля только если оно задано и отличается от уже выведенного ранее */}
            {dataPoint.description &&
            dataPoint.description !== lastDescription ? (
              <p className="description">{dataPoint.description}</p>
            ) : null}

            {/* Показывать значения только для тех величин, поле `name` которых задано */}
            {p.name ? (
              <p className="value" style={{ color: p.color || '#039205' }}>
                {/*{p?.name || 'Значение'}: {dataPoint.value}*/}
                {p?.name || 'Значение'}: {p.value}
              </p>
            ) : null}

            {/*<p>{label}</p>*/}
          </div>
        );
        lastName = dataPoint.name;
        lastDescription = dataPoint.description;

        return res;
      })}
    </div>
  );
};
