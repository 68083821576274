/** @format */

import {AllSurveysDefinitions} from "@shared/types/SurveyDefinitions";
import { AbstractDataCache } from '../DataCache';
import { BackendHttp } from '../../services/BackendHttp';

import {SurveyQuestions, AllSurveyQuestions} from '@shared/types/SurveyQuestions';
// import { SurveyDefinitions } from '@shared/types/SurveyDefinitions';

// import surveyJSON1 from '@shared/data/fixtures/surveyQuestions/survey1';
// import surveyJSON2 from '@shared/data/fixtures/surveyQuestions/survey2';
// import surveyJSON3 from '@shared/data/fixtures/surveyQuestions/survey3';
// import surveyJSON4 from '@shared/data/fixtures/surveyQuestions/survey4';
// import surveyJSON5 from '@shared/data/fixtures/surveyQuestions/survey5';
// import surveyJSON6 from '@shared/data/fixtures/surveyQuestions/survey6';
// import surveyJSON7 from '@shared/data/fixtures/surveyQuestions/survey7';
// import surveyOrgJSON from '@shared/data/fixtures/surveyQuestions/surveyOrg';

// import surveysJSON from '@shared/data/fixtures/surveyQuestions/';

// export const surveysJSON: AllSurveyQuestionsDefinition = {
//   '1': surveyJSON1,
//   '2': surveyJSON2,
//   '3': surveyJSON3,
//   '4': surveyJSON4,
//   '5': surveyJSON5,
//   '6': surveyJSON6,
//   '7': surveyJSON7,
//   org: surveyOrgJSON,
// };

// function getQuestionsDefinition(
//   surveyId: string,
// ): SurveyQuestionsDefinition | undefined {
//   // const surveyIndex = parseInt(surveyId) - 1;
//   // return surveysJSON[surveyIndex];
//   return surveysJSON[surveyId];
// }

export class SurveyQuestionsDefinitions extends AbstractDataCache<AllSurveyQuestions> {
  _data: AllSurveyQuestions | undefined;
  protected backendHttp: BackendHttp;

  constructor() {
    super();
    this.backendHttp = new BackendHttp();
  }

  // async _loadLocal(): Promise<AllSurveyQuestions | undefined> {
  //   this._set(surveysJSON);
  //   return this._get();
  // }

  async _loadRemote(): Promise<AllSurveyQuestions | undefined> {
    const data = await this.backendHttp.getQuestions();
    this._set(data);
    return this._get();
  }

  async _saveLocal(): Promise<AllSurveyQuestions | undefined> {
    // this._set(allSurveyDefinitions);
    return this._get();
  }

  async _saveRemote(): Promise<AllSurveyQuestions | undefined> {
    await this.backendHttp.postQuestions(this._data);
    return this._get();
  }

  async load(): Promise<AllSurveyQuestions | undefined> {
    // await this._loadLocal();
    await this._loadRemote();
    return this._get();
  }

  async save(): Promise<AllSurveyQuestions | undefined> {
    await this._saveLocal();
    await this._saveRemote();
    return this._get();      
  }

  public findSurvey(surveyId: string): SurveyQuestions | undefined {
    return this._data?.[surveyId];
  }
}
