/** @format */

import React from 'react';

export function ErrorListTemplate(props) {
  const { errors } = props;
  return (
    <div className="ui segment">
      <div className="ui red header">
        Пожалуйста, скорректируйте данные ниже:
      </div>
      {/*        <div className="ui red header">
          {errors.map(error => (
            <li className="ui item" key={error.stack}>
              {error.stack}
              {error.message}
            </li>
          ))}
        </div>*/}
    </div>
  );
}

export default ErrorListTemplate;
