/** @format */

import React from 'react';
import { Link as OriginalLink, LinkProps } from 'react-router-dom';

import { useForceUpdate, getIsAdmin, removeCurrentUser } from '../../lib/utils';
import eventBus from '../../lib/EventBus';

type LoginProps = /* LinkProps & */ {
  // using `interface` is also ok
  // disabled?: boolean;
};

const {
  REACT_APP_WORDPRESS_CLIENT_ID,
  REACT_APP_WORDPRESS_SERVER_URL,
  REACT_APP_BACKEND_API_SERVER_URL,
  REACT_APP_WORDPRESS_TO_BACKEND_REDIRECT_PATH,
} = process.env;

export function Login(params: LoginProps) {
  const forceUpdate = useForceUpdate();
  //
  // https://wordpress.org/support/topic/domain-com-oauth-authorize-throws-a-404/
  //
  const loginFormAction =
    REACT_APP_WORDPRESS_SERVER_URL +
    `?oauth=authorize` +
    `&response_type=code` +
    `&client_id=${REACT_APP_WORDPRESS_CLIENT_ID}` +
    `&redirect_uri=${
      REACT_APP_BACKEND_API_SERVER_URL +
      REACT_APP_WORDPRESS_TO_BACKEND_REDIRECT_PATH
    }`;

  //
  // https://wp-oauth.com/docs/general/endpoints/destroy/
  //
  const logoutFormAction = '';

  console.log('Login: getIsAdmin()', getIsAdmin());

  return (
    <React.Fragment>
      {getIsAdmin() ? (
        <input
          type="button"
          value="Logout"
          className="ui button tertiary"
          onClick={(e) => {
            removeCurrentUser();
            eventBus.dispatch('logout');
            forceUpdate();
          }}
        />
      ) : (
        <form action={loginFormAction} method="post">
          <input
            type="submit"
            value="Login"
            className="ui button tertiary"
            onClick={(e) => {
              eventBus.dispatch('login');
            }}
          />
        </form>
      )}
    </React.Fragment>
  );
}
