/** @format */

import {
  SURVEY_ASPECT_LIST_PATH,
  SURVEY_EDIT_QUESTIONS_LIST_PATH,
  SURVEY_EDIT_QUESTIONS_PATH,
  SURVEY_LEVEL_LIST_PATH,
} from '@shared/config';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import JSONSchemaForm, {
  UiSchema,
  WidgetProps,
  FieldProps,
  FieldTemplateProps,
} from '@rjsf/semantic-ui';

import { JSONSchema6, JSONSchema7 } from 'json-schema';

import 'semantic-ui-css/semantic.min.css';

import { Surveys } from '../../../lib/surveys';
import { routeSubstitute } from '../../../lib/utils';
import { Link } from '../../basic/Link';
import { Loading } from '../../basic/Loading';
import { Notfound } from '../../basic/Notfound';
import {NotReady} from "../../basic/NotReady";

import { customWidgets, customFields } from '../../rjsf';

import Surveys_uiSchema from '@shared/schemas-ui/Surveys.ui-schema';

import SurveysSchema_ from '@shared/schemas/Surveys.schema';
import AllSurveysSchema_ from '@shared/schemas/AllSurveys.schema';
const SurveysSchema = SurveysSchema_ as JSONSchema7;
const AllSurveysSchema = AllSurveysSchema_ as JSONSchema7;

interface AdminSurveyPageProps {
  surveyId: string;
  surveys: Surveys;
  // name: string
  // surveyState: SurveyState
  // onBack: ClickEvent
  // onNext: ClickEvent
}

export function AdminMenuSurveyPage(props: AdminSurveyPageProps) {
  const { surveyId, surveys } = props;
  if (surveys.state !== 'ready') return <NotReady state={surveys.state} />;

  const surveyQuestions = surveys.getQuestions(surveyId);
  const surveyDefinitions = surveys.getDefinition(surveyId);
  console.log('AdminMenuSurveyPage: surveys', surveys)
  console.log('AdminMenuSurveyPage: surveyQuestions', surveyQuestions)
  console.log('AdminMenuSurveyPage: surveyDefinitions', surveyDefinitions)
  
  if (!surveyQuestions || !surveyDefinitions)
    return <Notfound message="Раздел опроса не найден" />;

  return (
    <nav className="ui main container">
      <header className="ui container">
        <h2 className="ui center aligned header">
          Редактирование размерности "{surveyDefinitions?.name}"
        </h2>
        <h3>
          Выберите раздел данных размерности "{surveyDefinitions?.name}" для его
          редактирования:
        </h3>
      </header>
      <main>
        <ul>
          <li>
            <Link
              to={routeSubstitute(SURVEY_EDIT_QUESTIONS_LIST_PATH, {
                surveyId,
              })}
            >
              Вопросы размерности
            </Link>
          </li>
          <li>
            <Link
              to={routeSubstitute(SURVEY_ASPECT_LIST_PATH, {
                surveyId,
              })}
            >
              Названия, веса, правила вычисления аспектов размерности
            </Link>
          </li>
          <li>
            <Link
              to={routeSubstitute(SURVEY_LEVEL_LIST_PATH, {
                surveyId,
              })}
            >
              Описания и веса уровней зрелости размерности
            </Link>
          </li>
        </ul>
      </main>
    </nav>
  );

/*
  const formData = surveys.getQuestions(surveyId);
  const schema = SurveysSchema as JSONSchema7;

  const uiSchema = Surveys_uiSchema; 
  // uiSchema['ui:options'] = {};

  return (
    <div className="ui container">
      {formData ? (
        <JSONSchemaForm
          formData={formData}
          schema={schema}
          uiSchema={uiSchema}
          formContext={{
            'ui:options': {
              semantic: {
                wrapLabel: true,
                wrapContent: true,
              },
            },
          }}
          widgets={customWidgets}
          fields={customFields}
          // FieldTemplate={CustomFieldTemplate}
        />
      ) : null}
    </div>
  );
*/
}
