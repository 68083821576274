/** @format */

import {INTERMEDIATE_SUMMARY_REPORT_PATH, SURVEY_PATH} from "@shared/config";
import React, {useEffect, useState} from 'react';

import { Hr } from '../basic/Hr';
import { SurveyCompleteIcon } from '../basic/SurveyCompleteIcon';
import {Link} from "../basic/Link";
import {Loading} from "../basic/Loading";
import {NotReady} from "../basic/NotReady";
import { LinkToSurvey } from './MainPage/SurveySections/LinkToSurvey';
import { Surveys } from '../../lib/surveys';
import {routeSubstitute, useForceUpdate} from '../../lib/utils';
import './ChooseSurveyPage.scss';

const SurveyLine = ({
  hasData,
  surveyId,
  name,
  completed,
  disabled,
  selected,
  onChange,
}: {
  hasData: boolean;
  surveyId: string;
  name: string;
  completed: boolean;
  disabled: boolean;
  selected: boolean;
  onChange: (newValue: boolean) => void;
}) => {
  // const [isChecked, setIsChecked] = useState(true);
  // const id = `checkbox_survey_${surveyId}`;

  const isControlDisabled = disabled || !completed;

  return (
    
    <li
      className={'disable-select ' + (isControlDisabled ? 'disabled' : '')}
      key={surveyId}
      style={{ display: 'block' }}
    >
      <div className="control">
        <label>
          <input
            type="checkbox"
            disabled={disabled || !completed}
            checked={selected && !isControlDisabled}
            onChange={() => {
              onChange(!selected);
            }}
          />
          <span>{name}</span>
        </label>
      </div>
      <SurveyCompleteIcon completed={completed} hasData={hasData} />
    </li>
  );
};

export const ChooseSurveysPage = ({ surveys }: { surveys: Surveys }) => {
  const forceUpdate = useForceUpdate();
  if (surveys.state !== 'ready') return <NotReady state={surveys.state} />;
  
  const { completed, selected } = surveys.getCounts();
  const isFinalReportEnabled = completed > 2 && selected > 2;

  // const [completed, setCompleted] = useState(0);
  // const [selected, setSelected] = useState(0);
  //
  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       const { completed, selected } = await surveyQuestions.getCounts();
  //       setCompleted(completed);
  //       setSelected(selected);
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   }
  //   fetchData();
  // }, []);
  // // console.log('isFinalReportEnabled:', isFinalReportEnabled);
  //
  // const isFinalReportEnabled = completed > 2 && selected > 2;


  return (
    <div className="choose-survey-page">
      <header>
        <h3 style={{ fontWeight: 'normal' }}>
          Выберите размерности для построения отчета:
        </h3>
      </header>
      <main>
        <ul>
          {surveys.allSurveyDefinitions._data
            ?.map(({ surveyId, name, disabled }) => {
            if (surveyId === 'org') return null;
            const { data, completed, selected } =
              surveys.getSurvey(surveyId, undefined) || {};
            // if (completed) completedCount++;
            // console.log('data, completed, selected', data, completed, selected);
            return (
              <SurveyLine
                hasData={!!data}
                surveyId={surveyId}
                name={name}
                completed={!!completed}
                disabled={disabled}
                key={surveyId}
                selected={selected ?? true}
                onChange={(selected) => {
                  surveys.setSelected(surveyId, selected);
                  forceUpdate();
                }}
              />
            );
          })}
        </ul>
      </main>

      <footer>
        <Hr style={{ marginLeft: '40px', width: '300px' }} />
        {isFinalReportEnabled ? (
          <ul>
            {(() => {
              const data = null;  
              const completed = false;
              return (
                // <Link to={routeSubstitute(SURVEY_PATH, { surveyId: 'org' })} >
                //   Сформировать отчёт &gt;&gt;
                // </Link>
                <Link to={INTERMEDIATE_SUMMARY_REPORT_PATH} >
                  Сформировать отчёт &gt;&gt;
                </Link>
              );

            })()}
          </ul>
        ) : (
          <p>
            Для формирования итогового отчета необходимо выбрать, как минимум,
            на три раздела с заполненными ответами.
          </p>
        )}
      </footer>
    </div>
  );
};

/*
              return (
                <LinkToSurvey
                  surveyQuestions={surveyQuestions}
                  hasData={!!data}

                  surveyId={'org'}

                  name={'Сформировать отчёт >>'}
                  completed={!!completed}
                  disabled={false}
                />
              );
*/
