/** @format */

import * as SurveyJs from 'survey-react';
import pegjs from 'pegjs';
// import {remap} from "@utilities/object";

import { BackendHttp } from '../../services/BackendHttp';
import { objectToArray } from '../utils';
import {
  AbstractDataCache,
  DataCacheSource,
  DataCacheStorage,
} from '../DataCache';

import grammarSource from './expression.pegjs';

import {
  SurveyDefinitions /*, AllSurveysDefinitions*/,
} from './SurveyDefinitions';
import {
  SurveyQuestionsDefinitions,
  // AllSurveyQuestionsDefinition,
} from './SurveyQuestionsDefinitions';

import { AllSurveyStates, SurveyState } from '@shared/types/SurveyState';
import {
  FullSurveyData,
  AllSurveyResults,
  SurveyResult,
  SurveyAspectResult,
  SurveyId,
  AvgSurveyResults,
  AvgSurveyResultsQuery,
  // EMPTY_FULL_SURVEY_DATA,
} from '@shared/types/SurveyResult';

import { SurveyQuestions } from '@shared/types/SurveyQuestions';

import {
  AllSurveysElement,
  AspectQuestionWeight,
  AspectWeightDefinition,
  SurveyAspectWeightDefinitions,
} from '@shared/types/SurveyDefinitions';

const EMPTY_FULL_SURVEY_DATA /*: FullSurveyData*/ = {
  user: { userId: '' },
  /*datetime: null, */
  surveys: {},
  results: {},
  surveysTotal: 0,
};

const BASE_STORAGE_NAME = 'big_data_survey_storage';

export interface SurveysCounts {
  completed: number;
  selected: number;
}

export type LoadingState = 'init' | 'loading' | 'error' | 'ready';

//

export type SurveysState = LoadingState;

class LocalSource extends DataCacheSource<FullSurveyData> {
  async load(): Promise<FullSurveyData> {
    const s = window.localStorage.getItem(BASE_STORAGE_NAME) || '{}';
    try {
      const result = JSON.parse(s);
      if (!result.surveys) result.surveys = {};
      if (!result.results) result.results = {};
      this.cache._data = result;
      return result;
    } catch {
      const msg = 'Error parsing saved JSON (or no JSON was saved)';
      console.error(new Error(msg));
      return Promise.resolve(this.cache._setInitialData());
    }
  }

  async save(): Promise<FullSurveyData> {
    window.localStorage.setItem(
      BASE_STORAGE_NAME,
      JSON.stringify(this.cache._data),
    );
    return Promise.resolve(this.cache._data);
  }
}

////////////////////////////////////////////////////////////////////////////////

export class Surveys extends AbstractDataCache<
  FullSurveyData//,
  // 'local' | 'remote'
> {
  public state: SurveysState = 'init';
  public userId: string = '';
  protected _parser: pegjs.Parser;
  protected backendHttp: BackendHttp;

  allSurveyDefinitions: SurveyDefinitions;
  allSurveyQuestionsDefinitions: SurveyQuestionsDefinitions;

  // get data(): FullSurveyData {
  // return this._data;
  // }

  constructor() {
    super();
    this._setInitialData();
    this.backendHttp = new BackendHttp();
    try {
      this._parser = pegjs.generate(grammarSource);
    } catch (error) {
      console.error('ERROR generating parser', error);
      throw error;
    }
    this.allSurveyDefinitions = new SurveyDefinitions();
    this.allSurveyQuestionsDefinitions = new SurveyQuestionsDefinitions();
  }

  public _prepareLoad(): void {
    this.state = 'loading';
  }

  public async load(idFromUrl?: string): Promise<FullSurveyData | undefined> {
    this._prepareLoad();
    try {
      await Promise.all([
        // this._loadLocal(),
        this._loadRemote(idFromUrl),
        this.allSurveyDefinitions.load(),
        this.allSurveyQuestionsDefinitions.load(),
      ]);
      this.state = 'ready';
      return this._data;
    } catch (e) {
      this.state = 'error';
      throw e;
    }
  }

  public _setInitialData(): FullSurveyData | undefined {
    const data = EMPTY_FULL_SURVEY_DATA;
    this._set(data);
    return this._data;
  }

  protected _saveLocal(): FullSurveyData {
    window.localStorage.setItem(BASE_STORAGE_NAME, JSON.stringify(this._data));
    return this._data;
  }

  protected _clear(surveyId: SurveyId): void {
    console.log(`clearSavedData with surveyId: "${surveyId}"`);
    delete this._data?.surveys[surveyId];
    delete this._data?.results[surveyId];
    // window.localStorage.removeItem(_getStorageName(surveyId));
  }

  protected async _loadRemote(idFromUrl?: string): Promise<FullSurveyData | undefined> {
    await this._loadLocal();
    const localId = this._data.id;
    const remoteId = idFromUrl;

    const id = remoteId ? remoteId : localId;

    return this.backendHttp.getSurveys(id)
      .then((result) => {
      return result;
    })
      .catch( err => {
        console.log('_loadRemote: backendHttp.getSurveys: ERROR:',err)
        throw err;
      })
      ;
  }

  protected async _loadLocal(): Promise<FullSurveyData | undefined> {
    const s = window.localStorage.getItem(BASE_STORAGE_NAME) || '{}';
    try {
      const result = JSON.parse(s);
      if (!result.surveys) result.surveys = {};
      if (!result.results) result.results = {};
      this._data = result;
      return result;
    } catch {
      const msg = 'Error parsing saved JSON (or no JSON was saved)';
      console.error(new Error(msg));
      return this._setInitialData();
    }
  }

  protected _setSurvey(
    surveyId: SurveyId,
    surveyModel: SurveyJs.SurveyModel,
    completed: boolean = false,
  ): SurveyState {
    const surveyState = surveyToState(surveyModel, completed);
    if (surveyState) {
      if (this._data) this._data.surveys[surveyId] = surveyState;
    } else {
      if (this._data) delete this._data.surveys[surveyId];
    }

    this._setChanged(true);
    this._saveLocal();
    return surveyState;
  }

  protected _setSurveyProperty(
    surveyId: SurveyId,
    name: 'completed' | 'selected',
    value: boolean,
    defaultValue: boolean,
  ): void {
    const surveyState = this._data?.surveys[surveyId];
    if (surveyState) {
      surveyState[name] = value ?? defaultValue;
      // const newValue = value ?? defaultValue
      // if (typeof newValue !== 'undefined') {
      //   surveyState[name] = newValue;
      // }
      // surveyState[name] = typeof value !== 'undefined' ? value : defaultValue;
    }
  }

  protected _setCompleted(surveyId: SurveyId, completed = false): void {
    return this._setSurveyProperty(surveyId, 'completed', completed, false);
  }

  protected _setSelected(surveyId: SurveyId, selected = false): void {
    return this._setSurveyProperty(surveyId, 'selected', selected, true);
  }

  protected _setResult(
    surveyId: SurveyId,
    surveyResult: SurveyResult,
  ): SurveyResult | undefined {
    if (surveyResult) {
      this._data.results[surveyId] = surveyResult;
    } else {
      if (this._data) delete this._data.results[surveyId];
    }
    this._setChanged(true);
    this._saveLocal();
    return this._data.results[surveyId];
  }

  protected _calculate(surveyId: SurveyId): SurveyResult {
    const EMPTY_RESULT = {
      total: 0,
      level: 0,
      aspects: [],
    };
    console.log('_calculate: surveyId:', surveyId);
    if (surveyId === 'org') {
      console.log(`_calculate: skip calculation for ${surveyId}`);
      return EMPTY_RESULT;
    }

    const surveyData = this._data?.surveys[surveyId];
    console.log('_calculate: surveyData:', surveyData);

    const surveyDef = this.allSurveyDefinitions.findSurvey(surveyId);
    console.log('_calculate: surveyDef:', surveyDef);

    if (!surveyDef) {
      // error is printed inside getDefinition()
      const msg = `Survey Definition not found for surveyId ${surveyId}`;
      console.error(new Error(msg));
      return EMPTY_RESULT;
    }

    // const surveyIndex = parseInt(surveyId) - 1;
    // const surveyDef = allSurveys[surveyIndex];

    const weightDefinitions = surveyDef.aspectWeights;
    if (!weightDefinitions) {
      // error is printed inside getDefinition()
      const msg = `aspectWeights not set for surveyId ${surveyId}`;
      console.error(new Error(msg));
      return EMPTY_RESULT;
    }

    return map1(weightDefinitions, surveyData, this._parser);
    // this._fullSurvey.results[surveyId] = map1(weightDefinitions, surveyData, this._parser)
    // return this._fullSurvey.results[surveyId];
  }

  calculate(surveyId: SurveyId): void {
    const result = this._calculate(surveyId);
    this._setResult(surveyId, result);
    this._calculateTotal();
  }

  _calculateTotal(): number {
    let surveysTotal = 0;
    const surveyIds = Object.keys(this._data?.results ?? {});

    for (const surveyId of surveyIds) {
      if (surveyId === 'org') continue;

      const surveyDef = this.allSurveyDefinitions.findSurvey(surveyId);
      // (s) => s.surveyId === surveyId,
      // );
      if (!surveyDef) {
        console.warn(`SurveyDef for surveyId "${surveyId}" not found`);
        console.log('this._data', this._data);
        continue;
      }
      const surveyWeight = surveyDef.weight;
      const surveyResult = this._data?.results[surveyId];
      surveysTotal += (surveyResult?.level ?? 0) * surveyWeight;
    }
    return surveysTotal;
  }

  getCounts(): SurveysCounts {
    const result = { completed: 0, selected: 0 };
    // await this._loadLocal();
    const surveyIds = Object.keys(this._data?.results ?? {});
    // console.log('getCounts this._fullSurvey.results', this._fullSurvey.results);
    // console.log('getCounts surveyIds', surveyIds);

    const DEFAULT_COMPLETED = false;
    const DEFAULT_SELECTED = true;
    for (const surveyId of surveyIds) {
      const surveyState = this.getSurvey(surveyId, undefined) ?? {
        completed: DEFAULT_COMPLETED,
        selected: DEFAULT_SELECTED,
      };
      // console.log('getCounts surveyState', surveyState);
      if (surveyId === 'org') continue;

      if (typeof surveyState.completed === 'undefined')
        surveyState.completed = DEFAULT_COMPLETED;
      if (typeof surveyState.selected === 'undefined')
        surveyState.selected = DEFAULT_SELECTED;

      if (surveyState.completed) result.completed++;
      if (surveyState.selected) result.selected++;
    }
    // console.log('getCounts result:', result);
    return result;
  }

  //

  clear(surveyId: SurveyId) {
    this._clear(surveyId);
    this._setChanged(true);
    return this._saveLocal();
  }

  public setEmptySurvey(surveyId: SurveyId): SurveyState {
    if (this._data)
      this._data.surveys[surveyId] = {
        data: {},
        pageNo: 0,
        completed: false,
        selected: false,
      };
    this._setChanged(true);
    this._saveLocal();
    return this._data.surveys[surveyId];
  }

  public sanitizeSurvey(surveyId): SurveyState {
    if (!this.getSurvey(surveyId)?.data) {
      this.setEmptySurvey(surveyId);
    }
    return this._data.surveys[surveyId];
  }

  setSurvey(
    surveyId: SurveyId,
    surveyModel: SurveyJs.SurveyModel,
    completed: boolean = false,
  ): SurveyState {
    this._setSurvey(surveyId, surveyModel, completed);
    const surveyResult = this._calculate(surveyId);
    this._setResult(surveyId, surveyResult);
    this._setChanged(true);
    this._saveLocal();
    return this._data.surveys[surveyId];
  }

  setCompleted(surveyId: SurveyId, completed = false): SurveyState {
    this._setSurveyProperty(surveyId, 'completed', completed, false);
    this._setChanged(true);
    this._saveLocal();
    return this._data.surveys[surveyId];
  }

  setSelected(surveyId: SurveyId, selected = true) {
    this._setSurveyProperty(surveyId, 'selected', selected, true);
    this._setChanged(true); // do we need to mark as changed here..?
    return this._saveLocal();
  }

  getSurveyName(surveyId: SurveyId): string {
    const surveyInfo = this.allSurveyDefinitions.findSurvey(surveyId);
    // const surveyInfo = allSurveyDefinitions.find(
    //   (element) => element.surveyId === surveyId,
    // ) || {name: 'Размерность'};
    return surveyInfo ? surveyInfo.name : 'Размерность';
  }

  getSurvey(
    surveyId: SurveyId,
    surveyModel?: SurveyJs.SurveyModel,
  ): SurveyState | undefined {
    // await this._loadLocal();
    const surveyState = this._data?.surveys[surveyId];

    if (!surveyState) return undefined;
    // console.log(`Found previous survey session with surveyId: "${surveyId}"`, surveyState);

    if (!surveyModel) return surveyState;
    // console.log(`Restoring the survey session with surveyId: "${surveyId}"`, surveyState);
    stateToSurvey(surveyState, surveyModel);

    return surveyState;
  }

  getResult(surveyId: SurveyId): SurveyResult {
    // await this._loadLocal();
    this.calculate(surveyId);
    const surveyResult = this._data?.results[surveyId];
    if (!surveyResult)
      return {
        total: 0,
        level: 0,
        aspects: [],
      };
    return surveyResult;
  }

  getDefinition(surveyId: SurveyId): AllSurveysElement | undefined {
    return this.allSurveyDefinitions.findSurvey(surveyId);
  }

  getQuestions(surveyId: SurveyId): SurveyQuestions | undefined {
    return this.allSurveyQuestionsDefinitions.findSurvey(surveyId);
  }

  getOrgOkved(): string | undefined {
    return this.getSurvey('org')?.data?.okved as string;
  }

  getOrgSize(): string | undefined {
    return this.getSurvey('org')?.data?.size as string;
  }

  async getAverages(): Promise<AvgSurveyResultsQuery> {
    const okved = this.getOrgOkved();
    const size = this.getOrgSize();
    return this.backendHttp.getSurveysAvg({ okved, size });
  }

  async _saveRemote(): Promise<FullSurveyData> {
    return this.backendHttp.postSurveys(this._data).then((result) => {
      return result;
    });
  }

  async save(): Promise<FullSurveyData> {
    const remoteData = await this._saveRemote();
    this._data = remoteData;
    this._saveLocal();
    this._setChanged(false);
    return remoteData;
  }

  //////////////////////////////////////////////////////////////////////////////
}

////////////////////////////////////////////////////////////////////////////////
//
// function _getStorageName(surveyId: string) {
//   return `${BASE_STORAGE_NAME}${ surveyId ? '_'+surveyId : '' }`;
// }
//
//
// function clearSavedData(surveyId: string) {
//   console.log(`clearSavedData with surveyId: "${surveyId}"`);
//   window.localStorage.removeItem(_getStorageName(surveyId));
// }
//
//
// function saveAllSurveyState(allSurveyStates: AllSurveyStates): void {
//   window.localStorage.setItem(BASE_STORAGE_NAME, JSON.stringify(allSurveyStates));
// }
//
//
// function loadAllSurveyState(): AllSurveyStates {
//   const s = window.localStorage.getItem(BASE_STORAGE_NAME) || '{}';
//   try {
//     return JSON.parse(s);
//   } catch {
//     const msg = 'Error parsing saved JSON';
//     console.error(new Error(msg));
//     return {};
//   }
// }

//
//
// function saveSingleSurveyState(surveyId: string, surveyState: SurveyState|null): void {
//   console.log(`saveSingleSurveyState() surveyId: "${surveyId}"`, surveyState);
//   const allSurveyState = loadAllSurveyState();
//   if (surveyState) {
//     allSurveyState[surveyId] = surveyState;
//   } else {
//     delete allSurveyState[surveyId];
//   }
//   saveAllSurveyState(allSurveyState);
// }
//
//
// function loadSingleSurveyState(surveyId: string): SurveyState | null {
//   const  allSurveyState = loadAllSurveyState();
//   const surveyState = allSurveyState[surveyId];
//   console.log(`loadSingleSurveyState() surveyId: "${surveyId}"`, surveyState);
//   return surveyState ? surveyState : null;
// }
//
//

function surveyToState(
  survey: SurveyJs.SurveyModel,
  completed: boolean = false,
): SurveyState {
  const { data, currentPageNo: pageNo } = survey;
  const surveyState = {
    data,
    pageNo,
    completed,
    selected: true,
  };

  // const surveyState = remap(survey, {
  //   'data': 'data',
  //   'pageNo': 'currentPageNo',
  // }, {});
  // if (typeof completed === 'boolean') {
  //   surveyState.completed = completed;
  // }

  // console.log(`surveyToState`, surveyState);
  return surveyState;
}

function stateToSurvey(
  surveyState: SurveyState,
  survey?: SurveyJs.SurveyModel,
): void {
  if (!survey) return;
  const { data, pageNo } = surveyState;
  survey.data = data;
  if (pageNo) {
    survey.currentPageNo = pageNo;
  }
}

//
//
// function saveSingleSurveyData(surveyId: string, survey: SurveyJs.SurveyModel, completed: boolean=false): void {
//   const surveyState = surveyToState(survey, completed);
//   saveSingleSurveyState(surveyId, surveyState);
// }
//
//
// function saveSurveyCompleted(surveyId: string, completed=false) {
//   console.log(`saveSurveyCompleted with surveyId: "${surveyId}"`, { completed });
//   const surveyState = loadSingleSurveyState(surveyId);
//   if (surveyState) {
//     surveyState.completed = completed;
//   }
//   saveSingleSurveyState(surveyId, surveyState);
// }
//
//
// function loadSurveyData(surveyId: string, survey: SurveyJs.SurveyModel | null): SurveyState | null {
//   const surveyState = loadSingleSurveyState(surveyId);
//   if (!surveyState) return surveyState;
//   console.log(`Found previous survey session with surveyId: "${surveyId}"`, surveyState);
//
//   if (!survey) return surveyState;
//   console.log(`Restoring the survey session with surveyId: "${surveyId}"`, surveyState);
//   stateToSurvey(surveyState, survey);
//   return surveyState;
// }
//
////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////

// {
//   total: 6.25,
//   level: 6,
//   aspects: [
//     { id: 'a1', name: 'name1', value: 3.5, },
//     { id: 'a2', name: 'name2', value: 1.25, },
//     { id: 'a3', name: 'name3', value: 0.25, },
//     { id: 'a4', name: 'name4', value: 1.25, },
//   ]
// }
//

export const map1 = (
  definitions: SurveyAspectWeightDefinitions,
  surveyData: SurveyState | undefined,
  parser: pegjs.Parser,
): SurveyResult => {
  console.log(`map1() definitions`, definitions);
  console.log(`map1() surveyData`, surveyData);
  //if (!window.grammar) throw new Error('Grammar is not loaded');

  const result: SurveyResult = {
    total: 0,
    level: 0,
    aspects: [],
  };

  for (let i = 0; i < definitions.length; i++) {
    const def: AspectWeightDefinition = definitions[i];
    // for (const def of definitions) {
    // const def: AspectWeightDefinition = definitions[aspectId];
    const aspectWeight = def.w;
    let aspectQuestions: AspectQuestionWeight[] = def.q;

    const aspect: SurveyAspectResult = {
      aspectId: def.id,
      name: def.name,
      description: def.description,
      value: 0,
    };

    if (!Array.isArray(aspectQuestions)) {
      // means it is an object and we need to convert it into array
      aspectQuestions = objectToArray(aspectQuestions);
      console.log('aspectQuestions (after objectToArray) :', aspectQuestions);
    }

    for (const index in aspectQuestions) {
      const exprDef = aspectQuestions[index].q;
      const w = aspectQuestions[index].w;
      //console.log('exprDef:', exprDef, w)

      try {
        const exprResult = parser.parse(exprDef, { values: surveyData?.data });
        const strFound = !!exprResult ? 'found' : '-';
        console.log(`exprDef: '${exprDef}':${w} => ${strFound} `);

        if (!!exprResult) {
          aspect.value += w;
          result.total += w * aspectWeight;
          break;
        }
      } catch (error) {
        console.error(
          `ERROR parsing expression "${exprDef}", values: ${JSON.stringify(
            surveyData?.data,
          )} `,
        );
        throw error;
      }
    }
    result.aspects.push(aspect);
  }
  result.level = Math.round(result.total);
  console.log(`map1() result`, result);
  return result;
};
