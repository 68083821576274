/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { FieldProps } from '@rjsf/semantic-ui';
import { WidgetProps } from '@rjsf/semantic-ui';
import { Header } from 'semantic-ui-react';
import { TitleField as TitleField_ } from '@rjsf/semantic-ui/dist/es/Fields/';
import * as _ from 'lodash';

function TitleField(props: FieldProps) {
  console.log('TitleField: props:', props);
  let { title, semantic, options } = props;
  semantic = semantic ?? {};
  semantic = Object.assign({ inverted: false, dividing: true }, semantic);
  const { titleTemplate } = options ?? '';
  const title_ = titleTemplate ? _.template(titleTemplate)(props) : title;
  return (
    <h3 className="custom-title-field">
      <Header {...semantic}>{title_}</Header>
    </h3>
  );
}
//
// TitleField.defaultProps = {
//   options: {
//     semantic: {
//       inverted: false,
//       dividing: true
//     }
//   }
// };
//
// TitleField.propTypes = {
//   options: PropTypes.object
// };

export { TitleField };
export default TitleField;
