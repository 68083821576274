/** @format */

import React, { useState } from 'react';
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart /*ResponsiveContainer, PolarRadiusAxis*/,
} from 'recharts';

import { ChartData, ChartDataPoint } from '../../lib/calc';

import {
  animationDuration,
  gridColor,
  strokeDasharray,
  fillColor,
  fillColor2,
  fillOpacity,
  AspectsBarChartProps,
  axisTicks,
} from './defaults';
import { MAX_LEVEL } from '../../lib/const';
import { CustomTooltip } from './CustomTooltip';

export function AspectsBarChart(props: AspectsBarChartProps) {
  const [tooltipKey, setTooltipKey] = useState('');

  // // https://stackoverflow.com/a/59652280/2774010
  // // http://recharts.org/en-US/examples/CustomContentOfTooltip
  // // var tooltip: string;
  // const CustomTooltip = ({
  //   active,
  //   payload,
  //   label,
  // }: {
  //   active?: boolean;
  //   payload?: any;
  //   label?: string;
  // }) => {
  //   //console.log('CustomTooltip', active, payload, label)
  //   if (!active || !tooltipKey || !payload) return null;
  //   for (const bar of payload)
  //     if (bar.dataKey === tooltipKey)
  //       return (
  //         <div className="custom-tooltip">
  //           {bar.name}:
  //           <br />
  //           {/*{ Math.round(bar.value) } из { MAX_LEVEL }*/}
  //           {bar.value} из {MAX_LEVEL}
  //         </div>
  //       );
  //   return null;
  // };

  const count = props.data.length;

  //
  // increase width for the label text:
  // ----------------------------------
  // yAxis tick labels are clipped on a vertical bar chart
  // https://github.com/recharts/recharts/issues/1480
  //

  const barSize = 30;
  const margin = 10;
  const leftMargin = 350;
  const space = 25;
  const chartOuterWidth = 1000;
  const chartInnerWidth = chartOuterWidth - 100;
  const chartHeight = (barSize + space) * count + 2 * margin;
  //return <Example />

  // prepare data for right part of the bar to draw it as stacked
  const data: ChartData = props.data.map((d: ChartDataPoint) => {
    d.value2 = MAX_LEVEL - d.value;
    return d;
  });

  return (
    //<ResponsiveContainer height={250} width={"100%"}>
    <div style={{ margin: '0 auto', width: `${chartOuterWidth}px` }}>
      <div style={{ height: chartHeight, width: chartOuterWidth }}>
        {/*
      <ResponsiveContainer
        height={chartHeight}
        width={chartOuterWidth}
      >
*/}
        <BarChart
          layout="vertical"
          width={chartInnerWidth}
          height={chartHeight}
          data={data}
          margin={{
            top: margin,
            right: margin,
            bottom: margin,
            left: leftMargin,
          }}
        >
          <CartesianGrid stroke={gridColor} strokeDasharray={strokeDasharray} />
          <XAxis
            type="number"
            domain={[0, MAX_LEVEL]}
            scale={'linear'}
            tick={axisTicks}
          />
          <YAxis
            dataKey="name"
            type="category"
            padding={{ top: margin, bottom: margin }}
            tick={axisTicks}
          />
          <Tooltip content={<CustomTooltip />} />
          {/*<Legend />*/}
          {/*<Area dataKey="amt" fill="#8884d8" stroke="#8884d8" />*/}
          <Bar
            dataKey="value"
            stackId="a"
            barSize={barSize}
            fill={fillColor}
            fillOpacity={fillOpacity}
            animationDuration={animationDuration}
            name="Значение"
            onMouseOver={() => {
              // tooltip="value";
              setTooltipKey('value');
            }}
          />
          <Bar
            dataKey="value2"
            stackId="a"
            barSize={barSize}
            fill={fillColor2}
            fillOpacity={fillOpacity}
            animationDuration={animationDuration}
            onMouseOver={() => {
              // tooltip="value";
              setTooltipKey('value');
            }}
          />
          {/*<Bar dataKey="value" barSize={ barSize } fill="#5f89ad" />*/}
          {/*<Line dataKey="uv" stroke="#ff7300" />*/}
        </BarChart>
        {/*
      </ResponsiveContainer>
*/}
      </div>
    </div>
  );
}
