/** @format */

import JSONSchemaForm from '@rjsf/semantic-ui';
import { JSONSchema7 } from 'json-schema';
import React, { PropsWithChildren, useState, useEffect } from 'react';
import {NotReady} from "../../basic/NotReady";

import { CustomArrayField } from '../../rjsf';
import {
  SURVEY_EDIT_QUESTIONS_LIST_PATH,
  SURVEY_EDIT_QUESTIONS_PATH,
  SURVEY_LEVEL_EDIT_PATH,
} from '@shared/config';
import Surveys_uiSchema from '@shared/schemas-ui/Surveys.ui-schema';
import { routeSubstitute } from '../../../lib/utils';
import { Surveys } from '../../../lib/surveys';
import { Link } from '../../basic/Link';
import { Loading } from '../../basic/Loading';
import { Notfound } from '../../basic/Notfound';
import { LocalizableString } from '@shared/types/SurveyQuestions';
import { customFields, customWidgets } from '../../rjsf';
import {Title} from './components/Title'
import SurveysSchema_ from '@shared/schemas/Surveys.schema';
import AllSurveysSchema_ from '@shared/schemas/AllSurveys.schema';
const SurveysSchema = SurveysSchema_ as JSONSchema7;

export const AdminListSurveyLevel = (props: {
  surveys: Surveys;
  surveyId: string;
}) => {
  console.log('AdminListSurveyLevel props:', props);
  const { surveys, surveyId } = props;
  if (surveys.state !== 'ready') return <NotReady state={surveys.state} />;

  const surveyQuestions = surveys.getQuestions(surveyId);
  const surveyDefinitions = surveys.getDefinition(surveyId);
  if (!surveyQuestions || !surveyDefinitions || surveyQuestions._type!=='survey-js') return <Notfound message="Раздел опроса не найден" />;

  return (
    <nav className="ui main container">
      <header className="ui container">
        <h2 className="ui center aligned header">
          Редактирование описания уровней зрелости для размерности "{surveyDefinitions.name}"
        </h2>
        <h3>
          Выберите уровень зрелости размерности "{surveyDefinitions.name}" для его
          редактирования:
        </h3>
      </header>
      <main>
        {/*<ul>*/}
        <ul>
          {surveyDefinitions.maturityLevels.map((p) => (
            <li style={{ display: 'block' }} key={`${p.level}`}>
              <Link
                to={routeSubstitute(SURVEY_LEVEL_EDIT_PATH, {
                  surveyId,
                  levelId: p.level,
                })}
              >
                <div style={{ display: 'inline' }}>
                  {p.level} - <Title title={p.name} />
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </main>
    </nav>
  );

  const schema = SurveysSchema as JSONSchema7;

  const uiSchema = Surveys_uiSchema;
  // uiSchema['ui:options'] = {};

  customFields['ArrayField'] = CustomArrayField;

  return (
    <div className="ui container">
      {surveyDefinitions ? (
        <JSONSchemaForm
          formData={surveyDefinitions}
          schema={schema}
          uiSchema={uiSchema}
          formContext={{
            'ui:options': {
              semantic: {
                wrapLabel: true,
                wrapContent: true,
              },
            },
          }}
          widgets={customWidgets}
          fields={customFields}
          // FieldTemplate={CustomFieldTemplate}
        />
      ) : null}
    </div>
  );
};
