/** @format */

import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { MAX_LEVEL } from '../../lib/const';
import { ChartDataPoint } from '../../lib/calc';
import { Surveys } from '../../lib/surveys';

import { SurveysRadarChart } from '../charts/SurveysRadarChart';
import { AvgSurveyResultsQuery } from '@shared/types/SurveyResult';

interface SurveysSummaryProps extends RouteComponentProps<any> {
  surveys: Surveys;
}

const getHtml1 = (lvl: number /* 1..5 */) => {
  const getReportHeader = () =>
    `<h1>Итоговый отчет уровня зрелости работы с большими данными</h1>`;

  const getReportSubHeader = (lvl: number, max: number) =>
    `<h2>Итоговый уровень зрелости вашей организации по работе с большими данными - ${lvl} из ${max} </h2>`;

  const levels = [
    `<p>Ваш уровень соответствует <strong>начальному</strong> уровню зрелости в работе с большими данными</p>`,
    `<p>Ваш уровень соответствует <strong>формирующемуся</strong> уровню зрелости в работе с большими данными</p>`,
    `<p>Ваш уровень соответствует <strong>среднему</strong> уровню зрелости в работе с большими данными</p>`,
    `<p>Ваш уровень соответствует <strong>продвинутому</strong> уровню зрелости в работе с большими данными</p>`,
    `<p>Ваш уровень соответствует <strong>высокому</strong> уровню зрелости в работе с большими данными</p>`,
  ];

  const reportDescription = levels[lvl - 1] || '';

  return (
    `<div>` +
    getReportHeader() +
    getReportSubHeader(lvl, MAX_LEVEL) +
    reportDescription +
    `</div>`
  );
};

type ReportType = 'size' | 'okved' | 'total';

const getHtml2 = (type: ReportType, lvl: number /* 1..5 */) => {
  // const getReportHeader = () => `<h1>Итоговый отчет уровня зрелости работы с большими данными</h1>`;
  const getReportHeader = () => ``;

  const getReportSubHeader = (type: ReportType, lvl: number, max: number) => {
    switch (type) {
      case 'size':
        return `<h2>Средний уровень зрелости организаций аналогичного размера по работе с большими данными - ${lvl} из ${max} </h2>`;
      case 'okved':
        return `<h2>Средний уровень зрелости организаций аналогичного вида деятельности по работе с большими данными - ${lvl} из ${max} </h2>`;
      case 'total':
        return `<h2>Средний уровень зрелости организаций в целом по работе с большими данными - ${lvl} из ${max} </h2>`;
    }
  };
  const levels = [
    `<p>Средний уровень организаций соответствует <strong>начальному</strong> уровню зрелости в работе с большими данными</p>`,
    `<p>Средний уровень организаций соответствует <strong>формирующемуся</strong> уровню зрелости в работе с большими данными</p>`,
    `<p>Средний уровень организаций соответствует <strong>среднему</strong> уровню зрелости в работе с большими данными</p>`,
    `<p>Средний уровень организаций соответствует <strong>продвинутому</strong> уровню зрелости в работе с большими данными</p>`,
    `<p>Средний уровень организаций соответствует <strong>высокому</strong> уровню зрелости в работе с большими данными</p>`,
  ];

  const reportDescription = levels[lvl - 1] || '';

  return (
    `<div>` +
    getReportHeader() +
    getReportSubHeader(type, lvl, MAX_LEVEL) +
    reportDescription +
    `</div>`
  );
};

export function SurveysSummary(props: SurveysSummaryProps) {
  const { surveys } = props;

  const [avgData, setAvgData] = useState<AvgSurveyResultsQuery | null>(null);

  useEffect(() => {
    if (!avgData)
      surveys.getAverages().then((data) => {
        setAvgData(data);
      });
  });

  const totalAvgData = avgData?.total;
  const okvedAvgData = avgData?.okved;
  const sizeAvgData = avgData?.size;

  let surveysTotal = 0;

  let totalAvgTotal = 0;
  let okvedAvgTotal = 0;
  let sizeAvgTotal = 0;

  const chartData = surveys.allSurveyDefinitions._data
    // убираем информацию об организации
    ?.filter(({ surveyId }) => surveyId !== 'org')
    // добавляем информацию о текущем опросе
    .map((surveyDef /*, surveyIndex*/) => {
      const surveyState = surveys.getSurvey(surveyDef.surveyId, undefined);
      console.log('SurveysSummary: surveyState:', surveyState);
      return { surveyDef, surveyState };
    })
    // оставляем только те размерности, которые выбраны пользователем,
    // и данные по которым введены
    .filter(
      ({ surveyState }) =>
        surveyState && surveyState.completed && surveyState.selected,
    )
    .map(
      ({ surveyDef, surveyState } /*, surveyIndex*/): ChartDataPoint => {
        const {
          surveyId,
          name: surveyName,
          disabled,
          weight: surveyWeight /*, aspectWeights*/,
        } = surveyDef;

        // const surveyState = surveyQuestions.getSurvey(surveyId, undefined);
        // console.log('SurveysSummary: surveyState:', surveyState);

        if (!surveyState) {
          // survey is empty
          const empty = {
            id: surveyId,
            name: '', // flag to later filter it out
            value: 0,
            value2: 0,
          };
          return empty;
          // } else if (!surveyState.completed || !surveyState.selected) {
          //   return undefined; // survey was not completed or not selected
        } else {
          const surveyResult = surveys.getResult(surveyId);
          // console.log(`FinalReport: surveyResult.level: ${surveyResult.level} surveyWeight: ${surveyWeight}, surveysTotal: ${surveysTotal}`)

          surveysTotal += surveyResult.level * surveyWeight;

          const totalLvl = totalAvgData
            ? Math.round(totalAvgData.results[surveyId].total)
            : 0;
          totalAvgTotal += totalLvl * surveyWeight;
          const totalAvg = totalAvgData
            ? totalAvgData.results[surveyId].total
            : 0;

          const okvedLvl = okvedAvgData
            ? Math.round(okvedAvgData.results[surveyId].total)
            : 0;
          okvedAvgTotal += okvedLvl * surveyWeight;
          const okvedAvg = okvedAvgData
            ? okvedAvgData.results[surveyId].total
            : 0;

          const sizeLvl = sizeAvgData
            ? Math.round(sizeAvgData.results[surveyId].total)
            : 0;
          sizeAvgTotal += sizeLvl * surveyWeight;
          const sizeAvg = sizeAvgData ? sizeAvgData.results[surveyId].total : 0;

          return {
            id: surveyId,
            name: surveyName,
            value: surveyResult.level,
            // value2: avg.toFixed(2),
            totalAvg: totalAvg ? totalAvg.toFixed(2) : '0.00',
            okvedAvg: okvedAvg ? okvedAvg.toFixed(2) : '0.00',
            sizeAvg: sizeAvg ? sizeAvg.toFixed(2) : '0.00',
          };
        }
      },
    )
    .filter((cd) => !!cd && cd.name); // remove empty surveyQuestions
  console.log('SurveysSummary: chartData:', chartData);

  // const chartAvgData = avgData
  //   ? Object.keys(avgData.results)
  //   .map(k => {
  //     surveysAvgTotal += surveyResult.level * surveyWeight;
  //     return {
  //       name: surveyQuestions.getSurveyName(k),
  //       value: avgData.results[k].total,
  //     }
  //   })
  //   : null;

  surveysTotal = Math.round(surveysTotal);

  totalAvgTotal = Math.round(totalAvgTotal);
  okvedAvgTotal = Math.round(okvedAvgTotal);
  sizeAvgTotal = Math.round(sizeAvgTotal);

  return (
    <div>
      <div
        style={{ textAlign: 'center' }}
        dangerouslySetInnerHTML={{ __html: getHtml1(surveysTotal) }}
      />

      {chartData ? (
        <React.Fragment>
          <SurveysRadarChart
            data={chartData}
            // showValue={true}
            // showAvg={false}
            dataKeys={['value']}
          />

          {
            sizeAvgData ? (
              <React.Fragment>
                <div
                  style={{ textAlign: 'center' }}
                  dangerouslySetInnerHTML={{
                    __html: getHtml2('size', sizeAvgTotal),
                  }}
                />

                <SurveysRadarChart
                  data={chartData}
                  // showValue={true}
                  // showAvg={true}
                  dataKeys={['value', 'sizeAvg']}
                />
              </React.Fragment>
            ) : null
            // <p>К сожалению, для компаний такого размера недостаточно данных для формирования сравнительного отчета</p>
          }

          {
            okvedAvgData ? (
              <React.Fragment>
                <div
                  style={{ textAlign: 'center' }}
                  dangerouslySetInnerHTML={{
                    __html: getHtml2('okved', okvedAvgTotal),
                  }}
                />

                <SurveysRadarChart
                  data={chartData}
                  // showValue={true}
                  // showAvg={true}
                  dataKeys={['value', 'okvedAvg']}
                />
              </React.Fragment>
            ) : null
            // <p>К сожалению, для компаний с таким кодом ОКВЭД недостаточно данных для формирования сравнительного отчета</p>
          }

          {
            totalAvgData ? (
              <React.Fragment>
                <div
                  style={{ textAlign: 'center' }}
                  dangerouslySetInnerHTML={{
                    __html: getHtml2('total', totalAvgTotal),
                  }}
                />

                <SurveysRadarChart
                  data={chartData}
                  // showValue={true}
                  // showAvg={true}
                  dataKeys={['value', 'totalAvg']}
                />
              </React.Fragment>
            ) : null
            // <p>К сожалению, недостаточно данных для формирования сравнительного отчета</p>
          }
        </React.Fragment>
      ) : null}
    </div>
  );
}
