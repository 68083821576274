/** @format */

import React from 'react';

export const Error = ({
  message,
  children,
}: {
  message?: string;
  children?: React.ReactNode;
}) => (
  <h1 style={{ color: 'red' }}>
    {message ? (
      message
    ) : children ? (
      <React.Fragment>
        <span>Ошибка:</span>
        {children}
      </React.Fragment>
    ) : (
      <span>Произошла ошибка. Попробуйте повторить операцию позже.</span>
    )}
  </h1>
);
