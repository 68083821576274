import React from "react";
import {WidgetProps} from "@rjsf/semantic-ui";
import RadioWidget from "@rjsf/core/lib/components/widgets/RadioWidget";

import {WidgetLabel} from "../components/WidgetLabel";

export const InlineRadioWidget = (props: WidgetProps) => {
  console.log('InlineRadioWidget: props:', props)
  return (
    <div className="custom-inline-widget custom-radio-widget">
      <WidgetLabel {...props} />
      <RadioWidget {...props} />
    </div>
  );
};

