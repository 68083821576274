/** @format */

import { JSONSchema7 } from 'json-schema';

const schema: JSONSchema7 = {
  type: 'object',
  // title: 'Редактирование формы "Информация о компании"',
  title: '',
  // description: 'Редактирование формы "Информация о компании"',
  description: '',
  definitions: {
    field: {
      type: 'object',
      required: ['title'],
      properties: {
        title: { title: 'Название', type: 'string' },
        description: { title: 'Описание', type: 'string' },
        format: { title: 'Формат', /*type: 'string',*/ enum: ['email'] },
        pattern: { title: 'Шаблон', type: 'string' },
      },
    },
  },
  properties: {
    properties: {
      type: 'object',
      title: '',
      properties: {
        inn: { $ref: '#/definitions/field' },
        okved: { $ref: '#/definitions/field' },
        size: {
          properties: {
            title: { title: 'Название', type: 'string' },
            description: { title: 'Описание', type: 'string' },
            oneOf: {
              type: 'array',
              title: 'Выбор из списка',
              items: {
                type: 'object',
                properties: {
                  const: {
                    title: 'Идентификатор',
                    type: 'string',
                  },
                  title: {
                    title: 'Описание',
                    type: 'string',
                  },
                },
              },
            },
          },
        },
        email: { $ref: '#/definitions/field' },
      },
    },
  },
};

export default schema;
