/** @format */

import React from 'react';
import {
  Tooltip,
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
} from 'recharts';
import { ChartData } from '../../lib/calc';

import { routeSubstitute } from '../../lib/utils';
import { SURVEY_PATH } from '@shared/config';
import {
  animationDuration,
  axisTicks,
  /*fontColor,*/ gridColor,
  strokeDasharray,
  fillColor,
  fillColorAvg,
  radarFillOpacity,
  radarStrokeWidth,
  AspectsBarChartProps,
  radarChartWidth,
  radarChartHeight,
  radarChartDiameter,
} from './defaults';
import { MAX_LEVEL } from '../../lib/const';
import { CustomTooltip } from './CustomTooltip';
import { CustomPolarAngleAxisTick, CustomPolarRadiusAxisTick } from './Tick';

import './AspectsRadarChart.scss';

interface SurveysRadarChartProps extends AspectsBarChartProps {
  // showValue: boolean;
  // showAvg: boolean;
  dataKeys: string[];
}

const colors = {
  value: fillColor,
  sizeAvg: fillColorAvg,
  okvedAvg: fillColorAvg,
  totalAvg: fillColorAvg,
};

const names = {
  value: 'Значение',
  sizeAvg: 'Среднее',
  okvedAvg: 'Среднее',
  totalAvg: 'Среднее',
};

export function SurveysRadarChart(props: SurveysRadarChartProps) {
  const { data, dataKeys } = props;
  console.log('SurveysRadarChart: data:', data);
  return (
    <RadarChart
      cx={'50%'}
      cy={'50%'}
      outerRadius={radarChartDiameter / 2}
      width={radarChartWidth}
      height={radarChartHeight}
      data={data}
      className="SurveysRadarChart"
      style={{ margin: '0 auto' }}
    >
      <PolarGrid stroke={gridColor} strokeDasharray={strokeDasharray} />
      <PolarAngleAxis
        dataKey="id"
        // dataKey="name"
        tick={
          <CustomPolarAngleAxisTick
            getUrl={(index) =>
              routeSubstitute(SURVEY_PATH, { surveyId: data[index]?.id })
            }
            getName={(index) => data[index]?.name}
          />
        }
      />
      <PolarRadiusAxis
        type={'number'}
        angle={0}
        domain={[0, MAX_LEVEL]}
        scale={'linear'}
        // tick={axisTicks}
        tick={<CustomPolarRadiusAxisTick />}
      />
      {dataKeys.map((dataKey) => {
        return (
          <Radar
            key={dataKey}
            name={names[dataKey]}
            dataKey={dataKey}
            stroke={colors[dataKey]}
            strokeWidth={radarStrokeWidth}
            fill={colors[dataKey]}
            fillOpacity={radarFillOpacity}
            animationDuration={animationDuration}
          />
        );
      })}
      <Tooltip content={<CustomTooltip />} />
    </RadarChart>
  );
}
