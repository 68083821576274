export function transformErrors(errors) {
  console.log('transformErrors:', errors);
  return errors.map((error) => {
    if (error.name === 'required') {
      error.message = 'Значение не может быть пустым';
      // } else if (error.name === 'format' && error?.params?.format === 'email') {
      // } else if (error.name === 'format') {
      //   if (error.property === '.email') {
      //     error.message =
      //       'Пожалуйста, укажите корректный адрес электронной почты';
      //   }
      // } else if (error.name === 'pattern') {
      //   if (error.property === '.inn') {
      //     error.message = 'Пожалуйста, введите корректный ИНН';
      //   } else if (error.property === '.okved') {
      //     error.message = 'Пожалуйста, введите корректный ОКВЭД';
      //   }
    } else if (error.name === 'format' || error.name === 'pattern') {
      error.message = 'Пожалуйста, введите корректное значение';
    }
    return error;
  });
}

export default transformErrors;
