import React from "react";


export const Hr = (props:any) => (
  <div {...props}>
    <hr
      style={{
        borderTop: `1px solid`,
      }}
    />
  </div>
);
