/** @format */

import { jsPDF } from 'jspdf';
// import {Canvg} from "canvg";

import { totalWidth } from './const';
import { font } from './svgFontCss';
import { BackendHttp, OnProgressHandler } from '../services/BackendHttp';

function insertAfter(referenceNode: Node, newNode: Node) {
  referenceNode.parentNode?.insertBefore(newNode, referenceNode.nextSibling);
}

function insertBefore(referenceNode: Node, newNode: Node) {
  referenceNode.parentNode?.insertBefore(newNode, referenceNode);
}

//
// https://stackoverflow.com/questions/42402584/how-to-use-google-fonts-in-canvas-when-drawing-dom-objects-in-svg
//
/*
  Only tested on a really limited set of fonts, can very well not work
  This should be taken as an proof of concept rather than a solid script.

  @Params : an url pointing to an embed Google Font stylesheet
  @Returns : a Promise, fulfiled with all the cssRules converted to dataURI as an Array
*/
function GFontToDataURI(url: string): Promise<string[]> {
  return fetch(url) // first fecth the embed stylesheet page
    .then((resp) => resp.text()) // we only need the text of it
    .then((text) => {
      // now we need to parse the CSSruleSets contained
      // but chrome doesn't support styleSheets in DOMParsed docs...
      let s = document.createElement('style');
      s.innerHTML = text;
      document.head.appendChild(s);
      let styleSheet = s.sheet;

      // this will help us to keep track of the rules and the original urls
      let FontRule = (rule: any | undefined) => {
        if (!rule) return null;
        let src =
          rule.style.getPropertyValue('src') ||
          rule.style.cssText.match(/url\(.*?\)/g)?.[0];
        if (!src) return null;
        let url = src.split('url(')[1].split(')')[0];
        return {
          rule: rule,
          src: src,
          url: url.replace(/\"/g, ''),
        };
      };
      let fontRules = [],
        fontProms = [];

      // iterate through all the cssRules of the embedded doc
      // Edge doesn't make CSSRuleList enumerable...
      for (let i = 0; i < (styleSheet?.cssRules?.length ?? 0); i++) {
        let r = styleSheet?.cssRules[i];
        let fR = FontRule(r);
        if (!fR) {
          continue;
        }
        fontRules.push(fR);
        fontProms.push(
          fetch(fR?.url) // fetch the actual font-file (.woff)
            .then((resp) => resp.blob())
            .then((blob) => {
              return new Promise((resolve) => {
                // we have to return it as a dataURI
                //   because for whatever reason,
                //   browser are afraid of blobURI in <img> too...
                let f = new FileReader();
                f.onload = (e) => resolve(f.result);
                f.readAsDataURL(blob);
              });
            })
            .then((dataURL) => {
              // now that we have our dataURI version,
              //  we can replace the original URI with it
              //  and we return the full rule's cssText
              return fR?.rule.cssText.replace(fR.url, dataURL);
            }),
        );
      }
      document.head.removeChild(s); // clean up
      return Promise.all(fontProms); // wait for all this has been done
    });
}

//
// https://stackoverflow.com/questions/3975499/convert-svg-to-image-jpeg-png-etc-in-the-browser
//
function copyStylesInline(destinationNode: any, sourceNode: any) {
  const containerElements = ['svg', 'g'];
  for (let cd = 0; cd < destinationNode.childNodes.length; cd++) {
    const child = destinationNode.childNodes[cd];
    if (containerElements.indexOf(child.tagName) != -1) {
      copyStylesInline(child, sourceNode.childNodes[cd]);
      continue;
    }
    const style =
      sourceNode.childNodes[cd].currentStyle ||
      window.getComputedStyle(sourceNode.childNodes[cd]);
    if (style == 'undefined' || style == null) continue;
    for (let st = 0; st < style.length; st++) {
      child.style.setProperty(style[st], style.getPropertyValue(style[st]));
    }
  }
}

function createCanvas({ width, height }: { width: number; height: number }) {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const context = canvas.getContext('2d');
  console.log('context:', context);

  if (!context) {
    const msg = 'Unable to get Canvas Context';
    console.error(msg);
    // return callback(new Error(msg));
    return { canvas: null, context: null };
  }
  // draw image in canvas starting left-0 , top - 0
  // if (context) {
  //   context.fillStyle = "rgba(124,240,10,0.5)";
  // context.fillStyle = "rgba(255, 255, 255, 1)";
  // context.globalAlpha = 1;
  // context.fillStyle = "#FFF";
  context.fillStyle = 'white';
  //   context['fill-opacity'] = 1;
  context.fillRect(0, 0, width, height);
  return { canvas, context };
}

async function objectUrlToCanvas(
  objectUrl: string,
  origSvgElement: SVGSVGElement,
  width: number,
  height: number,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      const { canvas: newCanvas, context: newCanvasContext } = createCanvas({
        width,
        height,
      });
      if (!newCanvas || !newCanvasContext) {
        console.warn('Error creating Canvas');
        return null;
      }
      newCanvasContext.drawImage(image, 0, 0, width, height);

      //     // Canvg.from(newCanvasContext, origSvgData);
      insertAfter(origSvgElement, newCanvas);
      //     // insertAfter(origSvgElement, newCanvas);
      //     // // insertAfter(origSvgElement, image);
      origSvgElement.style.display = 'none';

      URL.revokeObjectURL(image.src);
      //
      // checkFinish();
      resolve(null);
    };
    // image.onabort
    // image.onerror

    // //   console.log('setTimeout: set')
    // //   setTimeout(() => {
    // //     console.log('setTimeout: callback')
    image.src = objectUrl;
  });
}

//
// http://rahulgaba.com/front-end/2016/07/14/generating-pdf-using-jspdf-and-html2canvas.html
//

//replace all svgs with a temp canvas
export var replaceSVGwithCanvas = async function (
  selector: string /*, callback: any*/,
): Promise<void> {
  return new Promise((resolve, reject) => {
    //find all svg elements in $container
    // var $container = $('body');
    //$container is the jQuery object of the div that you need to convert to image. This div may contain highcharts along with other child divs, etc
    // var svgElements = $container.find('svg');
    const svgElements = document.querySelectorAll(
      `${selector} svg`,
    ) as NodeListOf<SVGSVGElement>;

    let doneCount = 0;

    svgElements.forEach(async function (origSvgElement) {
      //
      function checkFinish() {
        doneCount++;
        if (doneCount >= svgElements.length) {
          console.log(`done: doneCount: ${doneCount}`);
          // callback();
          resolve();
          return true;
        }
        return false;
      }

      // skip SVG elements not visible to the user
      if (
        origSvgElement.style.display === 'none' ||
        origSvgElement.style.visibility === 'hidden'
      ) {
        checkFinish();
        return;
      }

      // var canvas, xml;
      // canvas = document.createElement("canvas");
      // canvas.className = "screenShotTempCanvas";
      // const ctx = canvas.getContext('2d');

      //
      // https://levelup.gitconnected.com/draw-an-svg-to-canvas-and-download-it-as-image-in-javascript-f7f7713cf81f
      //

      // let {width, height} = origSvgElement.getBBox();
      const width = origSvgElement.width.baseVal.value;
      const height = origSvgElement.height.baseVal.value;

      // //
      // // https://stackoverflow.com/questions/48105468/including-fonts-when-converting-svg-to-png
      // //
      // let svgData = new XMLSerializer().serializeToString( origSvgElement );
      // //

      // console.log('width, height:', width, height)

      function cloneSvgElement(origSvgElement: SVGSVGElement) {
        const clonedSvgElement = origSvgElement.cloneNode(true) as HTMLElement;
        copyStylesInline(clonedSvgElement, origSvgElement);
        insertAfter(origSvgElement, clonedSvgElement);
        return clonedSvgElement;
      }
      // const clonedSvgElement = cloneSvgElement(origSvgElement);

      function setTextFontFamily(svgElement: HTMLElement | SVGSVGElement) {
        svgElement
          .querySelectorAll('text')
          .forEach((el) => el.style.setProperty('font-family', 'Montserrat'));
        svgElement.style.setProperty('font-family', 'Montserrat');
      }
      setTextFontFamily(origSvgElement);
      // setTextFontFamily(clonedSvgElement);

      function setCssFont(
        svgElement: HTMLElement | SVGSVGElement,
        cssFont: string,
      ) {
        const defs = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'defs',
        );
        const style = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'style',
        );
        style.type = 'text/css';
        style.innerHTML = cssFont;
        defs.appendChild(style);
        svgElement.appendChild(defs);
      }
      setCssFont(origSvgElement, font);
      // setCssFont(clonedSvgElement, font)

      function setSvgTextStyles(
        svgEl: SVGSVGElement | HTMLElement | Document,
        cssRulesText: string | string[],
      ) {
        const svgDoc = document;
        const svgNS = 'http://www.w3.org/2000/svg';
        // so let's append it in our svg node
        const defs = svgDoc.createElementNS(svgNS, 'defs');
        // let defs = svgDoc.createElement('defs');
        const style = svgDoc.createElementNS(svgNS, 'style');
        // let style = svgDoc.createElement('style');
        style.innerHTML = Array.isArray(cssRulesText)
          ? cssRulesText.join('\n')
          : cssRulesText;
        defs.appendChild(style);
        svgEl.appendChild(defs);
      }

      GFontToDataURI('https://fonts.googleapis.com/css?family=Montserrat').then(
        async (cssRules) => {
          // console.log('css loaded, count:', cssRules.length)
          setSvgTextStyles(origSvgElement, cssRules);
          // setSvgTextStyles(clonedSvgElement, cssRules )
          // console.log('setSvgTextStyles(1...')
          setSvgTextStyles(origSvgElement, 'text { font-family: Montserrat; }');
          // setSvgTextStyles(clonedSvgElement, 'text { font-family: Montserrat; }')
          // console.log('setSvgTextStyles(2...')

          // let outerHTML = clonedSvgElement.outerHTML;

          // let blob = new Blob([outerHTML],{type:'image/svg+xml;charset=utf-8'});

          const DOMURL = window.URL || window.webkitURL || window;
          // const blobURL = URL.createObjectURL(blob);

          // const clonedSvgData = (new XMLSerializer()).serializeToString(clonedSvgElement);
          const origSvgData = new XMLSerializer().serializeToString(
            origSvgElement,
          );

          //
          // http://rahulgaba.com/front-end/2016/07/14/generating-pdf-using-jspdf-and-html2canvas.html
          //

          // https://vijayt.com/post/save-svg-element-with-custom-font-as-image/

          // Removing the name space as IE throws an error
          // origSvgData = origSvgData.replace(/xmlns=\"http:\/\/www\.w3\.org\/2000\/svg\"/, '');

          const origSvgBlob = new Blob([origSvgData], {
            type: 'image/svg+xml;charset=utf-8',
          });
          // const clonedSvgBlob = new Blob([clonedSvgData], {type: "image/svg+xml;charset=utf-8"});
          const objectUrl = URL.createObjectURL(origSvgBlob);

          // const newCanvasContext = createCanvas({ width, height });
          //   newCanvasContext.drawImage(image, 0, 0, width, height);
          // }

          // let v = await Canvg.from(newCanvasContext, origSvgData);
          // v.start();

          // insertAfter(origSvgElement, newCanvas);
          // insertAfter(origSvgElement, image);
          // origSvgElement.style.display = 'none';

          await objectUrlToCanvas(objectUrl, origSvgElement, width, height);

          checkFinish();

          // //   }, 2000)
          // //
          // //
        },
      );

      // // https://stackoverflow.com/questions/23223718/failed-to-execute-btoa-on-window-the-string-to-be-encoded-contains-characte
      // const base64 = btoa(svgData.replace(/[\u00A0-\u2666]/g, function(c) {
      //   return '&#' + c.charCodeAt(0) + ';';
      // }));
      // image.setAttribute( "src", "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svgData)) ) );
      // //

      // if (!ctx) {return}
      //convert SVG into a XML string

      // xml = (new XMLSerializer()).serializeToString(origSvgElement);
      // console.log('xml:', xml);

      // Removing the name space as IE throws an error
      // xml = xml.replace(/xmlns=\"http:\/\/www\.w3\.org\/2000\/svg\"/, '');
      // console.log('xml:', xml);

      //draw the SVG onto a canvas

      // new Canvg(ctx, origSvgElement);
      // new Canvg(ctx, xml);
      // await Canvg.from(ctx, xml);

      // $(canvas).insertAfter(origSvgElement);
      // insertAfter(origSvgElement, canvas);
      // insertAfter(origSvgElement, image);
      // insertBefore(origSvgElement, canvas);
      // console.log('inserted');

      // $(this).hide();
      //   doneCount++
      //   if (doneCount >= svgElements.length) {
      //     console.log(`done: doneCount: ${doneCount}`)
      //     return callback();
      //   }
    });
    // callback(); //to be called after the process in finished
  });
};

//

async function createJsPdfDoc(selector: string): Promise<jsPDF> {
  return new Promise((resolve, reject) => {
    const domContent = document.querySelector(selector);
    const htmlContent = domContent
      ? domContent.innerHTML
      : `Unable to get ${selector} from document`;
    console.log('domContent:', domContent);

    if (!domContent) {
      const msg = `Element with selector ${selector} not found`;
      console.error(msg);
      reject(new Error(msg));
    }

    console.log('Preparing...');
    // var doc = new jsPDF();
    // var doc = new jsPDF('portrait', 'pt', 'a4');
    var doc = new jsPDF('p', 'pt', [(totalWidth * 297) / 210, totalWidth]);

    // doc.addFileToVFS('Montserrat-Regular-normal.js', MyFont); // addfont
    // doc.addFont('Montserrat-Regular-normal.js', 'MyFont', 'normal');
    console.log('getFontList():', doc.getFontList());

    // doc.setFont('Montserrat-Regular-normal.ttf');
    // doc.setFont('Montserrat-Regular');
    doc.setFont('Montserrat', 'normal');

    // doc.html(document.body, {
    console.log('Converting HTML to PDF...');
    doc.html(domContent as HTMLElement, {
      callback: function (doc) {
        console.log('PDF is ready.');
        resolve(doc);
      },
      x: 10,
      y: 10,
      margin: 75,
    });
  });
}

export async function saveHtmlAsPdf(
  selector: string /*, callback: (error?:Error)=>void */,
): Promise<void> {
  console.log('savePdf');

  const doc = await createJsPdfDoc(selector);
  console.log('Saving PDF...');

  await doc.save('big-data-survey.pdf', { returnPromise: true } ); // 'sample-file.pdf'
  console.log('Done...');
}

function openDocInNewWindow9(doc: jsPDF, title?: string): boolean {
  if (title) doc.setProperties({ title });
  //   pdf.setProperties({
  //     title: 'PDF Title',
  //     subject: 'Info about PDF',
  //     author: 'PDFAuthor',
  //     keywords: 'generated, javascript, web 2.0, ajax',
  //     creator: 'My Company'
  // });

  // doc.output('dataurlnewwindow');

  // const blob = doc.output();
  // window.open(URL.createObjectURL(blob));

  const x = doc.output('pdfobjectnewwindow');
  // const x = doc.output('pdfjsnewwindow');
  // window.open(x);

  return true;
}

function openDocInNewWindow5(doc: jsPDF, title?: string): boolean {
  var s = doc.output('datauristring');
  var x = window.open();
  if (!x) {
    return false;
  } else {
    x.document.open();
    x.document.location.href = s;
    return true;
  }
}

//
// https://github.com/MrRio/jsPDF/issues/1969
//
function openDocInNewWindow4(doc: jsPDF, title?: string): boolean {
  title = title || 'Отчет в формате PDF';

  doc.setProperties({
    title,
    // subject: 'Info about PDF',
    // author: 'PDFAuthor',
    // keywords: 'generated, javascript, web 2.0, ajax',
    // creator: 'My Company'
  });
  const pdfDataUriString = doc.output('datauristring');
  const embed =
    "<embed width='100%' height='100%' src='" +
    pdfDataUriString +
    // "&displayName=pdfreport.pdf"+
    // "#filename=MY_CUSTOM_FILENAME.pdf"+
    "'/>";

  // const embed = `<object
  //   data="${pdfDataUriString}#filename=MY_CUSTOM_FILENAME.pdf"+
  //   type="application/pdf"
  //   width="100%"
  //   height="100%"
  // >`;

  const wnd = window.open();
  if (!wnd) {
    return false;
    // const msg = 'Unable to open new window';
    // console.error(msg);
    // return reject(new Error(msg));
  }
  wnd.document.open();
  wnd.document.write(embed);
  wnd.document.title = title || 'Отчет в формате PDF';
  wnd.document.close();
  //

  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(pdfDataUriString);
  }, 100);

  // return resolve();
  return true;
}

function openDocInNewWindow(doc: jsPDF, title?: string): boolean {
  if (title) doc.setProperties({ title });
  const pdfDataUriString = doc.output('datauristring');

  const blobPdf = doc.output('blob');

  // const part = doc.output();
  // var blobPDF = new Blob([part], { type: 'application/pdf' });

  var blobUrl = URL.createObjectURL(blobPdf);

  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  var newBlob = new Blob([blobPdf], { type: 'application/pdf' });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return true;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  // const data = window.URL.createObjectURL(newBlob);
  const data = window.URL.createObjectURL(blobPdf);

  // const data = doc.output('')

  var link = document.createElement('a');
  link.href = data;
  // link.download = 'file.pdf'; // download is triggered if set
  link.target = `_blank`;
  link.rel = `noopener noreferrer`;

  // link.displayName="pdfreport.pdf"
  // link.filename="MY_CUSTOM_FILENAME.pdf"
  // link.

  link.click();
  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
  return true;
}

export async function openInNewWindow(
  selector: string /*, callback: (error?:Error)=>void */,
  title?: string,
): Promise<void> {
  console.log('openInNewWindow');

  const doc = await createJsPdfDoc(selector);
  if (!openDocInNewWindow(doc)) {
    const msg = 'Unable to open new window';
    console.error(msg);
    throw new Error(msg);
  }
}

export async function pdfToEmail(
  selector: string /*, callback: (error?:Error)=>void */,
  onProgress: OnProgressHandler,
  title?: string,
): Promise<void> {
    console.log('pdfToEmail');
    const doc = await createJsPdfDoc(selector);

    const backendHttp = new BackendHttp();
    await backendHttp.postPdfToEmail(doc, onProgress);

}
