import React  from 'react';
import {Loading} from "../basic/Loading";
import {NotReady} from "../basic/NotReady";

import {AspectsSummary} from '../reports/AspectsSummary';
import {GenericReportPage} from "./GenericReportPage";
import {  Surveys, } from '../../lib/surveys'

const totalWidth = 1200;
const sv_body_padding = 14;

interface ClickEvent { (e: React.MouseEvent<HTMLInputElement, MouseEvent>): void; }

interface AspectsSummaryPageProps {
  surveyId: string
  surveys: Surveys
  // name: string
  // surveyState: SurveyState
  onBack: ClickEvent
  onNext: ClickEvent
}


const ButtonBack = ({ onBack }: { onBack: ClickEvent }) => (
  <input className="sv_prev_btn" type="button" value="Назад" style={{ marginRight: '5px' }}
         onClick={ (e) => {
           onBack(e);
         }}
  />
)

const ButtonNext = ({ onNext }: { onNext: ClickEvent }) => (
  <input className="sv_next_btn" type="button" value="Продолжить" style={{ marginRight: '5px' }}
         onClick={ (e) => {
           onNext(e);
         }}
  />
)


export function AspectsSummaryPage(props: AspectsSummaryPageProps) {
  console.log('AspectsSummaryPage props:', props)
  const { surveyId, surveys, onBack, onNext } = props;
  if (surveys.state !== 'ready') return <NotReady state={surveys.state} />;

  const footer = <div className='sv_nav'>
    <ButtonBack onBack={onBack} />
    <ButtonNext onNext={onNext} />
  </div>;

  return (
    <GenericReportPage id="AspectsSummaryPage" footer={footer} >

        <AspectsSummary
          surveyId={surveyId}
          surveys={surveys}
          // name={surveyName}
          // survey={surveyState}
        />

    </GenericReportPage>
  );
}
