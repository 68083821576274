import React  from 'react';

import {totalWidth} from '../../lib/const'


interface GenericPageProps {
  id: string
  children: React.ReactNode
}


export function GenericPage(props: GenericPageProps) {
  console.log('GenericPage props:', props)
  const { id, children } = props;

  return (
    <div
      id={id}
      style={{ display: 'block', maxWidth: `${totalWidth}px`, margin: '0 auto', }}
      className='sv_main sv_default_css'
    >
      <div className='sv_container '>
        <div className='sv_body'
          //style={{ maxWidth: `${totalWidth}px`, margin: '0 auto', }}
        >

          { children }

        </div>

      </div>
    </div>
  );
}
