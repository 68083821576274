/** @format */

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import {
  faFilePdf,
  faFileDownload,
  faPaperPlane,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import {
  replaceSVGwithCanvas,
  saveHtmlAsPdf,
  openInNewWindow,
  pdfToEmail,
} from '../../lib/pdfReport';

export const ReportPanel = ({ selector }: { selector: string }) => {
  const [progress, setProgress] = useState(false);

  function mySetProgress(value: boolean) {
    document.body.style.cursor = value ? 'wait' : 'default';
    setProgress(value);
  }

  async function handlePrepareClick(): Promise<void> {
    mySetProgress(true);
    const t = toast('Документ формируется. Подождите, пожалуйста.', {
      autoClose: false,
    });
    try {
      await replaceSVGwithCanvas(selector);
      mySetProgress(false);
    } finally {
      toast.dismiss(t);
    }
    return;
  }

  async function handleSaveClick(): Promise<void> {
    mySetProgress(true);
    const t = toast('Документ формируется. Подождите, пожалуйста.', {
      autoClose: false,
    });
    try {
      await replaceSVGwithCanvas(selector);
      await saveHtmlAsPdf(selector);
    } finally {
      toast.dismiss(t);
      mySetProgress(false);
      // if (err) return console.error('handleSaveClick', err)
      // return console.log('handleSaveClick(): done')
    }
  }

  async function handleOpenClick(): Promise<void> {
    mySetProgress(true);

    const t = toast('Документ формируется. Подождите, пожалуйста.', {
      autoClose: false,
    });
    try {
      await replaceSVGwithCanvas(selector);
      await openInNewWindow(selector);
    } finally {
      toast.dismiss(t);
      mySetProgress(false);
      // if (err) return console.error('handleOpenClick', err)
      // return console.log('handleOpenClick(): done')
    }
  }

  async function handleSendClick(): Promise<void> {
    mySetProgress(true);
    const t = toast('Документ формируется. Подождите, пожалуйста.', {
      autoClose: false,
    });
    try {
      await replaceSVGwithCanvas(selector);
      await pdfToEmail(selector, ({ progress, loaded, total }) => {
        toast.update(t, { progress });
      });
      toast('Сообщение успешно отправлено.');
    } finally {
      toast.dismiss(t);
      mySetProgress(false);
      // if (err) return console.error('handleOpenClick', err)
      // return console.log('handleOpenClick(): done')
    }
  }

  return (
    <div>
      {/*
      <button onClick={ handlePrepareClick } disabled={progress} >
        Prepare
      </button>
      &nbsp;
      */}
      <button onClick={handleSaveClick} disabled={progress}>
        <FontAwesomeIcon
          // icon={faFilePdf}
          icon={faFileDownload}
          // style={{ color: 'darkgreen', margin: '0 5px' }}
          style={{ margin: '0 6px 0 0' }}
        />
        Сохранить PDF
      </button>
      &nbsp;
      <button onClick={handleOpenClick} disabled={progress}>
        <FontAwesomeIcon
          icon={faExternalLinkAlt}
          // style={{ color: 'darkgreen', margin: '0 5px' }}
          style={{ margin: '0 6px 0 0' }}
        />
        Открыть PDF в новой вкладке
      </button>
      &nbsp;
      <button
        onClick={handleSendClick}
        disabled={progress}
        style={{ float: 'right' }}
      >
        <FontAwesomeIcon
          icon={faPaperPlane}
          // style={{ color: 'darkgreen', margin: '0 5px' }}
          style={{ margin: '0 6px 0 0' }}
        />
        Отправить на почту организации
      </button>
    </div>
  );
};
