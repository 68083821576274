import React from 'react'

export const Notfound = ({ message }: { message?: string }) => (
    <h1>
    {
      message
        ? message
        : "Страница не найдена"
    }
    </h1>
)
