/** @format */

import React, { PropsWithChildren, useState, useEffect } from 'react';
import { BackendHttp } from '../../../services/BackendHttp';

import {
  BASE_JSON_API_URL,
  FINAL_REPORT_PATH,
  INTERMEDIATE_DETAILED_REPORT_PATH,
  JSON_API_URL,
  REPORT_CHOOSE_SURVEYS_PATH,
} from '@shared/config';
import { getIsAdmin } from '../../../lib/utils';
import { Link, MiniLink, MiniLinkStyle } from '../../basic/Link';

export const ReportLinks = ({
  isFinalEnabled,
  isIntermediateEnabled,
}: {
  isFinalEnabled: boolean;
  isIntermediateEnabled: boolean;
}) => {
  async function handleJsonClickOld(event) {
    window.open(BASE_JSON_API_URL, '_blank').focus();
  }

  async function handleJsonClick(event) {
    event = event || window.event;
    const backendHttp = new BackendHttp();
    const json = await backendHttp.getAllJson();

    const strJson = JSON.stringify(json, null, 2);
    // console.log(strJson);

    const w = window.open();
    w.document.open();
    w.document.write('<html><body><pre>' + strJson + '</pre></body></html>');
    w.document.close();

    // add a load listener to the window so that the title gets changed on page load
    w.addEventListener('load', function () {
      w.document.title = 'Экспорт в формате JSON';
    });

    // stop the default `a` link or you will get 2 new windows!
    event.returnValue = false;
  }

  return (
    <React.Fragment>
      {isFinalEnabled && isIntermediateEnabled ? <p>Отчеты:</p> : null}

      <ul>
        {isIntermediateEnabled ? (
          <React.Fragment>
            <li style={{ display: 'block' }}>
              <Link
                to={REPORT_CHOOSE_SURVEYS_PATH}
                style={{ fontWeight: 'bold' }}
                disabled={!isIntermediateEnabled}
              >
                Промежуточный отчет (суммарный)
              </Link>
            </li>
            <li style={{ display: 'block' }}>
              <Link
                to={INTERMEDIATE_DETAILED_REPORT_PATH}
                disabled={!isIntermediateEnabled}
              >
                Промежуточный отчёт (детальный, по всем аспектам размерностей)
              </Link>
            </li>
          </React.Fragment>
        ) : null}

        {isFinalEnabled ? (
          <li style={{ display: 'block' }}>
            <Link
              to={FINAL_REPORT_PATH}
              style={{ fontWeight: 'bold' }}
              disabled={!isFinalEnabled}
            >
              Итоговый отчет
            </Link>
          </li>
        ) : null}

        <a
          href="#"
          style={{
            display: getIsAdmin() ? 'block' : 'none',
            ...MiniLinkStyle,
          }}
          onClick={handleJsonClick}
          // disabled={disabled}
        >
          [Экспорт всех данных в JSON]
        </a>
      </ul>

      {!isIntermediateEnabled ? (
        <p>
          Для формирования промежуточного отчета необходимы ответы, как минимум,
          на три раздела опроса.
        </p>
      ) : !isFinalEnabled ? (
        <p>
          Для формирования итогового отчета необходимы ответы на все разделы
          опроса, включая информацию о компании.
        </p>
      ) : null}
    </React.Fragment>
  );
};
