/** @format */

export const eventBus = {
  on(event: string, callback: EventListener) {
    document.addEventListener(
      event,
      ((event: CustomEvent) => {
        callback(event.detail);
      }) as EventListener,
    );
  },
  dispatch(event: string, data?: any): void {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event: string, callback: () => void): void {
    document.removeEventListener(event, callback);
  },
};

export default eventBus;
