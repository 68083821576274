/** @format */

import React from 'react';

import { LocalizableString } from '@shared/types/SurveyQuestions';
import {limitStr} from '../../../../lib/utils'

export function Title({ title }: { title: LocalizableString }) {
  return (
    <span>
      {!title
        ? ''
        : typeof title === 'string'
        ? limitStr(title)
        : typeof title.ru === 'string'
        ? limitStr(title.ru)
        : ''}
    </span>
  );
}

export default Title;
