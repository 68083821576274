/** @format */

import React, { PropsWithChildren, useState, useEffect } from 'react';
import JSONSchemaForm from '@rjsf/semantic-ui';
import {
  JSONSchema6,
  JSONSchema7,
  JSONSchema7Array,
  JSONSchema7Definition,
  JSONSchema7Object,
} from 'json-schema';

import Surveys_uiSchema from '@shared/schemas-ui/Surveys.ui-schema';
import SurveysSchema from '@shared/schemas/Surveys.schema';

import { Surveys as SurveysObject } from '../../../lib/surveys';
import { GenericForm } from './components/GenericForm';

export const AdminEditSurveyQuestion = (props: {
  surveys: SurveysObject;
  surveyId: string;
  questionId: string;
  history;
}) => {
  console.log('SurveyEditAspectsQuestion props:', props);
  const { surveys, surveyId, questionId } = props;

  const allQuestions = surveys.allSurveyQuestionsDefinitions?._data;
  const allDefinitions = surveys.allSurveyDefinitions?._data;

  const surveyQuestions = allQuestions?.[surveyId];
  const surveyDefinitions = allDefinitions?.find(
    (e) => e.surveyId === surveyId,
  );

  let formData;
  let schema;
  let uiSchema;
  if (surveyQuestions?._type === 'survey-js') {
    formData = surveyQuestions?.pages?.[0]?.elements?.find(
      (el) => el.name === questionId,
      )
     schema = SurveysSchema.properties?.pages?.items?.properties?.elements
      ?.items as JSONSchema7;
    //
    // copy definitions from root level to a new root
    // though we don't use them as they are have limited support by rjsf
    //
    // schema.definitions = SurveysSchema.definitions as { [key: string]: JSONSchema7Definition };
     uiSchema = Surveys_uiSchema.pages?.items?.elements?.items;
  // } else {
    
  }


  return (
    <GenericForm
      state={surveys.state}
      header1={`Редактирование формы "${surveyDefinitions?.name}"`}
      formData={formData}
      schema={schema}
      uiSchema={uiSchema}
      history={props.history}
      onSubmit={async (submitEvent) => {
        console.log('onSubmit: allQuestions', allQuestions, submitEvent);
        await surveys.allSurveyQuestionsDefinitions.save();
      }}
    />
  );
};
