/** @format */

import React, { PropsWithChildren, useState, useEffect } from 'react';
import {
  JSONSchema7,
} from 'json-schema';

import AllSurveys_uiSchema from '@shared/schemas-ui/AllSurveys.ui-schema';
import AllSurveysSchema from '@shared/schemas/AllSurveys.schema';

import { Surveys as SurveysObject } from '../../../lib/surveys';
import {GenericForm} from "./components/GenericForm";

export const AdminEditSurveyAspect = (props: {
  surveys: SurveysObject;
  surveyId: string;
  aspectId: string;
  history
}) => {
  console.log('AdminListSurveyAspect props:', props);
  const { surveys, surveyId, aspectId } = props;

  const allQuestions = surveys.allSurveyQuestionsDefinitions?._data;
  const allDefinitions = surveys.allSurveyDefinitions?._data;

  const surveyQuestions = allQuestions?.[surveyId];
  const surveyDefinitions = allDefinitions?.find(e => e.surveyId === surveyId);

  const formData = surveyDefinitions?.aspectWeights?.find(aw => aw.id === aspectId);

  const schema = AllSurveysSchema.items.properties.aspectWeights
    .items as JSONSchema7;
  const uiSchema = AllSurveys_uiSchema.items.aspectWeights.items;

  return (
    <GenericForm
      state={surveys.state}
      header1={`Редактирование аспектов и правил их вычисления размерности "${surveyDefinitions?.name}"`}
      formData={formData}
      schema={schema}
      uiSchema={uiSchema}
      history={props.history}
      onSubmit={async (submitEvent) => {
        console.log('onSubmit: allDefinitions', allDefinitions, submitEvent);
        await surveys.allSurveyDefinitions.save();
      }}
    />
  );
};
