/** @format */

import React from 'react';
import { AjvError, ErrorListProps, UiSchema } from '@rjsf/core';
import JSONSchemaForm from '@rjsf/semantic-ui';
import { JSONSchema7 } from 'json-schema';
import { toast } from 'react-toastify';
import {LoadingState} from "../../../../lib/surveys";
import {Notfound} from "../../../basic/Notfound";
import {NotReady} from "../../../basic/NotReady";

import { customFields, customWidgets } from '../../../rjsf';
import FormButtons from './FormButtons';

import { ErrorListTemplate } from '../../../rjsf/components/ErrorListTemplate';
import { transformErrors } from '../../../rjsf/helpers/transformErrors';

export const GenericForm = (props: {
  state: LoadingState;
  header1?: string;
  header2?: string;
  formData: (()=> any) | any;
  schema: (()=> JSONSchema7) | JSONSchema7;
  uiSchema: (()=> UiSchema) | UiSchema;
  // transformErrors?: (errors: AjvError[]) => AjvError[];
  // ErrorList?: React.StatelessComponent<ErrorListProps>;
  history;
  onSubmit;
  // onComplete?: () => void;
}) => {
  const {
    state,
    header1,
    header2,
    formData,
    schema,
    uiSchema,
    // transformErrors,
    // ErrorList,
    history,
    onSubmit,
    ...rest
  } = props;
  console.log('GenericForm props:', props);
  if (state !== 'ready') return <NotReady state={state} />;
  if (!formData) return <Notfound message="Данные для формы не найдены" />;

  return (
    <div className=" ui main container">
      <header className="ui container">
        <h2 className="ui center aligned header">{header1}</h2>
        <h3 className="ui center aligned header">{header2}</h3>
      </header>
      <div className="ui container segment">
        {formData ? (
          <JSONSchemaForm
            formData={typeof formData === 'function' ? formData() : formData}
            schema={typeof schema === 'function' ? schema() : schema}
            uiSchema={typeof uiSchema === 'function' ? uiSchema() : uiSchema}
            transformErrors={transformErrors}
            ErrorList={ErrorListTemplate}
            formContext={{
              'ui:options': {
                semantic: {
                  wrapLabel: true,
                  wrapContent: true,
                },
              },
            }}
            widgets={customWidgets}
            fields={customFields}
            // FieldTemplate={CustomFieldTemplate}
            onSubmit={async (submitEvent) => {
              console.log(
                'GenericForm: JSONSchemaForm: onSubmit: submitEvent',
                submitEvent,
              );
              console.log(
                'GenericForm: JSONSchemaForm: onSubmit:',
                formData,
                submitEvent.formData,
              );

              Object.assign(formData, submitEvent.formData);

              // onComplete && onComplete();

              const t = toast.info('Сохранение данных...', {
                autoClose: false,
              });
              try {
                const res = await onSubmit(submitEvent);
                toast.success('Данные успешно сохранены.');
                return res;
              } catch (e) {
                console.error(e);
                toast.error('Ошибка сохранения данных.');
              } finally {
                toast.dismiss(t);
              }
              return;
            }}
            liveValidate
            noHtml5Validate={false}
          >
            <FormButtons history={history} />
          </JSONSchemaForm>
        ) : null}
      </div>
    </div>
  );
};
