/** @format */

import { JSONSchema7 } from 'json-schema';
import React, { PropsWithChildren, useState, useEffect } from 'react';
import { UiSchema } from '@rjsf/core';

import AllSurveys_uiSchema from '@shared/schemas-ui/AllSurveys.ui-schema';
import AllSurveysSchema from '@shared/schemas/AllSurveys.schema';
import { ReactJsonFormDefinition } from '@shared/types/SurveyQuestions';

import { Surveys as SurveysObject } from '../../lib/surveys';
import { BackendHttp } from '../../services/BackendHttp';
import { Notfound } from '../basic/Notfound';
import { GenericForm } from './admin/components/GenericForm';
import { ErrorListTemplate } from '../rjsf/components/ErrorListTemplate';
import { transformErrors } from '../rjsf/helpers/transformErrors';
/*
import { JsonForms } from '@jsonforms/react';
import { JsonFormsStyleContext } from '@jsonforms/vanilla-renderers';

import { person } from '@jsonforms/examples';
import {
  vanillaRenderers,
  vanillaCells,
  vanillaStyles,
} from '@jsonforms/vanilla-renderers';
// import {
//   materialRenderers,
//   materialCells,
// } from '@jsonforms/material-renderers';
// import '@material-ui/core';
// import '@material-ui/icons';

const styleContextValue = {
  styles: [
    ...vanillaStyles,
    {
      name: 'control',
      classNames: ['sv_q', 'sv_qstn'],
    },
    {
      name: 'control.label',
      classNames: ['sv_q_title'],
    },
    {
      name: 'control.input-description',
      classNames: ['sv_q_title'],
    },
    // {
    //   name: 'control.input',
    //   classNames: ['custom-input'],
    // },
    // {
    //   name: 'array.button',
    //   classNames: ['custom-array-button'],
    // },
  ],
};
*/

export const OrgSurveyPage = (props: {
  surveys: SurveysObject;
  surveyId: 'org';
  history;
  // onComplete?: () => void;
}) => {
  console.log('OrgSurveyPage props:', props);
  const { surveys, surveyId, history /*, onComplete*/ } = props;

  // const [data, setData] = useState({});

  // useEffect(() => {
  //   const initialData = surveys.getSurvey('org')?.data;
  //   setData(initialData);
  //   //person.data;
  // });
  //
  // const uischema = {
  //   type: 'VerticalLayout',
  //   elements: [
  //     {
  //       type: 'Label',
  //       text: 'Введите сведения об организации',
  //     },
  //     {
  //       type: 'Control',
  //       scope: '#/properties/inn',
  //       label: 'ИНН организации',
  //     },
  //     {
  //       type: 'HorizontalLayout',
  //       elements: [
  //         {
  //           // type: 'Control',
  //           scope: '#/properties/size',
  //           label: 'Размер организации',
  //         },
  //         {
  //           type: 'Control',
  //           scope: '#/properties/okved',
  //           label: 'Основной вид экономической деятельности организации',
  //         },
  //       ],
  //     },
  //     {
  //       type: 'Control',
  //       scope: '#/properties/email',
  //       label: 'Адрес электронной почты организации',
  //     },
  //   ],
  // }; //person.uischema;
  //
  // const config = { showUnfocusedDescription: true };
  //
  // return (
  //   <div className="sv_main">
  //     <div className="sv_container">
  //       <div className="sv_body">
  //         <div className="sv_p_root">
  //           <div className="sv_q">
  //             <JsonFormsStyleContext.Provider value={styleContextValue}>
  //               <JsonForms
  //                 schema={schema}
  //                 uischema={uischema}
  //                 data={data}
  //                 config={config}
  //                 renderers={vanillaRenderers}
  //                 // renderers={materialRenderers}
  //                 cells={vanillaCells}
  //                 onChange={({ data, errors }) => {
  //                   console.log('errors:', errors);
  //                   console.log('data:', data);
  //                   setData(data);
  //                 }}
  //               />
  //             </JsonFormsStyleContext.Provider>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );

  //////////////////////////////////////////////////////////////////////////////

  const allQuestions = surveys.allSurveyQuestionsDefinitions?._data;
  const allDefinitions = surveys.allSurveyDefinitions?._data;

  const surveyQuestions = allQuestions?.[surveyId] as ReactJsonFormDefinition;
  const surveyDefinitions = allDefinitions?.find(
    (e) => e.surveyId === surveyId,
  );

  surveys.sanitizeSurvey(surveyId)

  const formData = surveys.getSurvey(surveyId)?.data;

  const { schema, uiSchema } = surveyQuestions || {};

  // const getOrgSurvey = () => {
  //   const allQuestions = surveys.allSurveyQuestionsDefinitions?._data;
  //   const allDefinitions = surveys.allSurveyDefinitions?._data;
  //
  //   const surveyQuestions = allQuestions?.[surveyId];
  //   const surveyDefinitions = allDefinitions?.find(
  //     (e) => e.surveyId === surveyId,
  //   );
  //   const formData = surveys.getSurvey('org')?.data;
  //   const { schema, uiSchema } =
  //     (surveyQuestions as ReactJsonFormDefinition) || {};
  //   return { formData, schema, uiSchema };
  // };

  // const getFormData = () => surveys.getSurvey(surveyId)?.data;
  // const getSchema = () =>
  //   (surveys.allSurveyQuestionsDefinitions?._data?.allQuestions?.[
  //     surveyId
  //   ] as ReactJsonFormDefinition)?.schema;

  return (
    <GenericForm
      state={surveys.state}
      formData={formData}
      schema={schema as JSONSchema7}
      uiSchema={uiSchema}
      history={props.history}
      onSubmit={async (submitEvent) => {
        surveys.setCompleted(surveyId, true);
        console.log('onSubmit: surveys:', surveys, submitEvent);
        await surveys.save();
      }}
      // onComplete={onComplete}
    />
  );
};
