/** @format */

import { JSONSchema } from '@typescript-eslint/experimental-utils';
import React, { PropsWithChildren, useState, useEffect } from 'react';
import JSONSchemaForm from '@rjsf/semantic-ui';
import {
  JSONSchema6,
  JSONSchema7,
  JSONSchema7Array,
  JSONSchema7Definition,
  JSONSchema7Object,
} from 'json-schema';

import AllSurveys_uiSchema from '@shared/schemas-ui/AllSurveys.ui-schema';
import AllSurveysSchema from '@shared/schemas/AllSurveys.schema';

import { Surveys as SurveysObject } from '../../../lib/surveys';
import { BackendHttp } from '../../../services/BackendHttp';
import { Loading } from '../../basic/Loading';
import { Notfound } from '../../basic/Notfound';
import { LocalizableString } from '@shared/types/SurveyQuestions';
import { NotReady } from '../../basic/NotReady';
import { customFields, customWidgets } from '../../rjsf';
import FormButtons from './components/FormButtons';
import { GenericForm } from './components/GenericForm';

export const AdminEditSurveyLevel = (props: {
  surveys: SurveysObject;
  surveyId: string;
  levelId: string;
  history;
}) => {
  console.log('AdminEditSurveyLevel props:', props);
  const { surveys, surveyId, levelId } = props;

  const allQuestions = surveys.allSurveyQuestionsDefinitions?._data;
  const allDefinitions = surveys.allSurveyDefinitions?._data;

  const surveyQuestions = allQuestions?.[surveyId];
  const surveyDefinitions = allDefinitions?.find(
    (e) => e.surveyId === surveyId,
  );

  const formData = surveyDefinitions?.maturityLevels?.[Number(levelId) - 1];

  const schema = AllSurveysSchema.items?.properties?.maturityLevels
    ?.items as JSONSchema7;
  const uiSchema = AllSurveys_uiSchema.items.maturityLevels.items;

  return (
    <GenericForm
      state={surveys.state}
      header1={`Редактирование описания уровней зрелости размерности "${surveyDefinitions?.name}"`}
      formData={formData}
      schema={schema}
      uiSchema={uiSchema}
      history={props.history}
      onSubmit={async (submitEvent) => {
        // Object.assign(formData, submitEvent.formData);
        console.log('onSubmit: allDefinitions', allDefinitions, submitEvent);
        await surveys.allSurveyDefinitions.save();
      }}
    />
  );
};
