/** @format */

import { ArrayFieldTemplateProps } from '@rjsf/core';
import { JSONSchema7 } from 'json-schema';
import React, { useState, useEffect, useRef, useCallback } from 'react';

import JSONSchemaForm, {
  UiSchema,
  WidgetProps,
  FieldProps,
  FieldTemplateProps,
} from '@rjsf/semantic-ui';
//
import StringField from '@rjsf/core/lib/components/fields/StringField';
import ArrayField from '@rjsf/core/lib/components/fields/ArrayField';
//
import { getDefaultRegistry } from '@rjsf/core/lib/utils';

import { TitleField } from './fields/TitleField';
//
import { InlineNumberWidget } from './widgets/InlineNumberWidget';
import { InlineSelectWidget } from './widgets/InlineSelectWidget';
import { InlineTextWidget } from './widgets/InlineTextWidget';
import { InlineTextareaWidget } from './widgets/InlineTextareaWidget';
import { InlineEmailWidget } from './widgets/InlineEmailWidget';
import { InlineRadioWidget } from './widgets/InlineRadioWidget';
import { InlineCodeWidget } from './widgets/InlineCodeWidget';

import { SearchWidget } from './widgets/SearchWidget';

import { TestWidget } from './widgets/TestWidget';

import './rjsf.scss';

const MyCustomWidget_ = (props: WidgetProps) => {
  return (
    <div>
      <input
        type="text"
        className="custom"
        value={props.value}
        required={props.required}
        onChange={(event) => props.onChange(event.target.value)}
      />
    </div>
  );
};

const CustomField = function (props: FieldProps) {
  return (
    /*     <button id="custom" className={props.value ? "checked" : "unchecked"} onClick={() => props.onChange(!props.value)}>
        {String(props.value)}
    </button>
 */
    <div>
      111
      <StringField {...props} />
      222
    </div>
  );
};

// const LABEL_WIDTH = 200;
// const LABEL_STYLE = {
//   flex: `0 1 ${LABEL_WIDTH}px`,
//   // alignSelf: 'center',
// };

function ObjectFieldTemplate(props: {
  title: string;
  description: string;
  properties: any;
}) {
  return (
    <div>
      {props.title}
      {props.description}
      {props.properties.map((element: any) => (
        <div className="property-wrapper">{element.content}</div>
      ))}
    </div>
  );
}

function CustomFieldTemplate(props: FieldTemplateProps) {
  const {
    id,
    classNames,
    label,
    help,
    required,
    description,
    errors,
    children,
  } = props;
  return (
    <div className={classNames}>
      555
      {/*<Label {...props} />*/}
      {/* {description} */}
      {children}
      {errors}
      {help}
      666
    </div>
  );
}

function PatchedArrayField(this: any, props: FieldProps) {
  return ArrayField.call(this, props);
}
PatchedArrayField.prototype = Object.create(ArrayField.prototype);

const orig = PatchedArrayField.prototype.renderArrayFieldItem;
console.log('CustomArrayField: orig:', orig);

PatchedArrayField.prototype.renderArrayFieldItem = function (
  props: FieldProps,
) {
  console.log('CustomArrayField: renderArrayFieldItem:', props);
  const OrigResult = orig.call(this, props);
  // orig.apply(this, arguments);
  return (
    <div style={{ color: 'red' }}>
      111
      {OrigResult}
      222
    </div>
  );
};

console.log(
  'CustomArrayField: replaced:',
  PatchedArrayField.prototype.renderArrayFieldItem,
);

export function CustomArrayField(props: FieldProps) {
  const {
    id,
    classNames,
    label,
    help,
    required,
    description,
    errors,
    children,
  } = props;

  console.log('CustomArrayField: props:', props);

  return (
    <div className={classNames}>
      555
      <PatchedArrayField {...props} />
      {/*       <Label {...props} /> */}
      {/* {description} */}
      {/*{children}*/}
      {/*{errors}*/}
      {/*{help}*/}
      666
    </div>
  );
}

const defaultWidgets = getDefaultRegistry().widgets;
export const customWidgets = {
  // ...defaultWidgets,
  'inline-text-widget': InlineTextWidget,
  'inline-number-widget': InlineNumberWidget,
  CodeWidget: InlineCodeWidget,
  EmailWidget: InlineEmailWidget,
  RadioWidget: InlineRadioWidget,

  // Добавим свой виджет, но не будем изменять виджет по умолчанию,
  // чтобы заблокировать его в стилях, т.к. для oneOf это единственная возможность сделать его read-only
  InlineSelectWidget: InlineSelectWidget,
  // SelectWidget: InlineSelectWidget,
  //

  SearchWidget: SearchWidget,
  TextWidget: InlineTextWidget,
  TextareaWidget: InlineTextareaWidget,
  UpDownWidget: InlineNumberWidget,
  testWidget: TestWidget,
};

const defaultFields = getDefaultRegistry().fields;
export const customFields = {
  TitleField
//   ...defaultFields,
  // myCustomField: CustomField,
};
