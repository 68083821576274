/** @format */

import React from 'react';
import {
  Tooltip,
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  BarChart /*, YAxis*/,
} from 'recharts';

import {
  animationDuration,
  axisTicks,
  gridColor,
  strokeDasharray,
  fillColor,
  fillOpacity,
  AspectsBarChartProps,
  radarChartWidth,
  radarChartHeight,
  radarChartDiameter,
} from './defaults';
import { MAX_LEVEL } from '../../lib/const';
import { ChartDataPoint } from '../../lib/calc';
import { CustomTooltip } from './CustomTooltip';
import { CustomPolarRadiusAxisTick } from './Tick';

import './AspectsRadarChart.scss';


export function AspectsRadarChart({ data }: AspectsBarChartProps) {
  console.log('AspectsRadarChart', data);
  return (
    <RadarChart
      cx={'50%'}
      cy={'50%'}
      outerRadius={radarChartDiameter / 2}
      width={radarChartWidth}
      height={radarChartHeight}
      data={data}
      // animationDuration={animationDuration}
      className={'AspectsRadarChart'}
    >
      <PolarGrid stroke={gridColor} strokeDasharray={strokeDasharray} />
      <PolarAngleAxis dataKey="name" tick={axisTicks} />
      <PolarRadiusAxis
        type={'number'}
        angle={0}
        domain={[0, MAX_LEVEL]}
        scale={'linear'}
        // tick={axisTicks}
        tick={<CustomPolarRadiusAxisTick />}
      />
      <Radar
        name="Значение"
        dataKey="value"
        // stroke={fillColor}
        fill={fillColor}
        fillOpacity={fillOpacity}
        animationDuration={animationDuration}
      />
      {/*<Tooltip />*/}
      <Tooltip content={<CustomTooltip />} />
    </RadarChart>
  );
}
