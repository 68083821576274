/** @format */

import { UiSchema } from '@rjsf/core';

const uiSchema: UiSchema = {
  // 'ui:ObjectFieldTemplate': ObjectFieldTemplate,
  'ui:options': {
    semantic: {
      class_: 'custom-root', // <h5>
      wrapItem: true,
      horizontalButtons: true,
      fluid: true,
      // inverted: false,
      // errorOptions: {
      //   size: 'small',
      //   pointing: 'above',
      // },
    },
  },
  pages: {
    items: {
      'ui:options': {
        semantic: {
          fluid: true,
          wrapItem: true,
          // horizontalButtons: true,
        },
      },
      name: {
        'ui:options': {
          semantic: {
            fluid: true,
            wrapItem: true,
            // horizontalButtons: true,
          },
        },
      },
      title: {
        // 'ui:widget': 'inline-text-widget',
        'ui:options': {
          semantic: {},
        },
      },
      elements: {
        // 'ui:widget': 'testWidget',
        'ui:options': {
          semantic: {
            fluid: true,
            wrapItem: true,
            // horizontalButtons: true,
          },
        },
        items: {
          'ui:options': {
            semantic: {
              fluid: true,
              wrapItem: true,
              // horizontalButtons: true,
            },
          },
          // так как элементы объявлены с использованием oneOf, в верхней части
          // формы отображается выпадающий список доступных типов. Список
          // заблокирован в rjsf.scss
          type: {
            'ui:disabled': true,
            'ui:widget': 'hidden', // скрыть тип, так как он уже выведен в дроп-даун выше
            semantic: {
              class_: 'type-custom-root', // <h5>
            },
          },
          oneOf: {
            class_: 'oneOf-custom-root', // <h5>
            'ui:disabled': true,
            type: {
              class_: 'oneOf-type-custom-root', // <h5>
              'ui:disabled': true,
            },
          },
          name: {
            'ui:placeholder': 'r1q01',
            'ui:disabled': true,
            'ui:options': {
              semantic: {
                // style: { width: '200px' },
                wrapItem: true,
              },
            },
          },
          html: {
            ru: {
              'ui:widget': 'CodeWidget',
              'ui:placeholder': '<p>Описание в формате HTML</p>',
              'ui:options': {
                semantic: {
                  // style: { width: '200px' },
                  wrapItem: true,
                },
              },
            },
          },
          description: {
            ru: {
              'ui:widget': 'textarea',
              'ui:placeholder': 'Описание вопроса',
              'ui:options': {
                semantic: {
                  // style: { width: '200px' },
                  wrapItem: true,
                },
              },
            },
          },
          isRequired: {
            'ui:options': {
              semantic: {
                // style: { width: '200px' },
                wrapItem: true,
              },
            },
          },
          columns: {
            'ui:options': {
              semantic: {
                // style: { width: '200px' },
                wrapItem: true,
              },
            },
          },
          choices: {
            'ui:options': {
              semantic: {
                // style: { width: '200px' },
                wrapItem: true,
              },
            },
            value: {
              'ui:placeholder': 'item1',
            },
            text: {
              ru: {},
            },
          },
        },
      },
    },
  },
};

export default uiSchema;
