import React from "react";
import {WidgetProps} from "@rjsf/semantic-ui";

import Widgets from '@rjsf/core/lib/components/widgets';
// import {Widgets} from '@rjsf/semantic-ui';

import {WidgetLabel} from "../components/WidgetLabel";

export const InlineSelectWidget = (props: WidgetProps) => {
  console.log('InlineSelectWidget: props:', props)
  return (
    <div className="custom-inline-widget custom-select-widget">
      <WidgetLabel {...props} />
      <Widgets.SelectWidget {...props} />
    </div>
  );
};

