/** @format */

import React from 'react';
import { WidgetProps } from '@rjsf/semantic-ui';

export const WidgetLabel = (props: WidgetProps) => (
  <label htmlFor={props.id} className="custom-inline-label">
    {props.label}
    {props.required ? '*' : null}
  </label>
);

export default WidgetLabel;
