/** @format */

import { unwrapDoc, unwrapDocs, WrappedDoc } from '@shared/lib/mongo';
import { OkvedRecord } from '@shared/types/OkvedRecord';
import axios, { AxiosResponse, Method } from 'axios';
import { jsPDF } from 'jspdf';

import { AllSurveysDefinitions } from '@shared/types/SurveyDefinitions';

import {
  AvgSurveyResultsQuery,
  FullSurveyData,
} from '@shared/types/SurveyResult';
import {
  AllSurveyQuestions,
} from '@shared/types/SurveyQuestions';

import {
  BASE_SURVEY_API_URL,
  BASE_SURVEY_QUESTIONS_API_URL,
  BASE_SURVEY_DEFINITIONS_API_URL,
  BASE_PDF_TO_EMAIL_API_URL,
  BASE_RESULTS_API_URL,
  BASE_OKVED_API_URL, BASE_JSON_API_URL,
} from '@shared/config';

interface PostResult {}

type RawServerResult = FullSurveyData &
  FullSurveyData[] &
  AvgSurveyResultsQuery &
  AllSurveysDefinitions &
  AllSurveyQuestions &
  // OkvedRecord[] &
  WrappedDoc<OkvedRecord>[] &
  PostResult;

type ServerResult = FullSurveyData &
  FullSurveyData[] &
  AvgSurveyResultsQuery &
  AllSurveysDefinitions &
  AllSurveyQuestions &
  OkvedRecord[] &
  // WrappedDoc<OkvedRecord>[] &
  PostResult;

type ServerError = {
  code: number;
  message: string;
};
interface ServerResponse {
  result?: RawServerResult;
  error?: ServerError;
}

export type OnProgressHandler = ({
  progress,
  loaded,
  total,
}: {
  progress: number;
  loaded: number;
  total: number;
}) => void;

const EMPTY_ALL_SURVEY_QUESTIONS = {};
const EMPTY_ALL_SURVEYS_DEFINITIONS: AllSurveysDefinitions = [];

export class BackendHttp {
  //
  public async postSurveys(
    data: FullSurveyData | undefined,
  ): Promise<FullSurveyData> {
    const result = await this._axiosPost({
      url: BASE_SURVEY_API_URL,
      data: data,
    });
    console.log('postSurveys:', result);
    return result;
  }

  public async getSurveys(id?: string): Promise<FullSurveyData> {
    const url = BASE_SURVEY_API_URL + (id ? '?id='+id : '');
    const result = await this._axiosGet({ url });
    console.log('getSurveys:', result);
    return result; // ?? EMPTY_FULL_SURVEY_DATA;
  }

  public async postQuestions(
    data: AllSurveyQuestions | undefined,
  ): Promise<PostResult> {
    const result = await this._axiosPost({
      url: BASE_SURVEY_QUESTIONS_API_URL,
      data: data,
    });
    console.log('postQuestions:', result);
    return result ?? {};
  }

  public async getQuestions(): Promise<AllSurveyQuestions> {
    const result = await this._axiosGet({ url: BASE_SURVEY_QUESTIONS_API_URL });
    console.log('getQuestions:', result);
    return result ?? EMPTY_ALL_SURVEY_QUESTIONS;
  }

  public async postDefinitions(
    data: AllSurveysDefinitions | undefined,
  ): Promise<PostResult> {
    const result = await this._axiosPost({
      url: BASE_SURVEY_DEFINITIONS_API_URL,
      data: data,
    });
    console.log('postDefinitions:', result);
    return result ?? {};
  }

  public async getOkveds({
    code: codeMask,
    name: nameMask,
    limit,
  }: {
    code?: string;
    name?: string;
    limit?: number;
  }): Promise<OkvedRecord[]> {
    const paramsArray = [];
    if (codeMask) paramsArray.push('code=' + encodeURIComponent(codeMask));
    if (nameMask) paramsArray.push('name=' + encodeURIComponent(nameMask));
    if (limit) paramsArray.push('limit=' + encodeURIComponent(limit));
    const params = paramsArray.join('&');

    const result = (await this._axiosGet({
      url: BASE_OKVED_API_URL + '?' + params,
    })) as WrappedDoc<OkvedRecord>[];
    console.log('getOkveds:', result);
    return unwrapDocs({ type: 'okved', docs: result });
  }

  public async getDefinitions(): Promise<AllSurveysDefinitions> {
    const result = await this._axiosGet({
      url: BASE_SURVEY_DEFINITIONS_API_URL,
    });
    console.log('getDefinitions:', result);
    return result ?? EMPTY_ALL_SURVEYS_DEFINITIONS;
  }

  public async postPdfToEmail(
    doc: jsPDF,
    onProgress: OnProgressHandler,
  ): Promise<PostResult> {
    const data = doc.output('datauristring');
    const result = await this._axiosPost({
      url: BASE_PDF_TO_EMAIL_API_URL,
      data: { data },
      onProgress,
    });
    console.log('postPdfToEmail:', result);
    return result; // ?? {};
  }

  public async getSurveysAvg({
    okved,
    size,
  }: {
    okved: string;
    size: string;
  }): Promise<AvgSurveyResultsQuery> {
    const paramsArray = [];
    if (okved) paramsArray.push('okved=' + encodeURIComponent(okved));
    if (size) paramsArray.push('size=' + encodeURIComponent(size));
    const params = paramsArray.join('&');
    console.log('getSurveysAvg: params:', params);
    const result = await this._axiosGet({
      url: BASE_RESULTS_API_URL + '?' + params,
    });
    console.log('getSurveysAvg:', result);
    return result; // ?? EMPTY_AVG_SURVEY_RESULT;
  }

  public async getAllJson(): Promise<RawServerResult> {
    const result = await this._axiosGet({
      url: BASE_JSON_API_URL,
    });
    console.log('getAllJson:', result);
    return result; // ?? EMPTY_AVG_SURVEY_RESULT;
  }

  //

  protected _axiosRequest({
    method,
    url,
    data,
    onProgress,
  }: {
    method: Method;
    url: string;
    data?: any;
    onProgress?: OnProgressHandler;
  }): Promise<RawServerResult> {
    console.log(`_axiosRequest url: "${url}"`, data);
    return axios
      .request({
        method,
        url,
        data,
        onUploadProgress: ({ loaded, total }) => {
          const progress = loaded / total;
          console.log('onUploadProgress', { progress, loaded, total });
          if (onProgress) onProgress({ progress, loaded, total });
        },
      })
      .then(function (
        response: AxiosResponse<ServerResponse>,
      ): RawServerResult | undefined {
        console.log('_axiosRequest:', response);
        if (response.data.error) {
          const { code, message } = response.data.error;
          throw new Error(`ERROR [${code}] ${message}`);
        }
        return response.data?.result;
      })
      .catch(function (error) {
        console.log('_axiosRequest: ERROR:', error);
        throw error;
      });
  }

  protected _axiosGet({
    url,
    onProgress,
  }: {
    url: string;
    onProgress?: OnProgressHandler;
  }): Promise<RawServerResult> {
    return this._axiosRequest({ method: 'get', url, onProgress });
  }

  protected _axiosPost({
    url,
    data,
    onProgress,
  }: {
    url: string;
    data: any;
    onProgress?: OnProgressHandler;
  }): Promise<RawServerResult | undefined> {
    return this._axiosRequest({ method: 'post', url, data, onProgress });
  }
}

export default BackendHttp;
