/** @format */

import { useState } from 'react';

export function routeSubstitute(
  route: string,
  context: { [key: string]: string | number },
) {
  Object.keys(context).forEach(
    (name) => (route = route.replace(':' + name, '' + context[name])),
  );
  return route;
}

export function objectToArray(o: {
  [key: string]: any;
}): { q: string; w: any }[] {
  return Object.keys(o).map((k) => {
    return { q: k, w: o[k] };
  });
}

//
// https://stackoverflow.com/questions/46240647/react-how-to-force-a-function-component-to-render
//
export function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

//

const USER_INFO_STORAGE_NAME = 'user_info_storage';

type CurrentUser = string;

export function getIsAdmin() {
  const result = getCurrentUser() === 'admin';
  return result;
}

export function setCurrentUser(user: CurrentUser) {
  return window.localStorage.setItem(
    USER_INFO_STORAGE_NAME,
    JSON.stringify(user),
  );
}

//

export function getCurrentUser(): CurrentUser {
  try {
    const s = window.localStorage.getItem(USER_INFO_STORAGE_NAME);
    return JSON.parse(s);
  } catch {
    const msg =
      'getCurrentUser: Error parsing saved JSON (or no JSON was saved)';
    console.error(new Error(msg));
    return '';
  }
}

export function removeCurrentUser() {
  return window.localStorage.removeItem(USER_INFO_STORAGE_NAME);
}

//

export function limitStr(s: string, count: number = 100) {
  const tail = '...';
  s = s.length > count ? s.substring(0, count - tail.length - 1) + tail : s;
  return s;
}
