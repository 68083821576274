/** @format */

export const MAX_LEVEL = 5;
export const totalWidth = 1200;

// export const ROOT_PATH = '/';
// export const SURVEY_PATH = '/survey/:surveyId';
// export const SURVEY_EDIT_PATH = '/survey/edit/:surveyId';
// export const SURVEY_DEF_EDIT_PATH = '/survey-def/edit/:surveyId';
// export const SURVEY_REPORT_PATH = '/survey/:surveyId/report';
// export const REPORT_CHOOSE_SURVEYS_PATH = '/report/test';
// export const INTERMEDIATE_SUMMARY_REPORT_PATH = '/report/summary';
// export const INTERMEDIATE_DETAILED_REPORT_PATH = '/report/detailed';
