/** @format */

import {ReactJsonFormDefinition} from "@shared/types/SurveyQuestions";
import React, { PropsWithChildren, useState, useEffect } from 'react';
import {
  JSONSchema7,
} from 'json-schema';

import OrgSurveys_uiSchema from '@shared/schemas-ui/OrgSurvey.ui-schema';
import OrgSurveys_Schema from '@shared/schemas/OrgSurvey.schema';

import { Surveys as SurveysObject } from '../../../lib/surveys';
import {GenericForm} from "./components/GenericForm";

export const AdminEditOrgSurvey = (props: {
  surveys: SurveysObject;
  surveyId: string;
  history;
}) => {
  console.log('AdminEditOrgSurvey props:', props);
  const { surveys, surveyId } = props;

  const allQuestions = surveys.allSurveyQuestionsDefinitions?._data;
  const allDefinitions = surveys.allSurveyDefinitions?._data;

  const surveyQuestions = allQuestions?.[surveyId] as ReactJsonFormDefinition;
  const surveyDefinitions = allDefinitions?.find(e => e.surveyId === surveyId);

  const formData = surveyQuestions?.schema;

  const schema = OrgSurveys_Schema;
  const uiSchema = OrgSurveys_uiSchema;

  return (
    <GenericForm
      state={surveys.state}
      header1={`Редактирование формы "${surveyDefinitions?.name}"`}
      formData={formData}
      schema={schema}
      uiSchema={uiSchema}
      history={props.history}
      onSubmit={async (submitEvent) => {
        console.log('onSubmit: allDefinitions', allDefinitions, submitEvent);
        await surveys.allSurveyQuestionsDefinitions.save();
      }}
    />
  );
};
