/** @format */

// import pegjs from 'pegjs';

import { SurveyResult } from '@shared/types/SurveyResult';

////////////////////////////////////////////////////////////////////////////////

export type ChartData = ChartDataPoint[];

export interface ChartDataPoint {
  id: string;
  name: string;
  description?: string;
  value: number;
  value2?: number|string;
  totalAvg?: number|string;
  okvedAvg?: number|string;
  sizeAvg?: number|string;
}

////////////////////////////////////////////////////////////////////////////////
//
// interface MyWindow extends Window {
//   parser: any;
// }
// declare var window: MyWindow;
// window.parser = pegjs.generate(grammarSource);
//
// const allWeightsDefinitions = require('../data/weights.js');
//
////////////////////////////////////////////////////////////////////////////////
//
// const testSurveyData: SurveyState = {
//   "data": {
//     "r1q01": "item2",
//     "r1q08": "item2",
//     "r1q12": "item1",
//     "r1q16": "item2",
//     "matrixradiogroup1": {
//       "Осуществляется для основных деловых процессов организации (взаимоотношения с потребителями, оказание услуг, производство, закупки, логистика)": {
//         "Генерация больших данных": 1,
//         "Сбор больших данных": 1,
//         "Передача больших данных": 0,
//         "Предобработка больших данных больших данных": 0
//       },
//       "Осуществляется для обеспечивающих процессов организации (бухгалтерский учет, управление персоналом, техническое обслуживание)": {
//         "Генерация больших данных": 1,
//         "Сбор больших данных": 1,
//         "Передача больших данных": 0,
//         "Предобработка больших данных больших данных": 0
//       },
//       "Осуществляется для процессов управления организацией (стратегическое планирование, финансовое планирование, оперативное управление)": {
//         "Генерация больших данных": 1,
//         "Сбор больших данных": 1,
//         "Передача больших данных": 0,
//         "Предобработка больших данных больших данных": 0
//       }
//     },
//     "question4": "item1",
//     "question3": "1234567890",
//     "question5": "11",
//     "question6": "11@11.rr",
//     "r1q02": "item1",
//     "r1q03": "item1",
//     "r1q04": "item2",
//     "r1q05": "item2",
//     "r1q06": "item1",
//     "r1q07": "item2",
//     "r1q09": "item2",
//     "r1q10": "item2",
//     "r1q17": "item2",
//     "r1q18": "item2"
//   },
//   "pageNo": 16,
//   "completed": true
// }
//
////////////////////////////////////////////////////////////////////////////////
//
//{
//  className: 'germany', // optional can be used for styling
//    axes: [
//      {axis: "strength",     value: 13, xOffset: 30, yOffset: 10},
//      {axis: "intelligence", value: 6, },
//      {axis: "charisma",     value: 5, },
//      {axis: "dexterity",    value: 9, },
//      {axis: "luck",         value: 2, xOffset: -20, }
//    ]
// },

interface ChartAxisResult1 {
  axis: string;
  value: number;
  xOffset?: number;
  yOffset?: number;
}

interface ChartResult1 {
  axes: ChartAxisResult1[];
}

////////////////////////////////////////////////////////////////////////////////

export const convertToChart = (data: SurveyResult): ChartResult1[] => {
  console.log('convertToChart: data:', data);
  const result: ChartResult1 = {
    //className: 'test',
    axes: [],
  };
  //const labels = {
  //  a1: { /*label: 'Аспект 1',*/ xOffset: 30, yOffset:  0, },
  //  a2: { /*label: 'Аспект 2',*/ xOffset: 10, yOffset: 10, },
  //  a3: { /*label: 'Аспект 3',*/ xOffset:  0, yOffset:  0, },
  //  a4: { /*label: 'Аспект 4',*/ xOffset:  0, yOffset: 10, },
  //  a5: { /*label: 'Аспект 5',*/ xOffset: 30, yOffset:  0, },
  //  a6: { /*label: 'Аспект 6',*/ xOffset: 30, yOffset:  0, },
  //}
  //for (const aspectName in data.aspects) {
  for (const aspect of data.aspects) {
    //const l = labels[ aspect.id ];
    const axisValue: ChartAxisResult1 = {
      axis: aspect.name,
      value: aspect.value,
      xOffset: 0, // l.xOffset,
      yOffset: 0, // l.yOffset,
    };
    result.axes.push(axisValue);
  }
  const finalResult = [result];
  console.log('convertToChart: finalResult:', finalResult);
  return finalResult;
};

////////////////////////////////////////////////////////////////////////////////

export const convertToChart2 = (data: SurveyResult): ChartData => {
  console.log('convertToChart: data:', data);
  const result: ChartData = //{
    //className: 'test',
  //  axes:
  []//,
  //};
  //const labels = {
  //  a1: { /*label: 'Аспект 1',*/ xOffset: 30, yOffset:  0, },
  //  a2: { /*label: 'Аспект 2',*/ xOffset: 10, yOffset: 10, },
  //  a3: { /*label: 'Аспект 3',*/ xOffset:  0, yOffset:  0, },
  //  a4: { /*label: 'Аспект 4',*/ xOffset:  0, yOffset: 10, },
  //  a5: { /*label: 'Аспект 5',*/ xOffset: 30, yOffset:  0, },
  //  a6: { /*label: 'Аспект 6',*/ xOffset: 30, yOffset:  0, },
  //}
  //for (const aspectName in data.aspects) {
  for (const aspect of data.aspects) {
    //const l = labels[ aspect.id ];
    const axisValue: ChartDataPoint = {
      id: aspect.aspectId,
      name: aspect.name,
      description: aspect.description,
      value: aspect.value,
      // xOffset: 0, // l.xOffset,
      // yOffset: 0, // l.yOffset,
    };
    result/*.axes*/.push(axisValue);
  }
  // const finalResult = [result];

  // const tmp = convertToChart(data);
  // return tmp[0].axes.map((a) => ({
  //   name: a.axis,
  //   value: a.value,
  // }));
  return result;
};
