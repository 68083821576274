/** @format */

export abstract class DataCacheSource<T> {
  protected cache: DataCacheStorage<T>;
  constructor(cache: DataCacheStorage<T>) {
    this.cache = cache;
  }
  // protected abstract _load: () => Promise<T>;
  // protected abstract _save: () => Promise<T>;
  abstract load(): Promise<T>;
  abstract save(): Promise<T>;
}

export abstract class DataCacheStorage<T> {
  public _data: T | undefined;
  constructor() {
    this._setInitialData();
  }
  _set(data: T) {
    this._data = data;
  }
  _get(): T | undefined {
    return this._data;
  }
  public _setInitialData(): T | undefined {
    this._data = undefined;
    return this._data;
  }
}

export abstract class AbstractDataCache<
  T,
  // SourceIds extends string
> extends DataCacheStorage<T> {
  public isChanged: boolean = false;
  // public sources: { [sourceId in SourceIds]: DataCacheSource<T> };
  // public defaultLoaders: SourceIds[];
  // public defaultSavers: SourceIds[];
  constructor() {
    super();
  }
  _setChanged(value: boolean) {
    this.isChanged = value;
  }
  // public abstract /*async*/ load(): Promise<T | undefined>;
  // public abstract /*async*/ save(): Promise<T | undefined>;
}

abstract class DataCache<T, SourceIds extends string> extends AbstractDataCache<
  T
  //,
  // SourceIds
> {
  // protected abstract loaders: { [key: string]: DataCacheLoader<T> } = {};
  // public async load(): Promise<T | undefined> {
  //   // for (const k in this.defaultLoaders) {
  //   //   await this.sources[k].load();
  //   // }
  //   const promises = this.defaultLoaders.map((loaderId) =>
  //     this.sources[loaderId].load(),
  //   );
  //   const result = Promise.all(promises);
  //   return this._data;
  // }
  // public async save(): Promise<T | undefined> {
  //   // for (const k in this.defaultSavers) {
  //   //   await this.sources[k].save();
  //   // }
  //   const promises = this.defaultSavers.map((loaderId) =>
  //     this.sources[loaderId].save(),
  //   );
  //   const result = Promise.all(promises);
  //   return this._data;
  // }
}
