import React from "react";

export function FormButtons(props) {
  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <input
        type="submit"
        className="ui button primary"
        value="Сохранить"
      />
      <input
        type="button"
        className="ui button default"
        value="Назад"
        onClick={() => props.history.goBack()}
      />
    </div>
  )
}

export default FormButtons;
