/** @format */

import { UiSchema } from '@rjsf/core';

const uiSchema: UiSchema = {
  'ui:options': {
    titlePrefix: 'Обозначение поля:',
    semantic: {
      wrapItem: true,
      wrapLabel: true,
      wrapContent: true,
    },
  },
  properties: {
    inn: {
      'ui:options': { titleTemplate: 'Поле формы: "${ title }"' },
      format: {
        'ui:widget': 'InlineSelectWidget',
      },
      pattern: {
        'ui:options': {
          classNames: 'font-family-monospace',
        },
      },
    },
    okved: {
      'ui:options': { titleTemplate: 'Поле формы: "${ title }"' },
      format: {
        'ui:widget': 'InlineSelectWidget',
      },
      pattern: {
        'ui:options': { style: { fontFamily: 'monospace' } },
      },
    },
    size: {
      'ui:options': { titleTemplate: 'Поле формы: "${ title }"' },
      oneOf: {
        'ui:options': { semantic: { wrapItem: true } },
      },
      pattern: {
        'ui:options': {
          style: { fontFamily: 'monospace' },
        },
      },
    },
    email: {
      'ui:options': { titleTemplate: 'Поле формы: "${ title }"' },
      format: {
        'ui:widget': 'InlineSelectWidget',
      },
      pattern: {
        'ui:options': { style: { fontFamily: 'monospace' } },
      },
    },
  },
};

export default uiSchema;
