/** @format */

import React, { PropsWithChildren, useState, useEffect } from 'react';
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
  RouteComponentProps,
} from 'react-router-dom';
import * as Survey from 'survey-react';

//
import {
  ROOT_PATH,
  SURVEY_PATH,
  SURVEY_EDIT_PATH,
  SURVEY_EDIT_QUESTIONS_LIST_PATH,
  SURVEY_EDIT_QUESTIONS_PATH,
  SURVEY_LEVEL_LIST_PATH,
  SURVEY_LEVEL_EDIT_PATH,
  SURVEY_DEF_EDIT_PATH,
  SURVEY_REPORT_PATH,
  REPORT_CHOOSE_SURVEYS_PATH,
  INTERMEDIATE_SUMMARY_REPORT_PATH,
  INTERMEDIATE_DETAILED_REPORT_PATH,
  FINAL_REPORT_PATH,
  SURVEY_ASPECT_EDIT_PATH,
  SURVEY_ASPECT_LIST_PATH,
} from '@shared/config';
import { AdminEditOrgSurvey } from './components/pages/admin/AdminEditOrgSurvey';
import { AdminEditSurveyAspect } from './components/pages/admin/AdminEditSurveyAspect';
import { AdminListSurveyAspect } from './components/pages/admin/AdminListSurveyAspect';
import { AdminEditSurveyLevel } from './components/pages/admin/AdminEditSurveyLevel';
import { AdminListSurveyLevel } from './components/pages/admin/AdminListSurveyLevel';
import { AdminListSurveyQuestion } from './components/pages/admin/AdminListSurveyQuestion';
import { AdminEditSurveyQuestion } from './components/pages/admin/AdminEditSurveyQuestion';

import { Surveys } from './lib/surveys';
import { routeSubstitute, useForceUpdate, setCurrentUser } from './lib/utils';
//
import { Notfound } from './components/basic/Notfound';
//
import { MainPage } from './components/pages/MainPage';
import { OrgSurveyPage } from './components/pages/OrgSurveyPage';
import { AspectsSummaryPage } from './components/pages/AspectsSummaryPage';
import { AspectsSurveyPage } from './components/pages/AspectsSurveyPage';
import { FinalReportPage } from './components/pages/FinalReportPage';
import { ChooseSurveysPage } from './components/pages/ChooseSurveysPage';
import { AdminEditSurveyDefFull } from './components/pages/admin/AdminEditSurveyDefFull';
import { AdminMenuSurveyPage } from './components/pages/admin/AdminMenuSurveyPage';
//

type CustomRouteComponentProps =
  | PropsWithChildren<RouteComponentProps<any>>
  | PropsWithChildren<any>;

export function Routes({ surveys }: { surveys: Surveys }) {
  const forceUpdate = useForceUpdate();
  const location = useLocation();
  const history = useHistory();

  function getCurrentUser() {
    const queryParams = new URLSearchParams(window.location.search);

    const PARAM_NAME_USER = 'user';
    if (queryParams.has(PARAM_NAME_USER)) {
      setCurrentUser(queryParams.get(PARAM_NAME_USER));
      // setError('There was a problem.')
      queryParams.delete(PARAM_NAME_USER);
      history.replace({
        search: queryParams.toString(),
      });
      forceUpdate();
    }
  }

  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <Switch>
      <Route
        exact
        path={ROOT_PATH}
        component={() => <MainPage surveys={surveys} />}
      />
      <Route
        exact
        path={SURVEY_PATH}
        component={(props: CustomRouteComponentProps) => {
          const { surveyId } = props.match.params ?? {};
          // const surveyId = props.match.params && props.match.params.surveyId;

          if (surveyId === 'org') {
            return (
              <OrgSurveyPage
                surveys={surveys}
                surveyId={surveyId}
                history={history}
              />
            );
          }

          console.log('App: Checking completion state');
          const name = surveys.getSurveyName(surveyId);
          const { completed } = surveys.getSurvey(surveyId, undefined) || {};
          console.log('App: Checking completion state:', completed);

          return completed && surveyId !== 'org' ? (
            <Redirect
              to={
                surveyId === 'org'
                  ? ROOT_PATH // INTERMEDIATE_SUMMARY_REPORT_PATH //`/report`
                  : routeSubstitute(SURVEY_REPORT_PATH, { surveyId })
              }
            />
          ) : (
            <AspectsSurveyPage
              surveyId={surveyId}
              surveys={surveys}
              onComplete={(survey: Survey.SurveyModel) => {
                if (surveyId === 'org') props.history.push(ROOT_PATH);
                forceUpdate();
              }}
              history={history}
            />
          );
        }}
      />
      {/*
      <Route
        exact
        path={'/test'}
        component={(props: CustomRouteComponentProps) => {
          const { surveyId } = props.match.params ?? {};
          return (
            <OrgSurveyPage
              surveys={surveys}
              surveyId={surveyId}
              history={history}
            />
          );
        }}
      />
*/}
      <Route
        exact
        path={SURVEY_EDIT_PATH}
        component={(props: CustomRouteComponentProps) => {
          const { surveyId } = props.match.params ?? {};
          if (surveyId === 'org') {
            return (
              <AdminEditOrgSurvey
                surveys={surveys}
                surveyId={surveyId}
                history={history}
              />
            );
          } else {
            return (
              <AdminMenuSurveyPage surveys={surveys} surveyId={surveyId} />
            );
          }
        }}
      />
      //
      <Route
        exact
        path={SURVEY_EDIT_QUESTIONS_LIST_PATH}
        component={(props: CustomRouteComponentProps) => {
          const { surveyId } = props.match.params ?? {};
          return (
            <AdminListSurveyQuestion surveys={surveys} surveyId={surveyId} />
          );
        }}
      />
      <Route
        exact
        path={SURVEY_EDIT_QUESTIONS_PATH}
        component={(props: CustomRouteComponentProps) => {
          const { surveyId, questionId } = props.match.params ?? {};
          return (
            <AdminEditSurveyQuestion
              surveys={surveys}
              surveyId={surveyId}
              questionId={questionId}
              history={history}
            />
          );
        }}
      />
      //----------------------------------------------------------------------//
      <Route
        exact
        path={SURVEY_LEVEL_LIST_PATH}
        component={(props: CustomRouteComponentProps) => {
          const { surveyId } = props.match.params ?? {};
          return <AdminListSurveyLevel surveys={surveys} surveyId={surveyId} />;
        }}
      />
      <Route
        exact
        path={SURVEY_LEVEL_EDIT_PATH}
        component={(props: CustomRouteComponentProps) => {
          const { surveyId, levelId } = props.match.params ?? {};
          return (
            <AdminEditSurveyLevel
              surveys={surveys}
              surveyId={surveyId}
              levelId={levelId}
              history={history}
            />
          );
        }}
      />
      //----------------------------------------------------------------------//
      <Route
        exact
        path={SURVEY_ASPECT_LIST_PATH}
        component={(props: CustomRouteComponentProps) => {
          const { surveyId } = props.match.params ?? {};
          return (
            <AdminListSurveyAspect surveys={surveys} surveyId={surveyId} />
          );
        }}
      />
      <Route
        exact
        path={SURVEY_ASPECT_EDIT_PATH}
        component={(props: CustomRouteComponentProps) => {
          const { surveyId, aspectId } = props.match.params ?? {};
          return (
            <AdminEditSurveyAspect
              surveys={surveys}
              surveyId={surveyId}
              aspectId={aspectId}
              history={history}
            />
          );
        }}
      />
      //----------------------------------------------------------------------//
      <Route
        exact
        path={SURVEY_DEF_EDIT_PATH}
        component={(props: CustomRouteComponentProps) => {
          const { surveyId } = props.match.params ?? {};
          return (
            <AdminEditSurveyDefFull surveys={surveys} surveyId={surveyId} />
          );
        }}
      />
      <Route
        exact
        path={SURVEY_REPORT_PATH}
        component={(props: CustomRouteComponentProps) => {
          const { surveyId } = props.match.params ?? {};
          // const surveyId = props.match?.params?.surveyId;
          const surveyState = surveys.getSurvey(surveyId, undefined);
          return surveyState?.completed ? (
            <AspectsSummaryPage
              surveyId={surveyId}
              surveys={surveys}
              onBack={() => {
                surveys.setCompleted(surveyId, false);
                // props.history.push(`/survey/${surveyId}`)
                props.history.push(routeSubstitute(SURVEY_PATH, { surveyId }));
              }}
              onNext={() => {
                props.history.push(ROOT_PATH);
              }}
            />
          ) : (
            <Redirect
              // to={`/survey/${surveyId}`}
              to={routeSubstitute(SURVEY_PATH, { surveyId })}
            />
          );
        }}
      />
      <Route
        exact
        path={REPORT_CHOOSE_SURVEYS_PATH}
        component={(props: CustomRouteComponentProps) => {
          return (
            <ChooseSurveysPage
              {...props}
              surveys={surveys}
              showDetails={false}
            />
          );
        }}
      />
      <Route
        exact
        path={INTERMEDIATE_SUMMARY_REPORT_PATH}
        component={(props: CustomRouteComponentProps) => {
          return (
            <FinalReportPage {...props} surveys={surveys} showDetails={false} />
          );
        }}
      />
      <Route
        exact
        path={INTERMEDIATE_DETAILED_REPORT_PATH}
        component={(props: CustomRouteComponentProps) => {
          return (
            <FinalReportPage {...props} surveys={surveys} showDetails={true} />
          );
        }}
      />
      <Route
        exact
        path={FINAL_REPORT_PATH}
        component={(props: CustomRouteComponentProps) => {
          // Выбрать все размерности
          const ids = Object.keys(surveys._data.surveys);
          for (const id of ids) {
            surveys._data.surveys[id].selected = true;
          }
          return (
            <FinalReportPage {...props} surveys={surveys} showDetails={false} />
          );
        }}
      />
      <Route component={Notfound} />
    </Switch>
  );
}
