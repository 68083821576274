/** @format */

import React from 'react';
import * as Survey from 'survey-react';

import { Surveys } from '../../lib/surveys';
import {NotReady} from "../basic/NotReady";

type AspectsSurveyProps = {
  surveyId: string;
  surveys: Surveys;
  onComplete: (survey: Survey.SurveyModel) => void;
  history,
};

export class AspectsSurveyPage extends React.Component<AspectsSurveyProps> {
  survey: Survey.SurveyModel;

  constructor(props: AspectsSurveyProps) {
    super(props);
    const { surveyId, surveys, onComplete } = this.props;

    surveys.setCompleted(surveyId, false);

    const json = surveys.getQuestions(surveyId);

    this.survey = new Survey.Model(json);
    console.log('AspectsSurveyPage.constructor: json:', json);
    console.log('AspectsSurveyPage.constructor: this.survey:', this.survey);

    this.survey.onComplete.add(async (survey, options) => {
      console.log(`survey.onComplete surveyId: "${surveyId}"`, survey);
      surveys.setSurvey(surveyId, survey, true);
      await surveys.save();
      console.log('survey.onComplete: data was sent');
      onComplete && onComplete(survey);
    });
    this.survey.onPartialSend.add((survey) => {
      console.log('survey.onPartialSend');
      surveys.setSurvey(surveyId, survey);
    });

    // this.survey
    //   .onUpdateQuestionCssClasses
    //   .add(function (survey, options) {
    //     console.log('survey: onUpdateQuestionCssClasses: options', options)
    //
    //     // _.set(options, 'question.css.navigation.next', 'xxxxxxxxxxxxxxxxxxxxxxxxx');
    //     options.question.css.navigation.next = ' xxx';
    //     options.question.css.navigation.prev = ' yyy';
    //     // var classes = options.cssClasses
    //     //
    //     // classes.root = "sq-root";
    //     // classes.title = "sq-title"
    //     // classes.item = "sq-item";
    //     // classes.label = "sq-label";
    //     //
    //     // if (options.question.isRequired) {
    //     //   classes.title += " sq-title-required";
    //     //   classes.root += " sq-root-required";
    //     // }
    //     //
    //     // if (options.question.getType() === "checkbox") {
    //     //   classes.root += " sq-root-cb";
    //     // }
    //   });

  }

  componentDidMount() {
    const { surveyId, surveys } = this.props;
    const surveyState = surveys.getSurvey(surveyId, this.survey);
    console.log('AspectsSurveyPage.componentDidMount', surveyState);
  }

  render() {
    const {surveys,surveyId} = this.props;
    if (surveys.state !== 'ready') return <NotReady state={surveys.state} />;

    return (
      <div className="disable-select">
        <Survey.Survey model={this.survey} />
      </div>
    );
  }
}
