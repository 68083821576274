/** @format */

import React from 'react';
import { WidgetProps } from '@rjsf/semantic-ui';

import Widgets from '@rjsf/core/lib/components/widgets';
// import {Widgets} from '@rjsf/semantic-ui';

import { WidgetLabel } from '../components/WidgetLabel';

// interface Inline

interface InlineTextWidgetProps extends WidgetProps {
//   'ui:options'?: {
//     'inline-widget'?: {
//       class?: string;
//     };
//   };
}

function getInlineWidgetProps(props: WidgetProps) {
  return props?.['ui:options']?.['inline-widget'];
}

export const InlineTextWidget = (props: WidgetProps) => {
  console.log('InlineTextWidget: props:', props);
  const addClass = props?.options?.classNames ?? '';
  return (
    <div className={'custom-inline-widget custom-text-widget '+addClass} >
      <WidgetLabel {...props} />
      <Widgets.TextWidget {...props} />
    </div>
  );
};
